import { useState } from "react";
import CurrentMountainAreaContext from "./create-context/currentMountainAreaContext";

//Overrides and Current Mountain Area States
export function MountainProvider({ children }) {
  //Global Prop to share currentMountainArea to Lifts/Trails/Activities components.
  //setCurrentMountainArea is called in TabsMountainAreas component.
  const [currentMountainArea, setCurrentMountainArea] = useState(null);

  return (
    <CurrentMountainAreaContext.Provider
      value={{ currentMountainArea, setCurrentMountainArea }}
    >
      {children}
    </CurrentMountainAreaContext.Provider>
  );
}
