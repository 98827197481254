import classnames from "classnames";
import styles from "../../../styles/components/storybook/atoms/Link.module.scss";
import Icon from "../../shared/Icon/Icon";

const Link = ({ label, href, onClick, type, className, linkType }) => {
  if (type === "expandContentLink") {
    return (
      <ExpandContentLink
        label={label}
        onClick={onClick}
        className={className}
      />
    );
  }
  return <InternalLink label={label} href={href} className={className} linkType={linkType} />;
};

export default Link;

//LINK TYPES
const InternalLink = (props) => {
  const { label, href, className, linkType } = props;

  const linkClassName = classnames(className, styles.link, styles.internalLink);

  return (
    <a
      className={linkClassName}
      href={href}
      rel="noreferrer noopener"
      target={linkType === "external" ? "_blank" : "_self"}
    >
      {label}
      <Icon name="chevron" type="general" />
    </a>
  );
};

const ExpandContentLink = (props) => {
  const { label, onClick, className } = props;

  const linkClassName = classnames(
    className,
    styles.link,
    styles.expandContentLink
  );

  return (
    <a
      className={linkClassName}
      onClick={onClick}
      target="_blank"
      rel="noopener noreferrer"
    >
      {label}
    </a>
  );
};
