import classnames from "classnames";
import styles from "../../../styles/components/storybook/atoms/Indicator.module.scss";

const Indicator = ({ status, className }) => {
  const statusClassNameKey = status.toLowerCase().replaceAll(" ", "-");
  const indicatorIconClassName = classnames(
    className,
    styles.iconStatus,
    styles[statusClassNameKey]
  );

  return <i className={indicatorIconClassName} aria-hidden="true" />;
};

export default Indicator;
