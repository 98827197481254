import { useContext } from "react";
import LanguageContext from "../../../lib/context/create-context/languageContext";
import classnames from "classnames";
import styles from "../../../styles/components/storybook/widgets/SnowfallBannerWidget.module.scss";
import Divider from "../atoms/Divider";
import Icon from "../../shared/Icon/Icon";
import { UNIT_LENGTH_CM } from "../../../lib/components/weatherWidget";

const SnowfallBanner = (props) => {
  const {
    content: { area },
    lenghtUnit,
    snowfallBannerDataEndpoints: { inches, centimeters },
    condensedVariant,
    snowfallBannerLabels,
  } = props;

  const {isFrench} = useContext(LanguageContext);

  let snowFallBannerData = inches;
  if (lenghtUnit === UNIT_LENGTH_CM) {
    snowFallBannerData = centimeters;
  }

  if (!snowFallBannerData || snowFallBannerData === undefined) {
    return null;
  }

  const {
    newSnowLow,
    newSnowHigh,
    today,
    tomorrow,
    seventyTwoHours,
    seasonTotal,
    last7Days,
  } = snowFallBannerData;

  let newSnowLabel = isFrench ? "Nouvelle neige" : "New Snow";
  let last24hourLabel = isFrench ? "Aujourd'hui" : "Today";
  let last48hourLabel = isFrench ? "2 jours" : "2-Day";
  let last72hourLabel = isFrench ? "3 jours" : "3-Day";
  let seasonTotalLabel = isFrench ? "Total de la saison" : "Season Total";

  if (snowfallBannerLabels) {
    last24hourLabel = snowfallBannerLabels.map((label) => label.last24Hours);
    last48hourLabel = snowfallBannerLabels.map((label) => label.last48Hours);
    last72hourLabel = snowfallBannerLabels.map((label) => label.last72Hours);
  }
  const wrapperClassNames = classnames(
    styles.snowfallBannerWrapper,
    condensedVariant ? styles.condensedVariant : null
  );

  //Conditionally render Snowfall Banner based on snowfall - hide if today, yesterday, and 72 hours do not contain a numerical value greater than 0
  function isPositiveNumber(value) {
    let convertValueToNumber = value.replace(/[^0-9]/g, "");
    convertValueToNumber = +convertValueToNumber;

    return typeof convertValueToNumber === "number" && convertValueToNumber > 0;
  }

  if (
    !isPositiveNumber(today) &&
    !isPositiveNumber(tomorrow) &&
    !isPositiveNumber(seventyTwoHours)
  )
    return null;

  return (
    <div className={wrapperClassNames}>
      <div className={styles.snowfallBannerNewSnow}>
        <Icon
          type="weather"
          name="snowflake"
          className={styles.snowfallBannerIcon}
        />
        <SnowTotal
          area={`${area}: ${newSnowLow}`}
          reportArea={area}
          depth={`${newSnowLow} - ${newSnowHigh}`}
          label={newSnowLabel}
          condensedVariant={condensedVariant}
        />
      </div>
      <div className={styles.snowfallBannerForecast}>
        <Forecast
          area={area}
          depth={today}
          label={last24hourLabel}
          condensedVariant={condensedVariant}
        />
        <div className={styles.forecastDivider}></div>
        <Forecast
          area={area}
          depth={tomorrow}
          label={last48hourLabel}
          condensedVariant={condensedVariant}
        />
        <div className={styles.forecastDivider}></div>
        <Forecast
          area={area}
          depth={seventyTwoHours}
          label={last72hourLabel}
          condensedVariant={condensedVariant}
        />
      </div>
      <div className={styles.snowfallBannerSeasonTotal}>
        <SnowTotal
          area={area}
          depth={seasonTotal}
          seasonTotal={seasonTotal}
          label={seasonTotalLabel}
          condensedVariant={condensedVariant}
        />
      </div>
    </div>
  );
};

export default SnowfallBanner;

const Depth = ({ depth }) => (
  <div className={styles.snowfallDepth}>{depth}</div>
);
const SeasonTotal = ({ seasonTotal }) => (
  <div className={styles.snowfallSeasonTotal}>{seasonTotal}</div>
);
const Label = ({ label }) => (
  <h4 className={styles.snowfallContent}>{label}</h4>
);
const Area = ({ area }) => <p className={styles.area}>{area}</p>;

const SnowTotal = ({
  depth,
  label,
  area,
  condensedVariant,
  reportArea,
  seasonTotal,
}) => {
  return (
    <>
      <div className={styles.snowfall}>
        <Depth depth={depth} />
        <Label label={label} />
        {!condensedVariant && <Area area={area} />}
        {condensedVariant && reportArea && <Area area={reportArea} />}
        {condensedVariant && seasonTotal && (
          <SeasonTotal seasonTotal={seasonTotal} />
        )}
      </div>
    </>
  );
};

const Forecast = ({ depth, label, area, condensedVariant }) => {
  return (
    <div className={styles.forecastItem}>
      <Depth depth={depth} />
      <Label label={label} />
      {!condensedVariant && <Area area={area} />}
    </div>
  );
};
