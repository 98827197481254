import { is } from "date-fns/locale";
import { useEffect, useState } from "react";

const aggregateStats = ({ SnowReport }, accStats = {}) => ({
  TotalLifts: parseInt(SnowReport.TotalLifts) + (accStats?.TotalLifts || 0),
  TotalOpenLifts:
    parseInt(SnowReport.TotalOpenLifts) + (accStats?.TotalOpenLifts || 0),
  TotalTrails: parseInt(SnowReport.TotalTrails) + (accStats?.TotalTrails || 0),
  TotalOpenTrails:
    parseInt(SnowReport.TotalOpenTrails) + (accStats?.TotalOpenTrails || 0),
  TotalActivities:
    parseInt(SnowReport.TotalActivities) + (accStats?.TotalActivities || 0),
  TotalOpenActivities:
    parseInt(SnowReport.TotalOpenActivities) +
    (accStats?.TotalOpenActivities || 0),
});

const combineCategorizedList = (
  categorizedList,
  accumulatedCategorizedList
) => ({
  ...accumulatedCategorizedList,
  ...Object.fromEntries(
    Object.entries(categorizedList).map(([key, value]) => {
      if (accumulatedCategorizedList[key]) {
        return [key, [...accumulatedCategorizedList[key], ...value]];
      }
      return [key, value];
    })
  ),
});

const combineMountainAreas = ({ Name, LastUpdate, MountainAreas }) => {
  const {
    OpenTrailsCount,
    TotalTrailsCount,
    Trails,
    Lifts,
    Activities,
    CategorizedTrails,
    CategorizedLifts,
    CategorizedActivities,
  } = (MountainAreas || []).reduce(
    (acc, area) => ({
      ...acc,
      OpenTrailsCount: acc.OpenTrailsCount + area.OpenTrailsCount,
      TotalTrailsCount: acc.TotalTrailsCount + area.TotalTrailsCount,
      Trails: [...acc.Trails, ...area.Trails],
      Lifts: [...acc.Lifts, ...area.Lifts],
      Activities: [...acc.Activities, ...area.Activities],

      CategorizedTrails: combineCategorizedList(
        area.CategorizedTrails || {},
        acc.CategorizedTrails || {}
      ),
      CategorizedLifts: combineCategorizedList(
        area.CategorizedLifts || {},
        acc.CategorizedLifts || {}
      ),
      CategorizedActivities: combineCategorizedList(
        area.CategorizedActivities || {},
        acc.CategorizedActivities || {}
      ),
    }),
    {
      OpenTrailsCount: 0,
      TotalTrailsCount: 0,
      Trails: [],
      Lifts: [],
      Activities: [],
      CategorizedTrails: {},
      CategorizedLifts: {},
      CategorizedActivities: {},
    }
  );
  return {
    Name,
    LastUpdate,

    OpenTrailsCount,
    TotalTrailsCount,
    Trails,
    Lifts,
    Activities,
    CategorizedTrails,
    CategorizedLifts,
    CategorizedActivities,
  };
};

const getPrimaryResortData = (
  primaryResortId,
  index,
  resortId,
  resortData,
  accumulatedData
) => {
  if (primaryResortId) {
    return primaryResortId === resortId ? resortData : accumulatedData;
  } else {
    return index === 0 ? resortData : accumulatedData;
  }
};

const combineResortFeed = (apiFeed, primaryResort) => {
  const keyIndexMap = {
    0: "zero",
    1: "one",
    2: "two",
    3: "three",
    4: "four",
    5: "five",
  };

  if (apiFeed.Resorts.length === 1) {
    const resort = apiFeed.Resorts[0];
    const { BaseArea, MidMountainArea, SummitArea, ...snowReport } =
      resort.SnowReport;

    return {
      ...resort,
      Names: {
        [keyIndexMap[0]]: resort.CurrentConditions.Base.Name,
        [keyIndexMap[1]]: resort.CurrentConditions.MidMountain.Name,
        [keyIndexMap[2]]: resort.CurrentConditions.Summit.Name,
      },
      AggregatedStats: aggregateStats(resort),
      Forecasts: {
        [keyIndexMap[0]]: { ...resort.Forecast },
        [keyIndexMap[1]]: { ...resort.Forecast },
        [keyIndexMap[2]]: { ...resort.Forecast },
      },
      SnowReport: {
        [keyIndexMap[0]]: {
          ...snowReport,
          Area: {
            ...BaseArea,
            newSnowLowIn: BaseArea.Last24HoursIn,
            newSnowHighIn: SummitArea.Last24HoursIn,
            newSnowLowCm: BaseArea.Last24HoursCm,
            newSnowHighCm: SummitArea.Last24HoursCm,
          },
        },
        [keyIndexMap[1]]: {
          ...snowReport,
          Area: {
            ...MidMountainArea,
            newSnowLowIn: BaseArea.Last24HoursIn,
            newSnowHighIn: SummitArea.Last24HoursIn,
            newSnowLowCm: BaseArea.Last24HoursCm,
            newSnowHighCm: SummitArea.Last24HoursCm,
          },
        },
        [keyIndexMap[2]]: {
          ...snowReport,
          Area: {
            ...SummitArea,
            newSnowLowIn: BaseArea.Last24HoursIn,
            newSnowHighIn: SummitArea.Last24HoursIn,
            newSnowLowCm: BaseArea.Last24HoursCm,
            newSnowHighCm: SummitArea.Last24HoursCm,
          },
        },
      },
      CurrentConditions: {
        [keyIndexMap[0]]: resort.CurrentConditions.Base,
        [keyIndexMap[1]]: resort.CurrentConditions.MidMountain,
        [keyIndexMap[2]]: resort.CurrentConditions.Summit,
      },
      MountainAreas: resort.MountainAreas.map(
        ({
          CategorizedLifts,
          CategorizedTrails,
          CategorizedActivities,
          ...mountainArea
        }) => ({
          ...mountainArea,
          CategorizedLifts: CategorizedLifts || {},
          CategorizedTrails: CategorizedTrails || {},
          CategorizedActivities: CategorizedActivities || {},
        })
      ),
    };
  } else {
    return apiFeed.Resorts.reduce(
      (acc, resort, index) => {
        const key = keyIndexMap[index];
        const { BaseArea, MidMountainArea, SummitArea, ...snowReport } =
          resort.SnowReport;

        const resortName = resort.Name;
        return {
          ...acc,
          Names: {
            ...acc.Names,
            [key]: resortName,
          },
          AggregatedStats: aggregateStats(resort, acc.AggregatedStats),
          SnowReport: {
            ...acc.SnowReport,
            [key]: {
              ...snowReport,
              Area: {
                ...BaseArea,
                newSnowLowIn: BaseArea.Last24HoursIn,
                newSnowHighIn: SummitArea.Last24HoursIn,
                newSnowLowCm: BaseArea.Last24HoursCm,
                newSnowHighCm: SummitArea.Last24HoursCm,
              },
            },
          },
          Forecasts: {
            ...acc.Forecasts,
            [key]: { ...resort.Forecast },
          },
          CurrentConditions: {
            ...acc.CurrentConditions,
            [key]: resort.CurrentConditions.Base,
          },
          MountainAreas: [...acc.MountainAreas, combineMountainAreas(resort)],
          LayoutOptions: getPrimaryResortData(
            primaryResort,
            index,
            resort.id,
            resort.LayoutOptions,
            acc.LayoutOptions
          ),
          Roads: getPrimaryResortData(
            primaryResort,
            index,
            resort.id,
            resort.Roads,
            acc.Roads
          ),
        };
      },
      {
        Names: {},
        AggregatedStats: {},
        SnowReport: {},
        Forecasts: {},
        CurrentConditions: {},
        MountainAreas: [],
      }
    );
  }
};

const GetAPIFeedData = (
  resortIds = [],
  bearerToken = "",
  primaryResort = null,
  isFrenchContent = false,
  isMtnPowderStaging = false
) => {
  const [resortAPIData, setResortAPIData] = useState(null);
  const [fetchingResortAPIData, setFetchingResortAPIData] = useState(false);

  useEffect(() => {
    if (!fetchingResortAPIData && !resortAPIData && resortIds.length > 0) {
      setFetchingResortAPIData(true);
      // let feedURL = `https://www.mtnpowder.com/feed?format=json&resortId=${resortId}`;
      // if (resortId === "bear-mountain-snow-summit-snow-valley-combined") {
      //   feedURL =
      //     "https://www.mtnpowder.com/feed/v3/bear-mountain-snow-summit-snow-valley/combined?bearer_token=5pGMqUcRBEG4kmDJyHBPJA9kcynwUrQoGKDxlOLfVdQ";
      // } else if (
      //   resortId === "bear-mountain-summer-snow-summit-snow-valley-summer"
      // ) {
      //   feedURL =
      //     "https://www.mtnpowder.com/feed/v3/bear-mountain-summer-snow-summit-summer-snow-valley-summer/combined?bearer_token=kmZMlGSSCPHjRl8Ix6YgzD72HVtTvvEcfcqpcyFb76k";
      // } else if (resortId === "blue-mountain") {
      //   feedURL =
      //     "https://snowreporting.herokuapp.com/feed?format=json&resortId=3";
      // }
      // DEV TEST FEED: To use, change resortID to 'test' in the json config file

      // let feedURL = "";
      // if (resortIds "test") {
      //   feedURL = "testFeed.json";
      // } else {
      // if (resortIds) {

      // }

      // const filteredResortIds = resortIds.filter(
      //   (resortId) => resortId !== "test"
      // );

      const TEST_ID = "test";
      const useTestData = resortIds.includes(TEST_ID);

      const testUrl = "testFeed.json";

      let url = new URL(
        `https://mtnpowder.com/${isFrenchContent ? 'fr-CA/' : ''}feed/v3.json?bearer_token=${bearerToken}`
      );
      if (isMtnPowderStaging) {
        url = new URL(
          `https://snowreporting-staging.herokuapp.com/${isFrenchContent ? 'fr-CA/' : ''}feed/v3.json?bearer_token=J3UPpMHLH8kcnMvn2GrdCqhQZNBqOplx8lN-6goKyqE`
        );
      }

      resortIds
      .filter((resortId) => resortId !== TEST_ID)
      .forEach((resortId) => {
        url.searchParams.append("resortId[]", resortId);
      });
      

      let testFeed;
      if (useTestData) {
        testFeed = fetch(testUrl).then((response) => response.json());
      }
      let apiFeed;
      if (useTestData && resortIds.length === 1) {
        apiFeed = testFeed.then((data) => ({
          Resorts: [data],
        }));
      } else {
        apiFeed = fetch(url)
          .then((response) => response.json())
          .then(async (data) => {
            if (useTestData) {
              const testIndex = resortIds.indexOf(TEST_ID);
              const testData = await testFeed;

              data.Resorts = [
                ...data.Resorts.slice(0, testIndex),
                testData,
                ...data.Resorts.slice(testIndex),
              ];

              return data;
            }
            return data;
          })
          .then((data) => {
              data.Resorts = data.Resorts.map((resort, index) => ({
                id: resortIds[index],
                ...resort,
              }));
              return data;
          });
      }

      apiFeed
        .then((data) => {
          setResortAPIData(combineResortFeed(data, primaryResort));
        })
        .catch(console.error);
    }
  });

  return resortAPIData;
};

export default GetAPIFeedData;
