import classnames from "classnames";
import styles from "../../../styles/components/storybook/widgets/DirectionsWidget.module.scss";
import Icon from "../../shared/Icon/Icon";

const Road = ({ dataEndpoints }) => {
  if (!dataEndpoints) return null;

  const {
    Name,
    chains,
    statusToDisplay,
    Summary,
    iconName,
    defaultIconName,
    iconType,
    summary,
  } = dataEndpoints;

  const className = classnames(
    styles.road,
    summary ? styles.hasSummary : undefined
  );

  return (
    <li className={className}>
      <div className={styles.icons}>
        <Icon
          className={styles.iconLight}
          name={iconName || defaultIconName}
          type={iconType}
        />
      </div>
      <div className={styles.info}>
        {Name && <p className={styles.name}>{Name}</p>}
        {statusToDisplay && (
          <h5 className={styles.status}>{statusToDisplay}</h5>
        )}
        {chains && <p className={styles.chains}>Chains Required</p>}
        {summary && <p className={styles.summary}>{Summary}</p>}
      </div>
    </li>
  );
};

export default Road;
