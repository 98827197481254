import classnames from "classnames";
import styles from "../../../styles/components/storybook/atoms/LabeledItem.module.scss";

const LabeledItem = (props) => {
  const {
    label,
    endpoint,
    secondLabel,
    secondEndpoint,
    centerText,
    marginTop,
  } = props;

  const classNames = classnames(
    styles.component,
    centerText && styles.centerText,
    marginTop && styles.marginTop
  );

  if (!label && !endpoint && !secondLabel && !secondEndpoint) return null;

  return (
    <p className={classNames}>
      {label && endpoint && (
        <>
          <span className={styles.label}>
            {label}: <strong>{endpoint}</strong>
          </span>
        </>
      )}
      {secondLabel && secondEndpoint && (
        <>
          <span> / </span>
          <span className={styles.label}>
            {secondLabel}: <strong>{secondEndpoint}</strong>
          </span>
        </>
      )}
    </p>
  );
};

export default LabeledItem;
