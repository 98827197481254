import Lift from "../../molecules/Lift";

import styles from "../../../../styles/components/storybook/organisms/LiftsAccordion.module.scss";

const CategorizedLifts = ({
  categorizedLifts,
  columns,
  statusOverrides,
  showTimestamp,
}) => {
  return categorizedLifts.map(({ category, lifts }, index) => {
    return (
      <div className={styles.component} key={index}>
        {category !== "Uncategorized" && (
          <div className={styles.categoryTitle}>
            <h3>{category}</h3>
          </div>
        )}
        <div className={styles.accordionPseudoElement}></div>
        <ul
          className={styles.liftList}
          style={{
            gridTemplateRows: `repeat(${Math.ceil(
              lifts.length / columns
            )}, 1fr)`,
          }}
        >
          {lifts.map((lift, index) => (
            <Lift
              key={`${lift.key}-${index}`}
              name={lift.name}
              iconPath={lift.iconPath}
              iconType="lift-types"
              statusIcon={lift.statusIcon}
              status={statusOverrides[lift.status] || lift.status}
              rideTime={lift.rideTime}
              hours={lift.hours}
              updateTimestamp={lift.updateTimestamp}
              showTimestamp={showTimestamp}
            />
          ))}
        </ul>
      </div>
    );
  });
};

export default CategorizedLifts;
