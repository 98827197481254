import { useContext } from "react";
import LanguageContext from "./create-context/languageContext";
import ResortJSONConfigContext from "./create-context/resortJSONConfigContext";

export function LanguageProvider({ children }) {
    const config = useContext(ResortJSONConfigContext)
    const isFrench = config?.isFrench || false;

    return (
        <LanguageContext.Provider value={{ isFrench }}>
            {children}
        </LanguageContext.Provider>
    );
}