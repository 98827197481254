import classNames from "classnames";
import styles from "../../../styles/components/storybook/widgets/TitleWidget.module.scss";

const TitleWidget = ({ id, title }) => {
  return (
    <section id={id} className={styles.component}>
      <div className={styles.container}>
        <h1
          className={classNames(styles.title, {
            [styles.titleReducedSize]: title.length >= 25,
          })}
        >
          {title}
        </h1>
      </div>
    </section>
  );
};

export default TitleWidget;
