import DOMPurify from "isomorphic-dompurify";
import styles from "../../../styles/components/storybook/molecules/AlertBar.module.scss";

const AlertBar = (props) => {
  const { alert } = props;
  if (!alert || alert === "--") return null;

  return (
    <section className={styles.component}>
      <div className={styles.wrapper}>
        <p
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(alert),
          }}
        ></p>
      </div>
    </section>
  );
};

export default AlertBar;
