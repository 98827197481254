import { useContext, useState } from "react";
import classnames from "classnames";
import styles from "../../../styles/components/storybook/widgets/ActivityWidget.module.scss";
import Widget from "../organisms/Widget";
import CurrentActivityMountainAreaContext from "../../../lib/context/create-context/currentActivityMountainAreaContext";
import ActivitiesAccordion from "../organisms/activities/ActivitiesAccordion";
import {
  getActivityMountainAreasEndpoints,
  getActivityWidgetHeaderDataEndpoints,
} from "../../../lib/components/activityWidget/activityEndpoints";
import LanguageContext from "../../../lib/context/create-context/languageContext";

const ActivityWidget = (props) => {
  const activityMountainContext = useContext(
    CurrentActivityMountainAreaContext
  );

  const { isFrench } = useContext(LanguageContext);
  const currentActivityMountainArea =
    activityMountainContext?.currentActivityMountainArea;

  const {
    id,
    title,
    data,
    link: { label: headerLinkLabel, url: headerLink },
    showClosedToggleDefaultValue,
    options,
  } = props;
  const [filteredText, setFilteredText] = useState("");
  const [showClosed, setShowClosed] = useState(true);
  const [showSpecificActivityOnly, setShowSpecificActivityOnly] = useState(
    options?.showSpecificActivityToggleDefaultValue || false
  );
  const [showSpecificActivityToggle, setShowSpecificActivityToggle] = useState(
    options?.showSpecificActivityToggle || false
  );

  const classNames = classnames(styles.component, styles.activity);
  const mountainAreas = getActivityMountainAreasEndpoints(data?.MountainAreas);
  const headerStats = getActivityWidgetHeaderDataEndpoints(data);

  return (
    <Widget
      id={id}
      headerTitle={title}
      headerLink={headerLink}
      headerLinkLabel={headerLinkLabel}
      padding={false}
      open={headerStats.open}
      total={headerStats.total}
      placeholder={isFrench ? "Rechercher une activité" : "Search Activities"}
      filteredText={filteredText}
      setFilteredText={setFilteredText}
      showClosed={showClosed}
      setShowClosed={setShowClosed}
      showClosedToggleDefaultValue={showClosedToggleDefaultValue}
      showSpecificActivityToggle={showSpecificActivityToggle}
      setShowSpecificActivityToggle={setShowSpecificActivityToggle}
      showSpecificActivityOnly={showSpecificActivityOnly}
      setShowSpecificActivityOnly={setShowSpecificActivityOnly}
      specificActivityToggleLabel={options?.specificActivityToggleLabel || ""}
    >
      <div className={classNames}>
        <ActivitiesAccordion
          mountainAreas={mountainAreas}
          filteredMountainArea={currentActivityMountainArea}
          filteredText={filteredText}
          showClosed={showClosed}
          showSpecificActivityOnly={showSpecificActivityOnly}
          showSpecificActivityToggleID={
            options?.showSpecificActivityToggleID || ""
          }
        />
      </div>
    </Widget>
  );
};

export default ActivityWidget;
