import styles from "../../../styles/components/storybook/widgets/NewsletterSignupWidget.module.scss";
import Widget from "../organisms/Widget";

const NewsletterSignupWidget = (props) => {
  const { title, form, id } = props;

  if (!form) return null;

  return (
    <Widget id={id} background={false} padding={false}>
      <div className={styles.newsletterWrapper}>
        <div className={styles.wrapper}>
          {title && <p className={styles.title}>{title}</p>}
        </div>
        <div
          className={styles.wrapper}
          dangerouslySetInnerHTML={{ __html: form }}
        ></div>
      </div>
    </Widget>
  );
};

export default NewsletterSignupWidget;
