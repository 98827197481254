import styles from "../../../styles/components/storybook/atoms/LinkedImage.module.scss";

const LinkedImage = (props) => {
  const { href, image, title, caption, type } = props;

  if (!href || !image) return null;
  const alt = `${title} ${type}` || "Map Image";

  return (
    <figure className={styles.component}>
      <a href={href} target="_blank" rel="noreferrer noopener nofollow">
        <div
          className={styles.backgroundImg}
          style={{ backgroundImage: `url(${image})` }}
        >
          <img src={image} alt={alt} />
        </div>
        <figcaption>
          {title && (
            <div>
              <h6 className={styles.title}>{title}</h6>
            </div>
          )}
          {caption && (
            <div>
              <h6 className={styles.caption}>{caption}</h6>
            </div>
          )}
        </figcaption>
      </a>
    </figure>
  );
};

export default LinkedImage;
