import Trail from "../../molecules/Trail";

import styles from "../../../../styles/components/storybook/organisms/TrailsAccordion.module.scss";

const CategorizedTrails = ({ categorizedTrails, columns }) => {
  return categorizedTrails.map(({ category, trails }, categoryIndex) => {
    return (
      <div className={styles.component} key={categoryIndex}>
        {category !== "Uncategorized" && (
          <div className={styles.categoryTitle}>
            <h3>{category}</h3>
          </div>
        )}
        <div className={styles.accordionPseudoElement}></div>
        <ul
          className={styles.trailList}
          style={{
            gridTemplateRows: `repeat(${Math.ceil(
              trails.length / columns
            )}, 1fr)`,
          }}
        >
          {trails.map((trail, trailIndex) => (
            <Trail
              key={`${trail.name}-${trail.difficultyIconPath}-${trailIndex}`}
              name={trail.name}
              status={trail.status}
              openStatusClass={trail.openStatusClass}
              difficultyIconPath={trail.iconPath}
              difficultyIconType="trail-icons"
              featureIcons={trail.featureIcons}
              featureIconType="trail-features"
            />
          ))}
        </ul>
      </div>
    );
  });
};

export default CategorizedTrails;
