import { useState } from "react";
import CurrentActivityMountainAreaContext from "./create-context/currentActivityMountainAreaContext";

//Overrides and Current Mountain Area States
export function ActivityMountainProvider({ children }) {
  //Global Prop to share currentMountainArea to Lifts/Trails/Activities components.
  //setCurrentMountainArea is called in TabsMountainAreas component.
  const [currentActivityMountainArea, setCurrentActivityMountainArea] =
    useState(null);

  return (
    <CurrentActivityMountainAreaContext.Provider
      value={{ currentActivityMountainArea, setCurrentActivityMountainArea }}
    >
      {children}
    </CurrentActivityMountainAreaContext.Provider>
  );
}
