import classNames from "classnames";
import styles from "../../../styles/components/storybook/atoms/ToggleSwitch.module.scss";

const ToggleSwitch = ({ title, isToggled, setIsToggled, padding, id }) => {

  const { bottom, right } = padding ? padding : { bottom: false, right: false };

  const componentClassName = classNames(
    styles.component,
    right ? styles.componentPaddingRight : "",
    bottom ? styles.componentPaddingBottom : ""
  );

  const switchClassName = classNames(
    styles.switch,
    isToggled ? styles.switchToggled : ""
  );

  const sliderClassName = classNames(
    styles.slider,
    isToggled ? styles.sliderToggled : ""
  );

  return (
    <div className={componentClassName} id={id}>
      <label>{title}</label>
      <div className={switchClassName} onClick={() => setIsToggled(!isToggled)}>
        <span className={sliderClassName} />
      </div>
    </div>
  );
};

export default ToggleSwitch;
