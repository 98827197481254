import classnames from "classnames";
import styles from "../../../styles/components/storybook/molecules/LinkedImageList.module.scss";
import LinkedImage from "../atoms/LinkedImage";

const LinkedImageList = (props) => {
  const { images = [], type } = props;
  if (!images || images.length === 0) return null;

  const classNames = classnames(
    styles.component,
    images.length === 1 && styles.singleImage,
    images.length === 2 && styles.doubleImage
  );

  return (
    <ul className={classNames}>
      {images.map((image, index) => (
        <li key={index}>
          <LinkedImage
            type={type}
            href={image.link ? image.link : image.MapUrl}
            image={image.image ? image.image : image.ThumbnailUrl}
            title={image.title ? image.title : image.Title}
            caption={image.caption}
          />
        </li>
      ))}
    </ul>
  );
};

export default LinkedImageList;
