import classnames from "classnames";
import styles from "../../../styles/components/storybook/atoms/Widget.module.scss";
import Link from "../atoms/Link";

const WidgetFooter = (props) => {
  const { link, label, background = true } = props;

  const classNames = classnames(
    styles.footer,
    background ? styles.background : undefined
  );

  if (!link || !label) return null;
  return (
    <div className={classNames}>
      <Link label={label} href={link} type="internalLink" />
    </div>
  );
};

export default WidgetFooter;
