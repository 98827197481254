import styles from "../../../../styles/components/storybook/organisms/ActivitiesAccordion.module.scss";

import Activity from "../../molecules/Activity";

const CategorizedActivities = ({ categorizedActivities, columns }) => {
  return categorizedActivities.map(
    ({ category, activities }, categoryIndex) => {
      return (
        <div className={styles.component} key={categoryIndex}>
          {category !== "Uncategorized" && (
            <div className={styles.categoryTitle}>
              <h3>{category}</h3>
            </div>
          )}
          <div className={styles.accordionPseudoElement}></div>
          <ul
            className={styles.activityList}
            style={{
              gridTemplateRows: `repeat(${Math.ceil(
                activities.length / columns
              )}, 1fr)`,
            }}
          >
            {activities.map((activity, activityIndex) => (
              <Activity
                key={`${activity.key}-${activityIndex}`}
                name={activity.name}
                status={activity.status}
                statusIcon={activity.statusIcon}
                hours={activity.hours}
                icon={activity.icon}
              />
            ))}
          </ul>
        </div>
      );
    }
  );
};

export default CategorizedActivities;
