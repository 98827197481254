import Link from "../atoms/Link";
import styles from "../../../styles/components/storybook/molecules/LinkList.module.scss";

const LinkList = (props) => {
  const { id, title, links, linkType } = props;
  if (!links || links.length === 0) return null;

  return (
    <div id={id} className={styles.component}>
      <h5 className={styles.title}>{title}</h5>
      <ul className={styles.list}>
        {links.map((link, i) => {
          return (
            <li key={i}>
              <Link label={link.Title} href={link.Url} type="internalLink" linkType={linkType} />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default LinkList;
