import classNames from "classnames";
import { useRef, useEffect, useState, useLayoutEffect } from "react";
import Button from "../atoms/Button";

import styles from "../../../styles/components/storybook/molecules/NavBar.module.scss";

const NavBar = ({
  anchors,
  onAnchorActive,
  inverseButtonTextOnHover,
  className,
}) => {
  const navBarRef = useRef();
  const [activeAnchor, setActiveAnchor] = useState(-1);

  useEffect(() => {
    onAnchorActive(activeAnchor >= 0 ? anchors[activeAnchor] : null);
  }, [activeAnchor, onAnchorActive]);

  useLayoutEffect(() => {
    // TODO: Bug - if maps doesn't exist, it will throw an error because height of a non-existing element is null
    const navBar = navBarRef.current;

    const headerHeight = document.querySelector("header")?.clientHeight || 0;
    const stickyNavHeight =
      document.querySelector(`.sticky-header`)?.clientHeight || 0;

    let anchorPositions = [];
    let documentHeight = document.body.offsetHeight;
    setTimeout(() => {
      documentHeight = document.body.offsetHeight;

      anchorPositions = anchors.map(({ title, link }) => {
        const widgetTitle = document
          .querySelector(`${link} .widget-title`)
          ?.getBoundingClientRect();

        let top =
          (widgetTitle?.top || 0) +
          window.scrollY +
          widgetTitle?.height -
          (stickyNavHeight + headerHeight);

        return {
          title,
          link,
          top,
        };
      });
    }, 250);

    const onScroll = () => {
      const activeAnchors =
        // Magin number - Intentionally decreasing the height of body so it will always be
        // smaller than window.innerHeight + window.scrollY at the bottom of the page
        documentHeight - 10 <= window.innerHeight + window.scrollY
          ? anchorPositions // Include the last Nav if scroll hits the bottom of the page
          : anchorPositions.filter(({ top }) => top < window.scrollY);
      setActiveAnchor(activeAnchors.length - 1);
    };

    if (navBar) {
      window.addEventListener("scroll", onScroll);
      return () => {
        window.removeEventListener("scroll", onScroll);
      };
    }
  }, [navBarRef, anchors]);

  return (
    <ul
      ref={navBarRef}
      className={classNames(styles.component, { [className]: !!className })}
    >
      {anchors.map(({ title, link }, index) => (
        <li className={styles.buttonWrapper} key={index}>
          <Button
            title={title}
            link={link}
            className={classNames(styles.button, {
              [styles.inverseOnHover]: !!inverseButtonTextOnHover,
            })}
            isTransparent={
              !(activeAnchor >= 0 ? activeAnchor == index : index == 0)
            }
          />
        </li>
      ))}
    </ul>
  );
};

export default NavBar;
