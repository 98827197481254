import styles from "../../../styles/components/storybook/atoms/LabelUnitToggle.module.scss";

const LabelUnitToggle = (props) => {
  const {
    unit,
    setUnit,
    label,
    topUnitLabel,
    topUnitType,
    bottomUnitLabel,
    bottomUnitType,
  } = props;

  return (
    <div className={styles.labelUnitToggle}>
      <div>
        <h2 className={styles.label}>{label}</h2>
      </div>
      <div>
        <ul className={styles.unitToggle}>
          <button
            onClick={() => {
              setUnit(topUnitType);
            }}
            className={unit === topUnitType ? styles.active : undefined}
          >
            {topUnitLabel}
          </button>
          <button
            onClick={() => {
              setUnit(bottomUnitType);
            }}
            className={unit === bottomUnitType ? styles.active : undefined}
          >
            {bottomUnitLabel}
          </button>
        </ul>
      </div>
    </div>
  );
};
export default LabelUnitToggle;
