import { getHoursToday } from "./activityWidget";

const getActivityEndpoint = (activity) => ({
  key: activity.Name,
  name: activity.Name,
  statusIcon: activity.StatusIcon,
  status: activity.Status,
  hours: getHoursToday(activity.Hours),
  icon: activity.ActivityIcon,
});

export const getActivityMountainAreasEndpoints = (mountainAreas) => {
  if (!mountainAreas) return null;

  const getCategorizedActivitiesEndpoints = (categorizedActivities) => {
    return Object.entries(categorizedActivities)
      .map(([category, activities]) => ({
        category,
        activities: activities.map((activity) => getActivityEndpoint(activity)),
      }))
      .sort((a, b) => {
        if (a.category === "Uncategorized") {
          return -1;
        } else {
          return 1;
        }
      });
  };

  const getUncategorizedActivitiesEndpoints = (activities) => {
    return activities.map((activity) => getActivityEndpoint(activity));
  };

  let endpoints = Object.entries(mountainAreas);
  endpoints = endpoints.map(([key, value]) => {
    return [
      key,
      {
        title: value.Name,
        activities: value.Activities,
        total: value.Activities.length,
        open: value.Activities.filter(
          (activity) => activity?.Status?.toLowerCase() === "open"
        ).length,
        activities: getUncategorizedActivitiesEndpoints(value.Activities),
        categorizedActivities: getCategorizedActivitiesEndpoints(
          value.CategorizedActivities
        ),
      },
    ];
  });

  endpoints = Object.fromEntries(endpoints);
  endpoints = Object.values(endpoints);

  return endpoints;
};

export const getActivityWidgetHeaderDataEndpoints = (data) => {
  if (!data) return null;

  let openActivities = data?.AggregatedStats.TotalOpenActivities.toString();
  openActivities = openActivities === "--" ? null : openActivities;

  let allActivities = data?.AggregatedStats.TotalActivities.toString();
  allActivities = allActivities === "--" ? null : allActivities;

  const endpoints = {
    open: openActivities,
    total: allActivities,
  };
  return endpoints;
};
