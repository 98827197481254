import styles from "../../../styles/components/storybook/widgets/StatsWidget.module.scss";
import StatBaseItem from "../atoms/StatBaseItem";
import Icon from "../../shared/Icon/Icon";
import { isAnimated } from "../../../lib/icons/isAnimated";

const StatItem = (props) => {
  const {
    className,
    hiddenClass,
    total,
    value,
    label,
    status,
    iconProps,
    statType,
  } = props;

  const statistic = (
    <Statistic
      statType={statType}
      total={total}
      value={value}
      status={status}
    />
  );

  switch (statType) {
    case "icon": {
      return (
        <StatBaseItem
          className={className}
          hiddenClass={hiddenClass}
          label={label}
          statistic={statistic}
          withIcon={true}
        >
          {isAnimated(iconProps) ? (
            <div className={styles.animatedIcon}>
              <Icon className={styles.icon} {...iconProps} />
            </div>
          ) : (
            <div>
              <Icon className={styles.icon} {...iconProps} />
            </div>
          )}
        </StatBaseItem>
      );
    }
    default:
      return (
        <StatBaseItem
          className={className}
          label={label}
          statistic={statistic}
        />
      );
  }
};

export default StatItem;

const Statistic = (props) => {
  const { statType, total, value, status } = props;

  switch (statType) {
    case "total": {
      return <StatTotal total={total} value={value} />;
    }
    case "percent": {
      return <StatPercent value={value} />;
    }
    case "icon": {
      return <StatIcon status={status} />;
    }
    default:
      return <StatTotal className="default" total="--" value="--" />;
  }
};

const StatTotal = (props) => {
  const { total, value } = props;
  const isValue = value || value === 0;
  const isTotal = total || total === 0;

  return (
    <>
      <span className={styles.statBig}>{isValue ? value : "--"}</span>
      <span className={styles.statSmall}> / {isTotal ? total : "--"}</span>
    </>
  );
};

const StatPercent = (props) => {
  const { value } = props;
  const isValue = value || value === 0;

  return (
    <>
      <span className={styles.statBig}>{isValue ? value : "--"}</span>
      <span className={styles.statSmall}> %</span>
    </>
  );
};

const StatIcon = (props) => {
  const { status } = props;

  const isStatus = !!status;

  return <span className={styles.statBig}>{isStatus ? status : "--"}</span>;
};
