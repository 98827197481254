import LinkedImageList from "../molecules/LinkedImageList";
import Widget from "../organisms/Widget";

const MapsWidget = (props) => {
  const {
    headerTitle,
    link: { url: headerLink, label: headerLinkLabel },
    mapList,
    id,
    type,
    extraMarginTop,
  } = props;

  if (!mapList || mapList.length === 0) return null;

  return (
    <Widget
      extraMarginTop={extraMarginTop}
      headerTitle={headerTitle}
      largeTitle={true}
      headerBackground={false}
      headerLink={headerLink}
      headerLinkLabel={headerLinkLabel}
      id={id}
    >
      <LinkedImageList images={mapList} type={type} />
    </Widget>
  );
};

export default MapsWidget;
