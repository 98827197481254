import { useContext, useState } from "react";
import AppContext from "./create-context/appContext";

// States that are specific to the app rather than a component
export function AppProvider({ children }) {
  const [cjsLoaded, setCjsLoaded] = useState(false);
  const value = {
    cjsLoaded,
    setCjsLoaded,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

export const useCjsLoaded = () => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error("useCjsLoaded must be used within a AppProvider");
  }

  const { cjsLoaded, setCjsLoaded } = context;

  return { cjsLoaded, setCjsLoaded };
};
