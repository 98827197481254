import { useContext } from "react";
import { format as formatDate } from "date-fns";

import Indicator from "../atoms/Indicator";

import LanguageContext from "../../../lib/context/create-context/languageContext";

import styles from "../../../styles/components/storybook/molecules/Hours.module.scss";
import Link from "../atoms/Link";

const Hours = ({ lodge }) => {
  const {isFrench} = useContext(LanguageContext);

  if (!lodge) {
    return null;
  }

  const { label, hours, isOpen, moreHoursUrl } = lodge;

  const todayDayOfWeek = formatDate(new Date(), "EEEE");

  const { Open, Close } = hours[todayDayOfWeek] || {};

  const openNowText = isFrench ? "Ouvert maintenant" : "Open Now";
  const closedText = isFrench ? "Fermé" : "Closed";
  const moreHoursText = isFrench ? "Toutes les heures" : "More Hours";

  return (
    <section className={styles.component}>
      <div className={styles.wrapper}>
        <div className={styles.columnWrapper}>
          <span className={styles.lodgeName}>{label}</span>
          <div className={styles.indicatorAndHoursWrapper}>
            <span className={styles.status}>
              <Indicator
                status={isOpen === "true" ? "open" : "closed"}
                className={styles.iconStatus}
              />
              {isOpen === "true" ? openNowText : closedText}
            </span>
            {Open && Close && (
              <span className={styles.hours}>
                {Open.replace(" ", "")} - {Close.replace(" ", "")}
              </span>
            )}
          </div>
        </div>
        <Link
          className={styles.moreHours}
          label={moreHoursText}
          href={moreHoursUrl}
        />
      </div>
    </section>
  );
};

export default Hours;
