import { useContext, useState } from "react";
import CurrentWeatherAreaContext from "../create-context/currentWeatherAreaContext";
import ResortAPIFeedContext from "../create-context/resortAPIFeedContext";
import ResortJSONConfigContext from "../create-context/resortJSONConfigContext";

//Overrides and Current Weather Area States
export function WeatherProvider({ children }) {
  //Global Prop to share currentWeatherArea to  components.
  //setCurrentWeatherArea is called in Tabs component.

  const config = useContext(ResortJSONConfigContext);
  const overrideDefaultArea = (
    (config?.contentBlocks || []).find(({ _type }) => _type === "weather")
      ?.tabs || []
  ).find(({ isDefault }) => isDefault)?.key;

  const [currentWeatherArea, setCurrentWeatherArea] = useState(
    overrideDefaultArea || "zero"
  );

  return (
    <CurrentWeatherAreaContext.Provider
      value={[currentWeatherArea, setCurrentWeatherArea]}
    >
      {children}
    </CurrentWeatherAreaContext.Provider>
  );
}
