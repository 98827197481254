const Loading = ({ configStyles }) => {
  let defaultFont = configStyles?.loadingFont;
  let defaultColor = configStyles?.colors.loadingColor
    ? configStyles?.colors.loadingColor
    : "black";
  let defaultBackgroundColor = configStyles?.colors.loadingBackgroundColor
    ? configStyles.colors.loadingBackgroundColor
    : "rgba(0,0,0,0.8)";

  const styles = `
    @keyframes rotateAnimation {
      from {
          transform: rotate(0deg);
      }
      to { 
          transform: rotate(360deg);
      }
    }

    @keyframes progressAnimation {
      0% {
        stroke-dasharray: 1px, 200px;
        stroke-dashoffset: 0px;
      }
      50% {
        stroke-dasharray: 100px, 200px;
        stroke-dashoffset: -15px;
      }
      100% {
        stroke-dasharray: 100px, 200px;
        stroke-dashoffset: -125px;
      }
    }

    .loading-container {
      background: ${defaultBackgroundColor};
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: 500px;
      
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      z-index: 1000;
    }

    .loading-text {
      margin-top: .5rem;
      font-family: ${defaultFont};
      color: ${defaultColor};
    }

    .loading-circle-container {
      height: 40px;
      width: 40px;
      display: inline-block;
      color: ${defaultColor};
      animation: 1.4s linear 0s infinite normal none running rotateAnimation;
    }
    .loading-circle {
      stroke: currentcolor;
      stroke-dasharray: 80px, 200px;
      stroke-dashoffset: 0px;
      animation: 1.4s ease-in-out 0s infinite normal none running progressAnimation;
    }
  `;

  return (
    <>
      <style>{styles}</style>
      <div className="loading-container">
        <span className="loading-circle-container">
          <svg viewBox="22 22 44 44">
            <circle
              className="loading-circle"
              cx="44"
              cy="44"
              r="20.2"
              fill="none"
              strokeWidth="3.6"
            ></circle>
          </svg>
        </span>
        <div className="loading-text">
          {configStyles?.isFrench ? "Chargement…" : "Loading..."}
        </div>
      </div>
    </>
  );
};

export default Loading;
