export default function ResortStyles({
  colors,
  palette,
  fonts,
  brandFontWeights,
  utilityFontWeights,
}) {
  let colorStyles, fontStyles, brandFontWeightStyles, utilityFontWeightStyles;

  if (colors && palette) {
    colorStyles = Object.entries(colors).map(
      ([key, value]) => `--color-${key}: ${palette[value]};`
    );
  }
  if (fonts) {
    fontStyles = Object.entries(fonts).map(
      ([key, value]) => `--${key}: ${value};`
    );
  }
  if (brandFontWeights) {
    brandFontWeightStyles = Object.entries(brandFontWeights).map(
      ([key, value]) => `--brandFontWeight-${key}: ${value};`
    );
  }
  if (utilityFontWeights) {
    utilityFontWeightStyles = Object.entries(utilityFontWeights).map(
      ([key, value]) => `--utilityFontWeight-${key}: ${value};`
    );
  }

  return (
    <style>{`
      :root {
        ${colorStyles !== undefined ? colorStyles.join("") : ""}
        ${fontStyles !== undefined ? fontStyles.join("") : ""}
        ${
          brandFontWeightStyles !== undefined
            ? brandFontWeightStyles.join("")
            : ""
        }
        ${
          utilityFontWeightStyles !== undefined
            ? utilityFontWeightStyles.join("")
            : ""
        }
      }
    `}</style>
  );
}
