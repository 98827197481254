import classNames from "classnames";
import styles from "../../../styles/components/storybook/atoms/Button.module.scss";

const Button = ({ title, link, isTransparent, className }) => {
  const variant = isTransparent ? styles.transparent : "";

  return (
    <a
      className={classNames(styles.component, {
        [variant]: !!variant,
        [className]: !!className,
      })}
      href={link}
    >
      {title}
    </a>
  );
};

export default Button;
