import { useContext, useState, useEffect } from "react";
import styles from "../../../styles/components/storybook/widgets/TrailWidget.module.scss";
import CurrentMountainAreaContext from "../../../lib/context/create-context/currentMountainAreaContext";
import LanguageContext from "../../../lib/context/create-context/languageContext";
import {
  getTrailsDataEndpoints,
  getTrailWidgetHeaderDataEndpoints,
} from "../../../lib/components/trailWidget/trailWidget";
import Widget from "../organisms/Widget";
import {
  ALPHABETICAL_ASC_SYMBOL,
  buildSortOptions,
} from "../../../lib/components/trailWidget/build-sort-options";

import { getResortFeatures } from "../../../lib/components/trailWidget/get-features";
import { getResortGrooming } from "../../../lib/components/trailWidget/get-grooming";
import { getResortDifficulties } from "../../../lib/components/trailWidget/get-difficulty";

import { useHash } from "../../../lib/hooks/hash";
import TrailsAccordion from "../organisms/trails/TrailsAccordion";

const TrailWidget = (props) => {
  const {
    id,
    title,
    data,
    options: {
      showClosedToggleDefaultValue,
      showLegendToggleDefaultValue,
      showUphillToggleDefaultValue,
      showUphillToggle,
      showLegendOnLoad,
      showSort,
      groomingLabel,
    },
    configFilters,
    trailFeatureMappings,
    sortOptionsLabelMappings,
  } = props;

  const filteredMountainArea = useContext(
    CurrentMountainAreaContext
  )?.currentMountainArea;

  const [filteredText, setFilteredText] = useState("");
  const [showClosed, setShowClosed] = useState(showClosedToggleDefaultValue);
  const [showLegend, setShowLegend] = useState(showLegendToggleDefaultValue);
  const [showUphillOnly, setShowUphillOnly] = useState(
    showUphillToggleDefaultValue
  );
  const [sortTrails, setSortTrails] = useState(ALPHABETICAL_ASC_SYMBOL);
  //Get trails header stats
  const headerStats = getTrailWidgetHeaderDataEndpoints(data);

  const mountainAreas = getTrailsDataEndpoints(
    data?.MountainAreas,
    configFilters
  );

  const trails = mountainAreas.flatMap((area) => {
    if (area.categorizedTrails.length) {
      return area.categorizedTrails
        .filter((categorizedTrails) => categorizedTrails?.trails?.length > 0)
        .reduce((acc, { trails }) => [...acc, ...trails], [])
        .flat();
    }
    return area.trails;
  });

  const resortDifficulties = getResortDifficulties(trails);

  const resortFeatures = getResortFeatures(trailFeatureMappings, trails);
  const resortGrooming = getResortGrooming(trailFeatureMappings, trails);

  const sortOptions = buildSortOptions(trails, sortOptionsLabelMappings);

  const { isFrench } = useContext(LanguageContext);

  const translate = (text) => {
    const translations = {
      Difficulty: "Niveau de difficulté",
      Features: "Profil",
      Grooming: "État",
    };

    return isFrench ? translations[text] : text;
  };

  const legendGroups = [
    {
      type: translate("Difficulty"),
      legends: resortDifficulties,
    },
    {
      type: translate("Features"),
      legends: resortFeatures,
    },
    {
      type: groomingLabel ? groomingLabel : translate("Grooming"),
      legends: resortGrooming,
    },
  ].filter(({ legends }) => Object.keys(legends).length > 0);

  const [hash] = useHash();

  useEffect(() => {
    const checkAndScroll = () => {
      const uphillElement = document.getElementById("uphill");
      if (uphillElement) {
        setTimeout(() => {
          uphillElement.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }, 750);
        setShowUphillOnly(true);
      }
    };

    if (hash === "#uphill") {
      checkAndScroll();
    }
  }, [hash]);

  return (
    <>
      {data && (
        <Widget
          id={id}
          headerTitle={title}
          padding={false}
          headerPaddingTop={true}
          open={headerStats.open}
          total={headerStats.total}
          placeholder={isFrench ? "Rechercher un sentier" : "Search Trails"}
          filteredText={filteredText}
          setFilteredText={setFilteredText}
          showClosed={showClosed}
          showClosedLabel={
            isFrench ? "VOIR LES SENTIERS FERMÉS" : "Show Closed"
          }
          setShowClosed={setShowClosed}
          showLegend={showLegend}
          setShowLegend={setShowLegend}
          showUphillOnly={showUphillOnly}
          setShowUphillOnly={setShowUphillOnly}
          showUphillToggle={showUphillToggle}
          showSort={showSort} // Toggle
          sortTrails={sortTrails} // Current Sort
          sortOptions={sortOptions} // Options
          setSortTrails={setSortTrails}
          //
          legendGroups={legendGroups}
          //
          showClosedToggleDefaultValue={showClosedToggleDefaultValue}
          showLegendToggleDefaultValue={showLegendToggleDefaultValue}
          showUphillToggleDefaultValue={showUphillToggleDefaultValue}
          showLegendOnLoad={showLegendOnLoad}
        >
          <div className={styles.component}>
            <TrailsAccordion
              mountainAreas={mountainAreas}
              trailSort={sortTrails}
              filteredMountainArea={filteredMountainArea}
              filteredText={filteredText}
              showClosed={showClosed}
              showUphillOnly={showUphillOnly}
              configFilters={configFilters}
              showLegend={showLegend}
            />
          </div>
        </Widget>
      )}
    </>
  );
};

export default TrailWidget;
