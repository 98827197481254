import { useEffect, useState, useContext } from "react";

import Accordion from "../../atoms/Accordion";

import useWindowDimensions from "../../../../lib/hooks/windowDimensions";

import { filterAndSortLiftsBuilder } from "../../../../lib/components/liftWidget/filterAndSortLifts";
import UncategorizedLifts from "./UncategorizedLifts";
import CategorizedLifts from "./CategorizedLifts";

import styles from "../../../../styles/components/storybook/organisms/LiftsAccordion.module.scss";

import LanguageContext from "../../../../lib/context/create-context/languageContext";

const LiftsAccordion = ({
  mountainAreas,
  statusOverrides,
  filteredMountainArea,
  filteredText,
  showClosed,
  showTimestamp,
}) => {
  const { isFrench } = useContext(LanguageContext);

  const [liftGroups, setLiftGroups] = useState([]);

  useEffect(() => {
    const parsedFilterText = filteredText.replace(/[\W_]+/g, " ").toLowerCase();

    const filterAndSortLifts = filterAndSortLiftsBuilder(
      parsedFilterText,
      showClosed
    );

    const filteredLiftGroups = mountainAreas
      // Filter by current mountain area
      .filter(({ title }) =>
        filteredMountainArea ? title === filteredMountainArea : true
      )
      .map((liftGroup) => {
        const categorizedLifts = (liftGroup.categorizedLifts || [])
          .map((categoryGroup) => ({
            ...categoryGroup,
            lifts: filterAndSortLifts(categoryGroup.lifts),
          }))
          // Filter out categories that has empty lifts
          .filter(({ lifts }) => lifts.length > 0);

        return {
          ...liftGroup,
          lifts: filterAndSortLifts(liftGroup.lifts),
          categorizedLifts,
        };
      })
      // Filter empty lifts
      .filter(
        (liftGroup) =>
          liftGroup.lifts.length > 0 || liftGroup.categorizedLifts.length > 0
      );

    setLiftGroups(filteredLiftGroups);
  }, [
    mountainAreas,
    setLiftGroups,
    filteredMountainArea,
    filteredText,
    showClosed,
  ]);

  const { width } = useWindowDimensions();
  let columns = 1; //Mobile
  if (width >= 768) {
    //Small Breakpoint
    columns = 2;
  }
  if (width >= 1200) {
    //XL Breakpoint
    columns = 3;
  }

  return liftGroups.length > 0 ? (
    liftGroups.map((liftGroup, i) => (
      <Accordion title={liftGroup.title} key={`${liftGroup.title}-${i}`}>
        {liftGroup.categorizedLifts.length > 0 ? (
          <CategorizedLifts
            categorizedLifts={liftGroup.categorizedLifts}
            columns={columns}
            statusOverrides={statusOverrides}
            showTimestamp={showTimestamp}
          />
        ) : (
          <UncategorizedLifts
            lifts={liftGroup.lifts}
            columns={columns}
            statusOverrides={statusOverrides}
            showTimestamp={showTimestamp}
          />
        )}
      </Accordion>
    ))
  ) : (
    <div className={styles.noMatchingLifts}>
      {isFrench
        ? "Aucune remontées ne correspond aux critères de recherche"
        : "No Lifts Match Search Criteria"}
    </div>
  );
};

export default LiftsAccordion;
