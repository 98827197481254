import { getTextFromSymbol } from "../helpful";
import { getDifficulty, PARKS_DIFFICULTY } from "./get-difficulty";

// Features
const GLADES = Symbol("Glades");
const MOGULS = Symbol("Moguls");
const MOGULS_TOP = Symbol("Moguls Top");
const MOGULS_BOTTOM = Symbol("Moguls Bottom");
const NIGHT_SKIING = Symbol("NightSkiing");
const NORDIC = Symbol("Nordic");
const TERRAIN_PARK_ON_RUN = Symbol("TerrainParkOnRun");
const TOURING = Symbol("Touring");

export const TRAILS_FEATURES_LEGENDS = [
  GLADES,
  MOGULS,
  MOGULS_TOP,
  MOGULS_BOTTOM,
  NIGHT_SKIING,
  NORDIC,
  TERRAIN_PARK_ON_RUN,
  TOURING,
];

// Feature map
export const FEATURE_ICON_MAP = {
  [GLADES]: "glades",
  [MOGULS]: "moguls",
  [MOGULS_TOP]: "moguls-top",
  [MOGULS_BOTTOM]: "moguls-bottom",
  [NIGHT_SKIING]: "night-skiing",
  [NORDIC]: "nordic",
  [TERRAIN_PARK_ON_RUN]: "terrain-park-on-run",
  [TOURING]: "touring",
};

export const getFeatures = (trail) => {
  return TRAILS_FEATURES_LEGENDS.filter((symbol) => {
    switch (symbol) {
      case GLADES:
        return trail?.Glades === "Yes";
      case MOGULS:
        return trail?.Moguls === "Yes";
      case MOGULS_TOP:
        return trail?.Moguls === "Top" || trail?.Moguls === "Haut";
      case MOGULS_BOTTOM:
        return trail?.Moguls === "Bottom" || trail?.Moguls === "Bas";
      case NIGHT_SKIING:
        return trail?.NightSkiing === "Yes";
      case NORDIC:
        return trail?.Nordic === "Yes";
      case TERRAIN_PARK_ON_RUN:
        return (
          !PARKS_DIFFICULTY.includes(getDifficulty(trail)) &&
          trail?.TerrainParkOnRun === "Yes"
        );
      case TOURING:
        return trail?.Touring === "Yes";
      default:
        return false;
    }
  });
};

export const getResortFeatures = (trailFeatureMappings, trails) => {
  return (
    TRAILS_FEATURES_LEGENDS
      // Add all features used by any trail
      .filter((symbol) =>
        trails?.some((trail) => getFeatures(trail).includes(symbol))
      )
      // Format as a nicely mapped object
      .reduce((acc, symbol) => {
        const key = getTextFromSymbol(symbol);
        const icon = FEATURE_ICON_MAP[symbol];
        const label = trailFeatureMappings[key];
        return { ...acc, [key]: { icon, label } };
      }, {})
  );
};
