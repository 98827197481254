import AnimatedIcon from "./AnimatedIcon";
import { isAnimated } from "../../../lib/icons/isAnimated";
import StaticIcon from "./StaticIcon";
import styles from "../../../styles/components/Icon.module.scss";
import { useRef } from "react";

const Icon = (props) => {
  const { name, type, className, source } = props;
  const ref = useRef(null);
  const useAnimated = isAnimated({ name, type });

  return (
    <>
      {useAnimated && (
        <i className={styles.animatedIcon} ref={ref}>
          <AnimatedIcon name={name} type={type} parentRef={ref} />
        </i>
      )}
      {!useAnimated && (
        <i className={styles.animatedIcon}>
          <StaticIcon
            name={name}
            type={type}
            className={className}
            source={source}
          />
        </i>
      )}
    </>
  );
};

export default Icon;
