import { ReactComponent as MagnifyingGlass } from "../../../assets/icons/Icon_Magnifying_Glass.svg";
import styles from "../../../styles/components/storybook/atoms/SearchInput.module.scss";

const SearchInput = (props) => {
  const { value, onChange, placeholder = "Find" } = props;

  return (
    <div className={styles.component}>
      <MagnifyingGlass className={styles.magnifyingGlass} />
      <input
        className={styles.input}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default SearchInput;
