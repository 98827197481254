import { FeatureIcon, StatusIcon, TrailIcon } from "../atoms/LegendIcons";
import styles from "../../../styles/components/storybook/molecules/Legend.module.scss";

const LegendItem = ({ label, description }) => (
  <li className={styles.legendItem}>
    <div>
      <p className={styles.legendListItem}>{label}</p>
    </div>
    <div>{description}</div>
  </li>
);

const DifficultyItem = ({ icon, label }) => {
  const description = <TrailIcon icon={icon} />;

  return <LegendItem description={description} label={label} />;
};

const FeatureItem = ({ icon, label }) => {
  const description = <FeatureIcon icon={icon} />;

  return <LegendItem description={description} label={label} />;
};

const StatusItem = ({ icon, label }) => {
  const description = <StatusIcon icon={icon} />;
  return <LegendItem description={description} label={label} />;
};

const LegendType = ({ type, children }) => {
  return (
    <div className={styles.legendType}>
      <div>
        <h6 className={styles.legendTitle}>{type}:</h6>
      </div>
      <ul className={styles.legendList}>{children}</ul>
    </div>
  );
};

export const Legend = ({ legendGroups }) => {
  return (
    <div className={styles.legend} aria-hidden="true">
      {legendGroups.map(({ type, legends }, index) => (
        <LegendType type={type} key={`${type}-${index}`}>
          {Object.values(legends || [])
            .filter((item) => {
              //Remove difficulty/feature statuses from Legend
              //To Use: in resorts JSON config file, map the status to be removed in trailFeatureMappings or trailDifficultyMappings to null
              //ie.
              // "trailFeatureMappings": {
              //  "Touring": null
              //},
              return item.label !== null;
            })
            .map(({ label, icon }, index) => {
              switch (type) {
                case "Difficulty":
                case "Niveau de difficulté":
                  return (
                    <DifficultyItem
                      icon={icon}
                      key={`${label}-${index}`}
                      label={label}
                    />
                  );
                case "Features":
                case "Profil":
                case "Grooming":
                case "État":
                case "Status":
                  return (
                    <FeatureItem
                      icon={icon}
                      key={`${label}-${index}`}
                      label={label}
                    />
                  );
                case "Status":
                  return (
                    <StatusItem
                      icon={icon}
                      key={`${label}-${index}`}
                      label={label}
                    />
                  );
                default:
                  return "Status";
              }
            })}
        </LegendType>
      ))}
    </div>
  );
};
