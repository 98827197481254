export function getWholeNumber(numberString) {
  if (!numberString || isNaN(numberString)) {
    return "--";
  }

  return parseInt(numberString);
}

export const valideObjectEndpoint = (endpoint) =>
  typeof endpoint === "object" && !Array.isArray(endpoint) && endpoint !== null;

export const getTextFromSymbol = (symbol) =>
  /Symbol\((.*)\)/.exec(symbol.toString())[1];

export const getPercentage = (numerator, denominator) =>
  numerator > 0 && denominator > 0
    ? Math.round((numerator / denominator) * 100)
    : 0;
