import { useState, useEffect, useCallback } from "react";

const sanitizeHash = (hash) => {
  return hash.startsWith('#/') ? '#' + hash.slice(2) : hash;
};

const useHash = () => {
    const [hash, setHash] = useState(() => sanitizeHash(window.location.hash));
  
    const hashChangeHandler = useCallback(() => {
      const newHash = sanitizeHash(window.location.hash);
      setHash(newHash);
    }, []);
  
    useEffect(() => {
      window.addEventListener('hashchange', hashChangeHandler);
      return () => {
        window.removeEventListener('hashchange', hashChangeHandler);
      };
    }, []);
  
    const updateHash = useCallback(
      newHash => {
        const sanitizedHash = sanitizeHash(newHash);
        if (sanitizedHash !== hash) {
          window.location.hash = sanitizedHash;
        }
      },
      [hash]
    );
  
    return [hash, updateHash, setHash];
};

export { useHash, sanitizeHash };
