import { useContext, useEffect } from "react";
//CONTENT BLOCKS
import ResortJSONConfigContext from "../../lib/context/create-context/resortJSONConfigContext";
import { useContentBlocks } from "../../lib/content/content-blocks";
//GLOBAL STATE VARIABLES
import { MountainProvider } from "../../lib/context/mountain-provider";
import { ActivityMountainProvider } from "../../lib/context/activity-mountain-provider";
import { WeatherProvider } from "../../lib/context/use-context/weather-provider";
import { LanguageProvider } from "../../lib/context/language-provider";
//RESORT STYLES
import ResortStyles from "./ResortStyles";
import "../../styles/themeVariables/base.css";
import "../../styles/themeVariables/bigBearMountain.css";
import "../../styles/themeVariables/blueMountain.css";
import "../../styles/themeVariables/crystal.css";
import "../../styles/themeVariables/deerValley.css";
import "../../styles/themeVariables/juneMountain.css";
import "../../styles/themeVariables/mammoth.css";
import "../../styles/themeVariables/palisadesTahoe.css";
import "../../styles/themeVariables/schweitzer.css";
import "../../styles/themeVariables/snowshoe.css";
import "../../styles/themeVariables/solitude.css";
import "../../styles/themeVariables/steamboat.css";
import "../../styles/themeVariables/stratton.css";
import "../../styles/themeVariables/sugarbush.css";
import "../../styles/themeVariables/tamarack-xc.css";
import "../../styles/themeVariables/tremblant.css";
import "../../styles/themeVariables/winterPark.css";
// import { useLocation } from "react-router-dom";
import { sanitizeHash } from "../../lib/hooks/hash";

// STICKY NAV
const Layout = () => {
  //CREATE CONTENT BLOCKS
  const config = useContext(ResortJSONConfigContext);
  //RESORT LAYOUT
  const content = config?.contentBlocks;
  const contentBlocks = useContentBlocks({ content });
  //RESORT STYLES
  const theme = config?.themeName;
  const altFont = config?.altFont;
  const brandFont = config?.brandFont;
  const utilityFont = config?.utilityFont;
  const fonts = { altFont, brandFont, utilityFont };
  const brandFontWeights = config?.brandFontWeights;
  const utilityFontWeights = config?.utilityFontWeights;
  const webfontloaderConfig = config?.webfontloaderConfig;
  const hasStickyNav = config?.stickyNav;
  const addMarginTop = config?.addMarginTop;
  useEffect(() => {
    // Webfontloader import must occur in the browser only.
    // useEffect recommended to exclude server-side rendering.
    // https://nextjs.org/docs/migrating/from-create-react-app#safely-accessing-web-apis
    import("webfontloader").then((WebFont) =>
      WebFont.load(webfontloaderConfig)
    );
  }, [webfontloaderConfig]);

  useEffect(() => {
    const handleHashScroll = (hash) => {
      const sanitizedHash = sanitizeHash(hash);

      const headerHeight = document.querySelector("header")?.clientHeight || 0;
      const stickyNavHeight =
        document.querySelector(`.sticky-header`)?.clientHeight || 0;

      const anchoredComponent = document.querySelector(sanitizedHash);
      if (anchoredComponent) {
        const widgetRect = anchoredComponent
          .querySelector(".widget-title")
          ?.getBoundingClientRect();

        window.scrollTo({
          top:
            (anchoredComponent.getBoundingClientRect()?.top || 0) +
            window.scrollY +
            (widgetRect?.height || 0) -
            (headerHeight + stickyNavHeight) +
            16,
          behavior: "smooth",
        });
      }
    };

    const currentHash = sanitizeHash(window.location.hash);
    if (currentHash) {
      handleHashScroll(currentHash);
    }

    const hashChangeListener = (event) => {
      event.preventDefault();
      handleHashScroll(sanitizeHash(window.location.hash));
    };

    window.addEventListener("hashchange", hashChangeListener);
    return () => {
      window.removeEventListener("hashchange", hashChangeListener);
    };
  }, []);

  return (
    <MountainProvider>
      <ActivityMountainProvider>
        <WeatherProvider>
          <LanguageProvider>
            {/* TODO: replace with font family tokens when done */}
            <ResortStyles fonts={fonts} />
            <div
              id={`theme-${theme}`}
              className={`lifts-and-trails-builder ${
                addMarginTop ? "addMarginTop" : ""
              }`}
            >
              {contentBlocks}
            </div>
          </LanguageProvider>
        </WeatherProvider>
      </ActivityMountainProvider>
    </MountainProvider>
  );
};

export default Layout;
