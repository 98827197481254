//Conditionally addSort option
//If 1 or more trails in mtn powder feed has feature === "Yes", show sort option

export const ALPHABETICAL_ASC_SYMBOL = "Alphabetical (A-Z)";
export const ALPHABETICAL_DESC_SYMBOL = "Alphabetical (Z-A)";
export const EASIEST_DIFFICULT_SYMBOL = "Easiest-Difficult";
export const DIFFICULT_EASIEST_SYMBOL = "Difficult-Easiest";
export const GROOMING_ONLY_SYMBOL = "Grooming Only";
export const NIGHT_SESSION_ONLY_SYMBOL = "Night Session Only";
export const SNOW_MAKING_ONLY_SYMBOL = "Snow Making Only";
export const SNOW_MAKING_TONIGHT = "Snowmaking Tonight";
export const SNOW_MAKING_OVERNIGHT = "Snowmaking Overnight";
export const SNOW_MAKING_NIGHTIME = "Snowmaking Nighttime";
export const TOURING = "Touring";

const DEFAULT_SORT_OPTIONS = [
  ALPHABETICAL_ASC_SYMBOL,
  ALPHABETICAL_DESC_SYMBOL,
  EASIEST_DIFFICULT_SYMBOL,
  DIFFICULT_EASIEST_SYMBOL,
];

const mapSymbolToOptionsFunction = (sortOptionsMapping) => (key) => {
  return {
    label: sortOptionsMapping[key],
    value: key,
  };
};

export function buildSortOptions(trails, sortOptionsMapping) {
  if (!trails || !sortOptionsMapping) return null;

  const mapSymbolToOptions = mapSymbolToOptionsFunction(sortOptionsMapping);

  const options = DEFAULT_SORT_OPTIONS.map(mapSymbolToOptions);

  const additionalSortOptions = new Set();
  trails.forEach((trail) => {
    if (trail.Grooming === "Yes") {
      additionalSortOptions.add(GROOMING_ONLY_SYMBOL);
    }
    if (trail.NightSkiing === "Yes") {
      additionalSortOptions.add(NIGHT_SESSION_ONLY_SYMBOL);
    }
    if (trail.SnowMaking === "Yes") {
      additionalSortOptions.add(SNOW_MAKING_ONLY_SYMBOL);
    }
    if (trail.SnowMaking === "Snowmaking Tonight") {
      additionalSortOptions.add(SNOW_MAKING_ONLY_SYMBOL);
    }
    if (trail.SnowMaking === "Snowmaking Tonight") {
      additionalSortOptions.add(SNOW_MAKING_TONIGHT);
    }
    if (trail.SnowMaking === "Overnight") {
      additionalSortOptions.add(SNOW_MAKING_OVERNIGHT);
    }
    if (trail.SnowMaking === "Nighttime") {
      additionalSortOptions.add(SNOW_MAKING_NIGHTIME);
    }
  });

  return [...options, ...[...additionalSortOptions].map(mapSymbolToOptions)];
}
