import classnames from "classnames";
import styles from "../../../styles/components/storybook/widgets/StatsWidget.module.scss";

const StatBaseItem = (props) => {
  const { children, hiddenClass, className, statistic, label, withIcon } =
    props;

  const classNames = classnames(
    styles.statItem,
    withIcon && styles.withIcon,
    styles[className],
    styles[hiddenClass]
  );

  return (
    <li className={classNames}>
      <div className={styles.icon}>{children}</div>
      <div className={styles.statLabel}>
        <div className={styles.statistic}>{statistic}</div>
        <div className={styles.label}>
          <h6>{label}</h6>
        </div>
      </div>
    </li>
  );
};

export default StatBaseItem;
