import { useLocation, useSearchParams } from "react-router-dom";
//RESORT JSON CONFIG DATA
import ResortJSONConfigContext from "./create-context/resortJSONConfigContext";
import GetJSONConfigData from "../data/get-json-config-data";
// RESORT API FEED DATA
import ResortAPIFeedContext from "./create-context/resortAPIFeedContext";
import GetAPIFeedData from "../data/get-api-feed-data";
import Loading from "../../components/storybook/atoms/Loading";
import browserslist from "browserslist";

// CREATE GLOBAL VARIABLES: Resort API Feed Data, JSON Config Data
export function DataProvider({ children }) {
  const { pathname } = useLocation(); //GET RESORT FILE PATH FROM URL
  const [searchParams] = useSearchParams();

  let resortPath = pathname.startsWith("/") ? pathname.substring(1) : pathname;
  if (!resortPath) {
    resortPath = "/example";
  }

  let basePath = "";
  /* eslint-disable */
  if (typeof liftsAndTrailsConfig !== "undefined") {
    basePath = liftsAndTrailsConfig.liftsAndTrailsBuilderBasePath;
    resortPath = liftsAndTrailsConfig.resortPath;
  }
  /* eslint-enable */

  const jsonConfigData = GetJSONConfigData(basePath, resortPath); //GET RESORTS JSON CONFIG DATA
  const apiFeedData = GetAPIFeedData(
    jsonConfigData?.resortIds,
    jsonConfigData?.bearerToken,
    jsonConfigData?.primaryResort,
    jsonConfigData?.isFrench,
    searchParams.get("mtnPowderStaging") === "true"
  ); //GET RESORTS API FEED DATA

  if (!jsonConfigData || !apiFeedData) {
    return <Loading configStyles={jsonConfigData} />;
  }

  return (
    <ResortJSONConfigContext.Provider value={jsonConfigData}>
      <ResortAPIFeedContext.Provider value={apiFeedData}>
        {/* <OverrideContext.Provider value={overrideData}> */}
        {children}
        {/* </OverrideContext.Provider> */}
      </ResortAPIFeedContext.Provider>
    </ResortJSONConfigContext.Provider>
  );
}
