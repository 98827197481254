import styles from "../../../styles/components/storybook/widgets/WeatherWidget.module.scss";
import WeatherCard from "../molecules/WeatherCard";
import {
  UNIT_TEMPERATURE_C,
  UNIT_TEMPERATURE_F,
  UNIT_LENGTH_CM,
  UNIT_LENGTH_IN,
} from "../../../lib/components/weatherWidget";

const CurrentWeather = ({
  degreeUnit,
  setDegreeUnit,
  measurementUnit,
  setMeasurementUnit,
  weatherCard,
  snowfallCard,
  currentConditions,
  snowReport,
}) => {
  const {
    baseConditions,
    iconName,
    skies,
    windDirection,
    //CHANGE BASED ON DEGREE UNIT
    fahrenheit = {},
    celsius = {},
  } = currentConditions;

  const {
    //CHANGE BASED ON MEASUREMENT UNIT
    inches = {},
    centimeters = {},
  } = snowReport;

  const getCurrentConditionsObject = () => {
    switch (degreeUnit) {
      case UNIT_TEMPERATURE_F:
        return fahrenheit;
      case UNIT_TEMPERATURE_C:
      default:
        return celsius;
    }
  };

  const getSnowFallObject = () => {
    switch (measurementUnit) {
      case UNIT_LENGTH_IN:
        return inches;
      case UNIT_LENGTH_CM:
      default:
        return centimeters;
    }
  };

  const { temperature, temperatureHigh, temperatureLow, windChill, windSpeed } =
    getCurrentConditionsObject();

  const {
    seasonTotal,
    stormTotal,
    locationBase,
    last24Hour,
    last48Hour,
    last72Hour,
    last7Days,
    snowBaseRange,
  } = getSnowFallObject();

  return (
    <div className={styles.currentComponent}>
      <div className={styles.currentWrapper}>
        <WeatherCard
          iconName={iconName}
          iconType="weather"
          labeledListItems={weatherCard.items}
          baseConditions={baseConditions}
          temperatureHigh={temperatureHigh}
          temperatureLow={temperatureLow}
          windChill={windChill}
          windSpeed={windSpeed}
          windDirection={windDirection}
          unitLabelHeader={skies}
          unit={degreeUnit}
          setUnit={setDegreeUnit}
          label={temperature}
          topUnitLabel="°F"
          topUnitType={UNIT_TEMPERATURE_F}
          bottomUnitLabel="°C"
          bottomUnitType={UNIT_TEMPERATURE_C}
        />
      </div>
      {snowfallCard && (
        <div className={styles.snowfallWrapper}>
          <WeatherCard
            labeledListItems={snowfallCard.items}
            baseConditions={baseConditions}
            stormTotal={stormTotal}
            seasonTotal={seasonTotal}
            last24Hour={last24Hour}
            last48Hour={last48Hour}
            last72Hour={last72Hour}
            last7Days={last7Days}
            unitLabelHeader={snowfallCard.label}
            unit={measurementUnit}
            setUnit={setMeasurementUnit}
            label={
              snowfallCard.setPrimary24Hrs
                ? last24Hour
                : snowfallCard.useRangeReading
                ? snowBaseRange
                : locationBase
            }
            topUnitLabel="in"
            topUnitType={UNIT_LENGTH_IN}
            bottomUnitLabel="cm"
            bottomUnitType={UNIT_LENGTH_CM}
          />
        </div>
      )}
    </div>
  );
};

export default CurrentWeather;
