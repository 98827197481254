import Icon from "../../shared/Icon/Icon";
import LabelUnitToggle from "../atoms/LabelUnitToggle";
import classnames from "classnames";
import styles from "../../../styles/components/storybook/molecules/WeatherCard.module.scss";
import LabeledItemsList from "./LabeledItemsList";
import classNames from "classnames";

const WeatherCard = (props) => {
  const {
    centerItems,
    iconName,
    iconType,
    listHeader,
    labeledListItems,
    baseConditions,
    seasonTotal,
    last24Hour,
    last48Hour,
    last72Hour,
    last7Days,
    temperatureHigh,
    temperatureLow,
    windChill,
    windSpeed,
    windDirection,
    forecastDayName,
    forecastDayNumber,
    forecastDayMonth,
    forecastSkies,
    forecastTemperatureHigh,
    forecastTemperatureLow,
    forecastWindSpeed,
    forecastSnowDay,
    forecastSnowNight,
    unitLabelHeader,
    unit,
    setUnit,
    label,
    topUnitLabel,
    topUnitType,
    bottomUnitLabel,
    bottomUnitType,
  } = props;

  const className = classnames(
    styles.card,
    centerItems ? styles.centerItems : undefined
  );

  const iconClassName = classnames(
    styles.icon,
    iconName === "snow" ? styles.animatedIcon : undefined
  );

  return (
    <div className={className}>
      {forecastDayName && forecastDayNumber && forecastDayMonth && (
        <h4 className={styles.date}>
          <strong className={styles.dayName}>{forecastDayName}, </strong>
          <span className={styles.month}>{forecastDayMonth} </span>
          <span className={styles.dayNumber}>{forecastDayNumber}</span>
        </h4>
      )}
      <div
        className={classNames(styles.iconStatToggle, {
          [styles.hasIcon]: iconName && iconType,
        })}
      >
        {iconName && iconType && (
          <div className={iconClassName}>
            <Icon name={iconName} type={iconType} />
          </div>
        )}
        {unit && setUnit && (
          <LabelUnitToggle
            unit={unit}
            setUnit={setUnit}
            label={label}
            topUnitLabel={topUnitLabel}
            topUnitType={topUnitType}
            bottomUnitLabel={bottomUnitLabel}
            bottomUnitType={bottomUnitType}
          />
        )}
        {unitLabelHeader && (
          <div className={styles.unitLabelHeader}>
            <p>{unitLabelHeader}</p>
          </div>
        )}
      </div>
      <div className={styles.list}>
        {listHeader && <h5 className={styles.listHeader}>{listHeader}</h5>}
        {labeledListItems &&
          Array.isArray(labeledListItems) &&
          labeledListItems.length && (
            <ul className={styles.labeledList}>
              {labeledListItems.map((item, index) => {
                return (
                  <li key={`${item.type}-${index}`}>
                    <LabeledItemsList
                      item={item.type}
                      label={item.label}
                      secondLabel={item.secondLabel}
                      baseConditions={baseConditions}
                      seasonTotal={seasonTotal}
                      last24Hour={last24Hour}
                      last48Hour={last48Hour}
                      last72Hour={last72Hour}
                      last7Days={last7Days}
                      temperatureHigh={temperatureHigh}
                      temperatureLow={temperatureLow}
                      windChill={windChill}
                      windSpeed={windSpeed}
                      windDirection={windDirection}
                      forecastSnowDay={forecastSnowDay}
                      forecastSnowNight={forecastSnowNight}
                      forecastTemperatureHigh={forecastTemperatureHigh}
                      forecastTemperatureLow={forecastTemperatureLow}
                      forecastWind={forecastWindSpeed}
                      forecastSkies={forecastSkies}
                    />
                  </li>
                );
              })}
            </ul>
          )}
      </div>
    </div>
  );
};

export default WeatherCard;
