export const isAnimated = ({ name, type }) => {
  const animatedIcons = [
    {
      name: "snow",
      type: "weather",
    },
    {
      name: "snowblower-on",
      type: "stats-rollup",
    },
  ];

  return animatedIcons.some(
    ({ name: iconName, type: iconType }) =>
      name === iconName && type === iconType
  );
};
