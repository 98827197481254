import Layout from "./Layout";
//GLOBAL STATE VARIABLES
import { AppProvider } from "../../lib/context/app-provider";
import { DataProvider } from "../../lib/context/data-provider";

export default function Page() {
  return (
    <AppProvider>
      <DataProvider>
        <Layout />
      </DataProvider>
    </AppProvider>
  );
}
