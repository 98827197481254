import { useEffect, useState } from "react";
import lottie from "lottie-web";

const AnimatedIcon = (props) => {
  const { name, type, parentRef } = props;
  const [iconData, setIconData] = useState(null);
  const [animation, setAnimation] = useState(null);

  useEffect(() => {
    import(`../../../assets/icons/${type}/${name}.json`).then(
      ({ default: data }) => setIconData(data)
    );
  }, [name, type]);

  useEffect(() => {
    setAnimation(
      lottie.loadAnimation({
        animationData: iconData,
        autoplay: true,
        container: parentRef.current,
        loop: true,
        renderer: "svg",
      })
    );

    return () => {
      if (animation) {
        animation.destroy();
      }
    };
  }, [iconData, parentRef]); // eslint-disable-line react-hooks/exhaustive-deps

  return <></>; // Lottie will render an SVG as the child of the parent element
};

export default AnimatedIcon;
