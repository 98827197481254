import Road from "../molecules/Road";
import styles from "../../../styles/components//storybook/widgets/DirectionsWidget.module.scss";
import Widget from "../organisms/Widget";

const DirectionsWidget = ({
  id,
  title,
  dataEndpoints = [],
  link: { label: headerLinkLabel, url: headerLink },
}) => {
  if (!dataEndpoints || !dataEndpoints.length) return null;

  return (
    <Widget
      headerTitle={title}
      headerLink={headerLink}
      headerLinkLabel={headerLinkLabel}
      headerBackground={true}
      headerDivider={true}
      id={id}
    >
      <div className={styles.component}>
        <ul className={styles.roads}>
          {dataEndpoints.map((object, i) => {
            return <Road key={i} dataEndpoints={object} />;
          })}
        </ul>
      </div>
    </Widget>
  );
};

export default DirectionsWidget;
