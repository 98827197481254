// Road statuses
const STATUS_CLOSED = Symbol("closed");
const STATUS_EXPECTED = Symbol("expected");
const STATUS_NA = Symbol("na");
const STATUS_OPEN = Symbol("open");
const STATUS_OPEN_WITH_RESTRICTIONS = Symbol("open_with_restrictions");

// Stoplight icons
const ICON_STOPLIGHT_GREEN = Symbol("green_light");
const ICON_STOPLIGHT_NONE = Symbol("na_light");
const ICON_STOPLIGHT_RED = Symbol("red_light");
const ICON_STOPLIGHT_YELLOW = Symbol("yellow_light");

// Road status and icon info
const ROADS = {
  [STATUS_CLOSED]: {
    icon: ICON_STOPLIGHT_RED,
    label: "closed",
    status: "Closed",
  },
  [STATUS_EXPECTED]: {
    icon: ICON_STOPLIGHT_RED,
    label: "expected",
    status: "Expected",
  },
  [STATUS_NA]: {
    icon: ICON_STOPLIGHT_NONE,
    label: "na",
    status: "--",
  },
  [STATUS_OPEN]: {
    icon: ICON_STOPLIGHT_GREEN,
    label: "open",
    status: "Open",
  },
  [STATUS_OPEN_WITH_RESTRICTIONS]: {
    icon: ICON_STOPLIGHT_YELLOW,
    label: "open_with_restrictions",
    status: "Open with Restrictions",
  },
};

// Map icon symbol to filename
export const ICON_SVG_MAP = {
  [ICON_STOPLIGHT_GREEN]: "Icon_Light_Green",
  [ICON_STOPLIGHT_NONE]: "Icon_Light_None",
  [ICON_STOPLIGHT_RED]: "Icon_Light_Red",
  [ICON_STOPLIGHT_YELLOW]: "Icon_Light_Yellow",
};

// Retrieve the road status symbol.
const getRoadStatus = ({ Status }) =>
  Object.getOwnPropertySymbols(ROADS).find(
    (key) => ROADS[key]?.label === Status
  );
const getRoadStatusName = (roadData) => ROADS[getRoadStatus(roadData)]?.status;

// Retrieve the road status icon
const getRoadIcon = (roadData) => ROADS[getRoadStatus(roadData)]?.icon;
// Retrieve the SVG icon filename.
const getRoadIconName = (roadData) => ICON_SVG_MAP[getRoadIcon(roadData)];

const cleanEndpoint = (endpoint) => {
  if (!endpoint) return;
  return endpoint.toLowerCase().replace(/ /g, "_");
};

export const getRoadsWidgetEndpoints = (data) => {
  const cloneData = { ...data };

  let endpoints = Object.entries(cloneData);
  endpoints = endpoints.map(([key, value]) => {
    return [
      key,
      {
        ...value,
        chains: cleanEndpoint(value.ChainsRequired) === "yes",
        statusToDisplay: getRoadStatusName({
          Status: cleanEndpoint(value.Status),
        }),
        summary:
          value.Summary &&
          cleanEndpoint(value.Status) === "open_with_restrictions"
            ? true
            : false,
        iconType: "directions",
        defaultIconName: "Icon_Light_None",
        iconName:
          cleanEndpoint(value.ChainsRequired) === "yes" &&
          cleanEndpoint(value.Status) === "open"
            ? getRoadIconName({ Status: "open_with_restrictions" })
            : getRoadIconName({
                Status: cleanEndpoint(value.Status),
              }),
      },
    ];
  });

  endpoints = Object.fromEntries(endpoints);
  endpoints = Object.values(endpoints);

  return endpoints;
};
