import { getTextFromSymbol } from "../helpful";

export const OPEN = Symbol("Open");
export const CLOSED = Symbol("Closed");
export const DELAYED = Symbol("Delayed");
export const HOLD = Symbol("Hold");
export const OPEN_FIRST_TRACKS = Symbol("Open First Tracks");
export const OPEN_BUT_CLOSED_TO_FOOT_TRAFFIC = Symbol(
  "Open but Closed to Foot Traffic"
);
export const WEATHER_HOLD = Symbol("Weather Hold");
export const CLOSED_FOR_SEASON = Symbol("Closed For Season");
export const OPEN_UPHILL_ONLY = Symbol("Open Uphill Only");
export const PENDING_MITIGATION = Symbol("Pending Mitigation");
export const EXPECTED = Symbol("Expected");
export const OPEN_SKI_AND_RIDE_SCHOOL_ONLY = Symbol(
  "Open Ski & Ride School Only"
);
export const PATROL_HOLD = Symbol("Patrol Hold");
export const PATROL_CLOSURE = Symbol("Patrol Closure");
export const MECHANICAL_HOLD = Symbol("Mechanical Hold");
export const MECHANICAL_CLOSURE = Symbol("Mechanical Closure");
export const WIND_HOLD = Symbol("Wind Hold");
export const WIND_CLOSURE = Symbol("Wind Closure");
export const ANTICIPATED_WEATHER_IMPACT = Symbol("Anticipated Weather Impact");
export const DOWNLOAD_ONLY = Symbol("Download Only");
export const LIGHTNING_HOLD = Symbol("Lightning Hold");
export const LIGHTNING_CLOSURE = Symbol("Lightning Closure");
export const THIRTY_MINS_OR_LESS = Symbol("30 Mins or Less");
export const MID_STATION_ONLY = Symbol("Mid-Station only");
export const WINDY_CONDITIONS = Symbol("Windy Conditions");
export const OPEN_TO_MID_STATION_ONLY = Symbol("Open to Mid-Station Only");
export const OPEN_CLOSES_TOMORROW = Symbol("Open Closes Tomorrow");
export const CLOSED_OPENS_TOMORROW = Symbol("Closed Opens Tomorrow");
export const OPEN_TO_FOOT_TRAFFIC_ONLY = Symbol("Open to Foot Traffic Only");
export const SCHEDULED_TO_MID_STATION_ONLY = Symbol(
  "Scheduled to Mid-Station Only"
);
export const NO_OFFLOAD_AT_KT22 = Symbol("No Offload at KT-22");
export const OFFLOAD_AT_KT22_ADVANCED_ONLY = Symbol(
  "Offload at KT-22 Advanced Only"
);
export const RETURN_TO_ALPINE_BASE_NOW = Symbol("Return to Alpine Base Now");
export const RETURN_TO_PALISADES_BASE_NOW = Symbol(
  "Return to Palisades Base Now"
);

export const LIFT_STATUS_API_MAPPING = {
  0: OPEN,
  1: CLOSED,
  2: DELAYED,
  3: HOLD,
  5: OPEN_FIRST_TRACKS,
  6: OPEN_BUT_CLOSED_TO_FOOT_TRAFFIC,
  8: WEATHER_HOLD,
  9: CLOSED_FOR_SEASON,
  10: OPEN_UPHILL_ONLY,
  11: PENDING_MITIGATION,
  12: EXPECTED,
  13: OPEN_SKI_AND_RIDE_SCHOOL_ONLY,
  14: PATROL_HOLD,
  15: PATROL_HOLD,
  16: MECHANICAL_HOLD,
  17: MECHANICAL_CLOSURE,
  18: WIND_HOLD,
  19: WIND_CLOSURE,
  20: ANTICIPATED_WEATHER_IMPACT,
  22: DOWNLOAD_ONLY,
  23: LIGHTNING_HOLD,
  24: LIGHTNING_CLOSURE,
  25: THIRTY_MINS_OR_LESS,
  26: MID_STATION_ONLY,
  27: WINDY_CONDITIONS,
  28: OPEN_TO_MID_STATION_ONLY,
  30: OPEN_CLOSES_TOMORROW,
  31: CLOSED_OPENS_TOMORROW,
  32: OPEN_TO_MID_STATION_ONLY,
  34: SCHEDULED_TO_MID_STATION_ONLY,
  35: NO_OFFLOAD_AT_KT22,
  36: OFFLOAD_AT_KT22_ADVANCED_ONLY,
  37: RETURN_TO_ALPINE_BASE_NOW,
  38: RETURN_TO_PALISADES_BASE_NOW,
};

export const LIFT_STATUS_LEGENDS = [OPEN, CLOSED];

// export const ICON_SVG_AND_CLASSNAME_MAP = {
//   [OPEN]: "open",
//   [CLOSED]: "closed",
//   [DELAYED]: "closed",
//   [HOLD]: "open",
//   [OPEN_FIRST_TRACKS]: "open",
//   [OPEN_BUT_CLOSED_TO_FOOT_TRAFFIC]: "open",
//   [WEATHER_HOLD]: "open",
//   [CLOSED_FOR_SEASON]: "closed",
//   [OPEN_UPHILL_ONLY]: "open",
//   [PENDING_MITIGATION]: "closed",
//   [EXPECTED]: "closed",
//   [OPEN_SKI_AND_RIDE_SCHOOL_ONLY]: "open",
//   [PATROL_HOLD]: "open",
//   [PATROL_CLOSURE]: "closed",
//   [MECHANICAL_HOLD]: "open",
//   [MECHANICAL_CLOSURE]: "closed",
//   [WIND_HOLD]: "open",
//   [WIND_CLOSURE]: "closed",
//   [ANTICIPATED_WEATHER_IMPACT]: "closed",
//   [DOWNLOAD_ONLY]: "open",
//   [LIGHTNING_HOLD]: "open",
//   [LIGHTNING_CLOSURE]: "closed",
//   [THIRTY_MINS_OR_LESS]: "closed",
//   [MID_STATION_ONLY]: "open",
//   [WINDY_CONDITIONS]: "open",
//   [OPEN_TO_MID_STATION_ONLY]: "open",
//   [OPEN_CLOSES_TOMORROW]: "open",
//   [CLOSED_OPENS_TOMORROW]: "closed",
//   [OPEN_TO_FOOT_TRAFFIC_ONLY]: "open",
//   [SCHEDULED_TO_MID_STATION_ONLY]: "closed",
//   [NO_OFFLOAD_AT_KT22]: "Open",
//   [OFFLOAD_AT_KT22_ADVANCED_ONLY]: "Open",
//   [RETURN_TO_ALPINE_BASE_NOW]: "Open",
//   [RETURN_TO_PALISADES_BASE_NOW]: "Open",
// };

export const getLiftStatus = ({ StatusId }) => {
  return LIFT_STATUS_API_MAPPING[StatusId] || CLOSED;
};

// export const getResortLiftsStatus = (liftStatusConfigOverrides, lifts) => {
//   const liftsStatus = lifts.map((lift) => lift.status);
//   // console.log(liftStatusConfigOverrides);
//   const override = liftStatusConfigOverrides || {};
//   return Object.getOwnPropertySymbols(ICON_SVG_AND_CLASSNAME_MAP)
//     .filter(
//       (symbol) =>
//         LIFT_STATUS_LEGENDS.includes(symbol) ||
//         (override[getTextFromSymbol(symbol)]?.icon &&
//           liftsStatus.includes(symbol))
//     )
//     .reduce((acc, symbol) => {
//       const key = getTextFromSymbol(symbol);
//       return {
//         ...acc,
//         [key]: {
//           icon: override[key]?.icon || ICON_SVG_AND_CLASSNAME_MAP[symbol],
//           label: override[key]?.label || key,
//         },
//       };
//     }, {});
// };

// Returns an object with the Symbol as the key and shortname as value
export const getLiftStatusOverrides = (liftStatusConfigOverrides) => {
  return Object.entries(liftStatusConfigOverrides || {})
    .filter(([_, value]) => value?.shortName)
    .reduce(
      (acc, [key, { shortName }]) => ({
        ...acc,
        [key]: shortName,
      }),
      {}
    );
};
