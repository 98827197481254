import { useEffect, useRef, useState } from "react";
import Tab from "../atoms/Tab";
import Divider from "../atoms/Divider";
import classnames from "classnames";
import styles from "../../../styles/components/storybook/molecules/Tabs.module.scss";

const Tabs = (props) => {
  const {
    options,
    currentTab,
    setCurrentTab,
    className,
    tabsGroupClassName = null,
    hideFadeDesktop = false,
  } = props;
  const [scrolled, setScrolled] = useState(false);
  const listRef = useRef(null);

  const classNames = classnames(
    styles.component,
    scrolled ? styles.scrolled : null
  );

  const handleScroll = (e) => {
    const offset = e.target
      .querySelector("li:first-of-type")
      .getBoundingClientRect();
    offset.x < 10 ? setScrolled(true) : setScrolled(false);
  };

  const [hideLeftFade, setHideLeftFade] = useState(true);

  useEffect(() => {
    const list = listRef.current;

    const scrollListener = () => {
      setHideLeftFade(list.scrollLeft < 15);
    };

    list.addEventListener("scroll", scrollListener);

    return () => {
      list.removeEventListener("scroll", scrollListener);
    };
  }, [listRef.current, setHideLeftFade]);

  return (
    <div className={classNames}>
      {!hideFadeDesktop && !hideLeftFade && (
        <div
          onClick={() => {
            const list = listRef.current;
            list.scroll({
              left: list.scrollLeft - 150,
              behavior: "smooth",
            });
            setHideLeftFade(list.scrollLeft < 150);
          }}
          className={classnames(styles.fade)}
        />
      )}
      <ul
        ref={listRef}
        className={classnames(styles.tabsGroup, tabsGroupClassName)}
        onScroll={handleScroll}
      >
        {options.map((option, index) => (
          <Tab
            key={index}
            label={option.label}
            value={option.value}
            className={className}
            isActive={currentTab === option.value}
            onClick={() => setCurrentTab(option.value)}
          />
        ))}
      </ul>
      {!hideFadeDesktop && (
        <div
          onClick={() => {
            const list = listRef.current;
            list.scroll({
              left: list.scrollLeft + 150,
              behavior: "smooth",
            });
            setHideLeftFade(false);
          }}
          className={classnames(styles.fade, styles.right)}
        />
      )}
      <Divider />
    </div>
  );
};

export default Tabs;
