import {
  ALPHABETICAL_ASC_SYMBOL,
  ALPHABETICAL_DESC_SYMBOL,
  DIFFICULT_EASIEST_SYMBOL,
  EASIEST_DIFFICULT_SYMBOL,
  GROOMING_ONLY_SYMBOL,
  NIGHT_SESSION_ONLY_SYMBOL,
  SNOW_MAKING_NIGHTIME,
  SNOW_MAKING_ONLY_SYMBOL,
  SNOW_MAKING_OVERNIGHT,
  SNOW_MAKING_TONIGHT,
  TOURING,
} from "./build-sort-options";

import { TRAILS_DIFFICULTIES_LEGENDS } from "./get-difficulty";

const removeDiacritics = (string) =>
  string.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

export const filterAndSortTrailsBuilder =
  (parsedFilterText, trailSort, showClosed, showUphillOnly) => (trails) => {
    return (
      trails
        .filter((trail) =>
          trail.name
            .replace(/[\W_]+/g, " ")
            .toLowerCase()
            .includes(parsedFilterText)
        ) // Filter By showClosed
        .filter(({ status }) =>
        showClosed ? true : !["Fermé", "Closed", "Fermé pour la saison", "Closed for Season"].includes(status)
        )
        // Filter By showUphillOnly
        .filter(({ Touring }) => !showUphillOnly || Touring === "Yes")
        // Filter by dropdown
        .filter(({ Grooming, Touring, NightSkiing, SnowMaking }) => {
          switch (trailSort) {
            case SNOW_MAKING_ONLY_SYMBOL:
              return SnowMaking === "Yes";
            case NIGHT_SESSION_ONLY_SYMBOL:
              return NightSkiing === "Yes";
            case GROOMING_ONLY_SYMBOL:
              return Grooming === "Yes";
            case SNOW_MAKING_TONIGHT:
              return SnowMaking === "Snowmaking Tonight";
            case SNOW_MAKING_NIGHTIME:
              return SnowMaking === "Nighttime";
            case SNOW_MAKING_OVERNIGHT:
              return SnowMaking === "Overnight";
            case TOURING:
              return Touring === "Yes";
            default:
              return true;
          }
        })
        // sort by dropdown
        .sort((a, b) => {
          switch (trailSort) {
            case EASIEST_DIFFICULT_SYMBOL:
              return TRAILS_DIFFICULTIES_LEGENDS.indexOf(a.difficulty) <
                TRAILS_DIFFICULTIES_LEGENDS.indexOf(b.difficulty)
                ? -1
                : 1;
            case DIFFICULT_EASIEST_SYMBOL:
              return TRAILS_DIFFICULTIES_LEGENDS.indexOf(a.difficulty) >
                TRAILS_DIFFICULTIES_LEGENDS.indexOf(b.difficulty)
                ? -1
                : 1;
            case ALPHABETICAL_DESC_SYMBOL:
              return removeDiacritics(a.name) > removeDiacritics(b.name)
                ? -1
                : 1;
            case ALPHABETICAL_ASC_SYMBOL:
            default:
              return removeDiacritics(a.name) < removeDiacritics(b.name)
                ? -1
                : 1;
          }
        })
    );
  };
