import { useState, useEffect } from "react";
import Widget from "../organisms/Widget";
import ContentWithHeader from "../molecules/ContentWithHeader";
import ExpandContent from "../atoms/ExpandContent";
import LinkList from "../molecules/LinkList";
import Divider from "../atoms/Divider";
import styles from "../../../styles/components/storybook/widgets/DailyMessageWidget.module.scss";
import { useContext } from "react";
import CurrentWeatherAreaContext from "../../../lib/context/create-context/currentWeatherAreaContext";
import { render } from "react-dom";

const DailyMessageWidget = (props) => {
  const { id, content, data, dailyMessageTitle, usefulLinksTitle } = props;

  const [areaLocation] = useContext(CurrentWeatherAreaContext);

  const [expandContentOnLoad, setExpandContentOnLoad] = useState();

  const { SnowReport, LayoutOptions } = data;

  const snowReportArea = SnowReport[areaLocation];

  const dailyMessageContent =
    content?.dailyMessage?.endPoint === "News"
      ? snowReportArea.News
      : snowReportArea.Report;

  const linkList =
    content?.usefulLinks?.links === "dataFeedUsefulLinks"
      ? LayoutOptions?.AdditionalResources
      : content?.usefulLinks?.links;

  const linkType = content?.usefulLinks?.linkType;

  const renderDailyMessage =
    dailyMessageContent &&
    dailyMessageContent !== "--" &&
    dailyMessageContent !== "\n\n" &&
    dailyMessageContent !== "\r\n\r\n";

  const renderLinkList = linkList && Array.isArray(linkList) && linkList.length;

  useEffect(() => {
    const dailyMessageContent = document.getElementById(
      "daily-message-content"
    );
    const dailyMessageLinkedList = document.getElementById(
      "daily-message-linked-list"
    );

    if (
      renderDailyMessage &&
      renderLinkList &&
      dailyMessageContent.offsetHeight > dailyMessageLinkedList.offsetHeight
    ) {
      setExpandContentOnLoad(false);
    } else {
      setExpandContentOnLoad(true);
    }
  }, [renderDailyMessage]);

  if (!renderDailyMessage && !renderLinkList) return null;

  return (
    <Widget id={id}>
      <div className={styles.component}>
        {renderDailyMessage && (
          <div>
            <ContentWithHeader
              id="daily-message-content"
              title={dailyMessageTitle}
            >
              <ExpandContent
                content={dailyMessageContent}
                maxCharLength={355}
                autoExpandOnHash={"#daily_message"}
                expandContentOnLoad={expandContentOnLoad}
              />
            </ContentWithHeader>
          </div>
        )}
        {renderDailyMessage && renderLinkList ? (
          <Divider hideDesktop={true} marginTop={true} />
        ) : null}
        {renderLinkList ? (
          <div>
            <LinkList
              id="daily-message-linked-list"
              title={usefulLinksTitle}
              links={linkList}
              linkType={linkType}
            />
          </div>
        ) : null}
      </div>
    </Widget>
  );
};

export default DailyMessageWidget;
