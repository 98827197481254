import { Legend } from "../molecules/Legend";
import styles from "../../../styles/components/storybook/widgets/TrailWidget.module.scss";

const LegendDrawer = (props) => {
  const { legendGroups, showLegend } = props;

  return (
    <div className={styles.drawer}>
      {showLegend && <Legend legendGroups={legendGroups} />}
    </div>
  );
};

export default LegendDrawer;
