// Difficulty values
//

import { getTextFromSymbol } from "../helpful";

// Each symbol represents a difficulty value composed from the TrailIcon and Difficulty of the trail.
const EASY = Symbol("Easy");
const EASIER = Symbol("Easier");
const EASIEST = Symbol("Easiest");
const INTERMEDIATE = Symbol("Intermediate");
const ADVANCED_INTERMEDIATE = Symbol("Advanced Intermediate");
const ADVANCED_INTERMEDIATE_ALT = Symbol("Advanced Intermediate");
const DIFFICULT = Symbol("Difficult");
const MODERATELY_DIFFICULT = Symbol("Moderately Difficult");
const EXPERT = Symbol("Expert");
const EXTREME_TERRAIN = Symbol("Extreme Terrain");
const PARK = Symbol("Park");
const PRO = Symbol("Pro");
const SMALL_PARK = Symbol("Small Park");
const MEDIUM_PARK = Symbol("Medium Park");
const LARGE_PARK = Symbol("Large Park");
const GLADES = Symbol("Glades");
const SNOWSHOE = Symbol("Snowshoe");
const FUN_ZONE = Symbol("Fun Zone");
const ADVENTURE_ZONE = Symbol("Adventure Zone");
const UPHILL_ACCESS = Symbol("Uphill Access");
const BLACK_FREERIDE = Symbol("Black Freeride");
const BLACK_TECHNICAL = Symbol("Black Technical");
const BLUE_FREERIDE = Symbol("Blue Freeride");
const BLUE_TECHNICAL = Symbol("Blue Technical");
const DOUBLE_BLACK_FREERIDE = Symbol("DoubleBlack-Freeride");
const DOUBLE_BLACK_TECHNICAL = Symbol("DoubleBlack-Technical");
const GREEN_FREERIDE = Symbol("Green Freeride");
const GREEN_TECHNICAL = Symbol("Green Technical");
const HALFPIPE = Symbol("Halfpipe");
//
// Difficulty info
//
// Map difficulty symbol to label key and icon.
/* eslint-disable */

export const PARKS_DIFFICULTY = [PARK, SMALL_PARK, MEDIUM_PARK, LARGE_PARK];

export const TRAILS_DIFFICULTIES_LEGENDS = [
  EASY,
  EASIER,
  EASIEST,
  GREEN_FREERIDE,
  GREEN_TECHNICAL,
  INTERMEDIATE,
  MODERATELY_DIFFICULT,
  BLUE_FREERIDE,
  BLUE_TECHNICAL,
  ADVANCED_INTERMEDIATE,
  ADVANCED_INTERMEDIATE_ALT,
  DIFFICULT,
  EXPERT,
  EXTREME_TERRAIN,
  PARK,
  PRO,
  SMALL_PARK,
  MEDIUM_PARK,
  LARGE_PARK,
  GLADES,
  SNOWSHOE,
  FUN_ZONE,
  ADVENTURE_ZONE,
  UPHILL_ACCESS,
  BLACK_FREERIDE,
  BLACK_TECHNICAL,
  DOUBLE_BLACK_FREERIDE,
  DOUBLE_BLACK_TECHNICAL,
  HALFPIPE,
];

// Map icon symbol to filename
export const DIFFICULTY_ICON_MAPPING = {
  [EASY]: "green-circle",
  [EASIER]: "green-black-circle",
  [GREEN_FREERIDE]: "green-freeride",
  [GREEN_TECHNICAL]: "green-technical",
  [EASIEST]: "green-circle",
  [INTERMEDIATE]: "blue-square",
  [ADVANCED_INTERMEDIATE]: "blue-black-square",
  [ADVANCED_INTERMEDIATE_ALT]: "blue-blue-square",
  [DIFFICULT]: "black-diamond",
  [PRO]: "black-triangle",
  [MODERATELY_DIFFICULT]: "blue-square",
  [EXPERT]: "double-black-diamond",
  [EXTREME_TERRAIN]: "double-black-diamond",
  [PARK]: "park",
  [SMALL_PARK]: "park",
  [MEDIUM_PARK]: "park",
  [LARGE_PARK]: "park",
  [GLADES]: "glades",
  [SNOWSHOE]: "snowshoe",
  [FUN_ZONE]: "purple-star",
  [ADVENTURE_ZONE]: "purple-star",
  [UPHILL_ACCESS]: "uphill-arrow",
  [BLACK_FREERIDE]: "black-freeride",
  [BLACK_TECHNICAL]: "black-technical",
  [BLUE_FREERIDE]: "blue-freeride",
  [BLUE_TECHNICAL]: "blue-technical",
  [DOUBLE_BLACK_FREERIDE]: "double-black-freeride",
  [DOUBLE_BLACK_TECHNICAL]: "double-black-technical",
  [HALFPIPE]: "halfpipe",
};

// Get difficulty for trail from its Difficulty and TrailIcon values.
//
// Order matters: priority first and fall through (like a switch/case, but can't easily switch on multi-vars)
export const getDifficulty = ({ Difficulty = null, TrailIcon = null }) => {
  if (TrailIcon === "GreenTechnical") return GREEN_TECHNICAL;
  if (TrailIcon === "GreenFreeride") return GREEN_FREERIDE;
  if (TrailIcon === "DoubleblackTechnical") return DOUBLE_BLACK_TECHNICAL;
  if (TrailIcon === "DoubleblackFreeride") return DOUBLE_BLACK_FREERIDE;
  if (TrailIcon === "BlueTechnical") return BLUE_TECHNICAL;
  if (TrailIcon === "BlueFreeride") return BLUE_FREERIDE;
  if (TrailIcon === "BlackTechnical") return BLACK_TECHNICAL;
  if (TrailIcon === "BlackFreeride") return BLACK_FREERIDE;
  if (TrailIcon === "UphillArrow") return UPHILL_ACCESS;
  if (Difficulty === "Uphill Access") return UPHILL_ACCESS;
  if (TrailIcon === "FunZone") return FUN_ZONE;
  if (Difficulty === "Fun zone") return FUN_ZONE;
  if (Difficulty === "Adventure Zone") return ADVENTURE_ZONE;
  if (TrailIcon === "PurpleStar") return ADVENTURE_ZONE;
  if (Difficulty === "Snowshoe") return SNOWSHOE;
  if (TrailIcon === "Snowshoe") return SNOWSHOE;
  if (Difficulty === "Glades") return GLADES;
  if (TrailIcon === "Glades") return GLADES;
  if (Difficulty === "LargePark") return LARGE_PARK;
  if (Difficulty === "MediumPark") return MEDIUM_PARK;
  if (Difficulty === "SmallPark") return SMALL_PARK;
  if (TrailIcon === "Park") return PARK;
  if (TrailIcon === "DoubleBlackDiamond") return EXPERT;
  if (Difficulty === "Extreme Terrain") return EXTREME_TERRAIN;
  if (TrailIcon === "ExtremeTerrain") return EXTREME_TERRAIN;
  if (TrailIcon === "BlackDiamond") return DIFFICULT;
  if (TrailIcon === "BlackTriangle") return PRO;
  if (TrailIcon === "BlueBlackSquare") return ADVANCED_INTERMEDIATE;
  if (TrailIcon === "BlueBlueSquare") return ADVANCED_INTERMEDIATE_ALT;
  if (Difficulty === "Advanced Intermediate") return ADVANCED_INTERMEDIATE;
  if (Difficulty === "Moderately Difficult") return MODERATELY_DIFFICULT;
  if (Difficulty === "Intermediate") return INTERMEDIATE;
  if (TrailIcon === "BlueSquare") return INTERMEDIATE;
  if (TrailIcon === "GreenCircle") return EASY;
  if (Difficulty === "Easy") return EASY;
  if (Difficulty === "Easier") return EASIER;
  if (Difficulty === "Easiest") return EASIEST;
  if (TrailIcon === "Halfpipe") return HALFPIPE;
  if (Difficulty === "Halfpipe") return HALFPIPE;

  return null;
};

export const getResortDifficulties = (trails) => {
  return (
    TRAILS_DIFFICULTIES_LEGENDS
      // Add all difficulties used by any trail
      .filter((key) => trails?.some((trail) => trail.difficulty === key))
      // Format as a nicely mapped object
      .reduce((acc, symbol) => {
        const key = getTextFromSymbol(symbol);
        const icon = DIFFICULTY_ICON_MAPPING[symbol];
        const label =
          trails.find((trail) => trail.difficulty === symbol)?.label || key;
        return { ...acc, [key]: { icon, label } };
      }, {})
  );
};
