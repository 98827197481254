import classnames from "classnames";
import { useState } from "react";
import Icon from "../../shared/Icon/Icon";
import styles from "../../../styles/components/storybook/atoms/Accordion.module.scss";

const Accordion = (props) => {
  const { children, title, open, total } = props;

  const [showContent, setShowContent] = useState(true);

  const iconClassNames = classnames(
    styles.icon,
    showContent ? styles.rotate : styles.noRotate
  );

  const contentClassNames = classnames(
    styles.accordionContent,
    showContent ? styles.showContent : styles.hideContent
  );

  return (
    <div className={styles.accordion}>
      <div
        className={styles.accordionBar}
        onClick={() => setShowContent(!showContent)}
      >
        <div className={styles.accordionTitle}>
          <Icon
            name="location-pin"
            type="general"
            className={styles.locationPinIcon}
          />
          <h6 className={styles.accordionName}>{title}</h6>
          {open !== undefined || total !== undefined ? (
            <>
              <span className={styles.accordionStat}>
                <strong>
                  {open}/{total}
                </strong>
              </span>
              <span className={styles.accordionStat}>Open</span>
            </>
          ) : null}
        </div>
        <div className={styles.iconWrapper}>
          <div className={iconClassNames} />
          <div className={styles.icon}></div>
        </div>
      </div>
      <div className={contentClassNames}>{children}</div>
      <div className={styles.accordionDivider}></div>
    </div>
  );
};

export default Accordion;
