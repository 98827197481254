import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import Link from "./Link";

const ExpandContent = (props) => {
  const {
    content,
    maxCharLength,
    autoExpandOnHash,
    expandContentOnLoad = true,
  } = props;

  const [infoIsExpanded, setInfoExpanded] = useState(expandContentOnLoad);
  // const infoExpansionToggle = () => {
  //   setInfoIsExpanded(!infoIsExpanded);
  // };

  //TODO:Remove when Mammoth removes report widget
  const { hash } = useLocation();

  //If page rendered with location.hash === #daily-message
  //Page will scroll down to #daily-message and expandInfo
  useEffect(() => {
    if (hash === autoExpandOnHash) {
      setInfoExpanded(true);
    } else {
      setInfoExpanded(expandContentOnLoad);
    }
  }, [setInfoExpanded, expandContentOnLoad, hash]);

  if (!content || content === "--" || content === "\r\n\r\n") {
    return null;
  }

  if (expandContentOnLoad) {
    return <RenderContent content={content} />;
  } else if (infoIsExpanded) {
    return (
      <RenderContent content={content} setInfoExpanded={setInfoExpanded} />
    );
  } else {
    if (content.trim().length < maxCharLength) {
      return <RenderContent content={content} />;
    } else {
      return (
        <RenderExpandableContent
          content={content}
          maxCharLength={maxCharLength}
          setInfoExpanded={setInfoExpanded}
        />
      );
    }
  }
};

export default ExpandContent;

const RenderContent = (props) => {
  const { content, setInfoExpanded } = props;
  return (
    <>
      <p dangerouslySetInnerHTML={{ __html: content }}></p>{" "}
      {setInfoExpanded && (
        <Link
          label="Read Less"
          onClick={() => setInfoExpanded(false)}
          type="expandContentLink"
        />
      )}
    </>
  );
};

const RenderExpandableContent = (props) => {
  const { content, maxCharLength, setInfoExpanded } = props;

  return (
    <>
      <span
        dangerouslySetInnerHTML={{
          __html: content.substring(0, maxCharLength),
        }}
      ></span>
      <span>... </span>
      <Link
        label="Read More"
        onClick={() => setInfoExpanded(true)}
        type="expandContentLink"
      />
    </>
  );
};
