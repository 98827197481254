import LabeledItem from "../atoms/LabeledItem";

const LabeledItemsList = (props) => {
  const {
    item,
    label,
    secondLabel,
    forecastSnowDay,
    forecastSnowNight,
    forecastTemperatureHigh,
    forecastTemperatureLow,
    forecastWind,
    forecastSkies,
    baseConditions,
    seasonTotal,
    last24Hour,
    last48Hour,
    last72Hour,
    last7Days,
    temperatureHigh,
    temperatureLow,
    windChill,
    windSpeed,
    windDirection,
  } = props;

  switch (item) {
    //CURRENT CONDITIONS
    case "tempHighLow": {
      return (
        <LabeledItem
          label={label}
          endpoint={temperatureHigh}
          secondLabel={secondLabel}
          secondEndpoint={temperatureLow}
        />
      );
    }
    case "wind": {
      return (
        <LabeledItem label={label} endpoint={`${windSpeed} ${windDirection}`} />
      );
    }
    case "windChill": {
      return <LabeledItem label={label} endpoint={windChill} />;
    }
    case "baseConditions": {
      return <LabeledItem label={label} endpoint={baseConditions} />;
    }
    //SNOWFALL
    case "seasonTotal": {
      return <LabeledItem label={label} endpoint={seasonTotal} />;
    }
    case "last24Hour": {
      return <LabeledItem label={label} endpoint={last24Hour} />;
    }
    case "last48Hour": {
      return <LabeledItem label={label} endpoint={last48Hour} />;
    }
    case "last72Hour": {
      return <LabeledItem label={label} endpoint={last72Hour} />;
    }
    case "last7Days": {
      return <LabeledItem label={label} endpoint={last7Days} />;
    }
    //FORECAST
    case "forecastTempHighLow": {
      return (
        <LabeledItem
          centerText={true}
          label={label}
          endpoint={forecastTemperatureHigh}
          secondLabel={secondLabel}
          secondEndpoint={forecastTemperatureLow}
        />
      );
    }
    case "forecastSnow": {
      return (
        <LabeledItem
          centerText={true}
          label={label}
          endpoint={forecastSnowDay}
          secondLabel={secondLabel}
          secondEndpoint={forecastSnowNight}
        />
      );
    }
    case "forecastWind": {
      return (
        <LabeledItem label={label} endpoint={forecastWind} centerText={true} />
      );
    }
    case "forecastSkies": {
      return (
        <LabeledItem
          label={label}
          endpoint={forecastSkies}
          centerText={true}
          marginTop={false}
        />
      );
    }

    default:
      return null;
  }
};

export default LabeledItemsList;
