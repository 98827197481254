import classNames from "classnames";
import { useLayoutEffect, useRef, useState } from "react";
import { useWindowWidth } from "../../../lib/hooks/windowDimensions";
import styles from "../../../styles/components/storybook/widgets/StickyHeaderWidget.module.scss";
import NavBar from "../molecules/NavBar";

const StickyHeaderWidget = ({
  isSticky,
  inverseButtonTextOnHover,
  possibleAnchors,
}) => {
  const titleRef = useRef();
  const headerRef = useRef();
  const headerContainerRef = useRef();

  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < 768;
  const [anchors, setAnchors] = useState(possibleAnchors);

  const getSiteWrapperOffset = () => {
    const siteWrapper = document.querySelector(".site-wrapper");
    return siteWrapper
      ? parseInt(
          (siteWrapper.currentStyle || window.getComputedStyle(siteWrapper))
            .marginTop,
          10
        )
      : 0;
  };

  const onAnchorActive = (activeAnchor) => {
    if (titleRef && !isMobile) {
      const title = titleRef.current;
      if (activeAnchor) {
        title.innerHTML = `<h2>${activeAnchor.title}</h2>`;
      } else {
        title.innerHTML = "";
      }
    }
  };

  useLayoutEffect(() => {
    if (possibleAnchors && possibleAnchors.length > 0) {
      setAnchors(
        possibleAnchors.filter(({ link }) => document.querySelector(link))
      );
    }
  }, [possibleAnchors, setAnchors]);

  const resetHeaderPosition = () => {
    const header = headerRef.current;
    const headerContainer = headerContainerRef.current;

    // let headerContainer = document.querySelector(".sticky-header"); let widgetTitle = document.querySelector(`#weather-and-conditions .widget-title`)?.getBoundingClientRect(); let mainHeaderHeight = document.querySelector("header")?.clientHeight || 0;

    if (!isMobile && anchors && anchors.length > 0) {
      const widgetTitle = document
        .querySelector(`${anchors[0].link} .widget-title`)
        ?.getBoundingClientRect();

      const mainHeaderHeight =
        document.querySelector("header")?.clientHeight || 0;

      const firstAnchorTop =
        (widgetTitle?.top || 0) +
        window.scrollY -
        Math.abs(
          ((widgetTitle?.height || 0) - (headerContainer?.clientHeight || 0)) /
            2
        ) -
        getSiteWrapperOffset();

      if (headerContainer.classList.contains(styles.sticky)) {
        headerContainer.style.top = `${mainHeaderHeight}px`;
      } else {
        headerContainer.style.top = `${firstAnchorTop}px`;
      }
      header.style.height = `0px`;
    } else {
      header.style.height = `${headerContainer.clientHeight}px`;
      headerContainer.style.top = `0px`;
    }
  };

  useLayoutEffect(() => {
    const title = titleRef.current;
    anchors.map(({ link }) => {
      const widget = document.querySelector(`${link} .widget-title`);
      if (widget) {
        if (title) {
          widget.style.maxWidth = `calc(${title.clientWidth || 0}px - 0.75rem)`;
        } else {
          widget.style.maxWidth = `initial`;
        }
      }
    });
    resetHeaderPosition();
  }, [titleRef, windowWidth]);

  useLayoutEffect(() => {
    const header = headerRef.current;
    const headerContainer = headerContainerRef.current;
    const mainHeaderHeight =
      document.querySelector("header")?.clientHeight || 0;

    const setSticky = (headerPositioning) => {
      const scrollY = window.scrollY;
      const headerContainerClassList = headerContainer.classList;

      if (
        headerPositioning < scrollY &&
        !headerContainerClassList.contains(styles.sticky)
      ) {
        headerContainerClassList.add(styles.sticky);
        headerContainer.style.top = `${mainHeaderHeight}px`;
      } else if (
        headerPositioning >= scrollY &&
        headerContainerClassList.contains(styles.sticky)
      ) {
        headerContainerClassList.remove(styles.sticky);
        resetHeaderPosition();
      }
    };

    if (header && headerContainer) {
      const siteWrapper = document.querySelector(".site-wrapper");
      resetHeaderPosition();

      const headerPositioning =
        parseInt(headerContainer.style.top, 10) +
        window.scrollY -
        mainHeaderHeight +
        getSiteWrapperOffset();

      const onScroll = () => {
        setSticky(headerPositioning);
      };
      if (isSticky) {
        window.addEventListener("scroll", onScroll);
      }
      return () => {
        window.removeEventListener("scroll", onScroll);
      };
    }
  }, [anchors, isSticky, isMobile, headerRef, headerContainerRef]);

  const ScrollWrapper = ({ children }) =>
    isMobile ? (
      <div className={styles.scrollContainer}>{children}</div>
    ) : (
      children
    );

  const LargeStickyWrapper = ({ children }) => {
    return windowWidth > 1200 ? (
      <div className={styles.largeStickyContainer}>{children}</div>
    ) : (
      children
    );
  };

  return (
    <section ref={headerRef} className={styles.component}>
      <div
        ref={headerContainerRef}
        className={classNames(styles.container, "sticky-header", {
          [styles.desktopContainer]: !isMobile,
        })}
      >
        <LargeStickyWrapper>
          {!isMobile && (
            <div ref={titleRef} className={styles.headerTitle}></div>
          )}
          <ScrollWrapper>
            <NavBar
              anchors={anchors}
              onAnchorActive={onAnchorActive}
              className={styles.navBar}
              inverseButtonTextOnHover={inverseButtonTextOnHover}
            />
            <div className={classNames(styles.fade, styles.right)} />
          </ScrollWrapper>
        </LargeStickyWrapper>
      </div>
    </section>
  );
};

export default StickyHeaderWidget;
