import classnames from "classnames";
import defaultStyles from "../../../styles/components/storybook/molecules/ContentWithHeader.module.scss";

const ContentWithHeader = (props) => {
  const { id, title, styles, className, children, marginTop } = props;
  if (!children || children?.props?.content === "--") return null;

  let componentClassName = defaultStyles.component;

  if (styles && className) {
    componentClassName = classnames(defaultStyles.component, styles[className]);
  }

  const contentClassName = classnames(
    defaultStyles.content,
    marginTop ? defaultStyles.marginTop : ""
  );

  return (
    <div id={id} className={componentClassName}>
      {title && <h5 className={defaultStyles.title}>{title}</h5>}
      <div className={contentClassName}>{children}</div>
    </div>
  );
};

export default ContentWithHeader;
