export const filterAndSortLiftsBuilder =
  (parsedFilterText, showClosed) => (lifts) => {
    return (
      lifts
        .filter((lift) =>
          lift.name
            .replace(/[\W_]+/g, " ")
            .toLowerCase()
            .includes(parsedFilterText)
        )
        // Filter By showClosed
        .filter(({ statusIcon }) =>
          showClosed ? true : statusIcon !== "closed"
        )
    );
  };
