export const filterAndSortActivitiesBuilder =
  (parsedFilterText, showClosed) => (activities) => {
    return (
      activities
        .filter((activity) =>
          activity.name
            .replace(/[\W_]+/g, " ")
            .toLowerCase()
            .includes(parsedFilterText)
        )
        // Filter By showClosed
        .filter(({ statusIcon }) =>
          showClosed ? true : statusIcon !== "closed"
        )
    );
  };
