//WIDGETS
import Promo from "../../components/storybook/widgets/PromoWidget";
import DailyMessageWidget from "../../components/storybook/widgets/DailyMessageWidget";
import DirectionsWidget from "../../components/storybook/widgets/DirectionsWidget";
import LiftWidget from "../../components/storybook/widgets/LiftWidget";
import NewsletterSignupWidget from "../../components/storybook/widgets/NewsletterSignupWidget";
import TabsMountainAreaWidget from "../../components/storybook/widgets/TabsMountainAreaWidget";
import TrailWidget from "../../components/storybook/widgets/TrailWidget";
import WeatherWidget from "../../components/storybook/widgets/WeatherWidget";
import MapsWidget from "../../components/storybook/widgets/MapsWidget";
//MTN POWDER API FEED DATA
import ResortAPIFeedContext from "../../lib/context/create-context/resortAPIFeedContext";
import { useContext } from "react";
//WIDGET DATA ENDPOINTS
import { getRoadsWidgetEndpoints } from "../components/directionsWidget";
import TitleWidget from "../../components/storybook/widgets/TitleWidget";
import StickyHeader from "../../components/storybook/widgets/StickyHeaderWidget";
import ActivityWidget from "../../components/storybook/widgets/ActivityWidget";

export const useContentBlocks = ({ content, errorCode }) => {
  const data = useContext(ResortAPIFeedContext);

  if (!data) {
    return null;
  }

  if (errorCode) {
    // return <Error statusCode={errorCode} />;
  }

  const isUsingDataFeed = (value) => {
    return value === "dataFeed";
  };

  const blocks = content?.map((block, i) => {
    switch (block?._type) {
      case "activities":
        if (!data?.MountainAreas) return null;
        let activitiesLink = {
          url: block?.link?.url,
          label: block?.link?.label,
        };

        if (isUsingDataFeed(block?.link)) {
          const moreActivitiesInfo =
            data?.LayoutOptions?.MoreInfoLinks?.MoreActivitiesInfo;
          if (moreActivitiesInfo) {
            activitiesLink = {
              url: moreActivitiesInfo[0]?.Url,
              label: moreActivitiesInfo[0]?.Title,
            };
          }
        }

        return (
          <ActivityWidget
            id={block._id}
            title={block?.title}
            link={activitiesLink}
            data={data}
            showClosedToggleDefaultValue={
              block?.options?.showClosedToggleDefaultValue ?? null
            }
            options={block?.options}
          />
        );
      case "activitiesTabs":
        if (!data?.MountainAreas) return null;
        const allActivitiesTabsLabel = block?.allTabsLabel
          ? block?.allTabsLabel
          : "All Activities";
        const defaultActivitiesTabs = [
          { label: allActivitiesTabsLabel, value: null },
        ];
        const categorizedActivitiesSwitch =
          block?.categorizedActivitiesSwitchOn;

        function createActivitiesTabs(data) {
          //Get each mountain area
          let mountainAreaData = data?.map((mountainArea) => mountainArea);

          //Find all mountain areas that have CategorizedActivities obj
          let getCategorizedActivities = mountainAreaData?.filter(
            (area) => Object.keys(area.CategorizedActivities).length > 0
          );

          //Filter out any mountain area that have an empty CategorizedActivities obj
          let isCategorizedActivities = false;
          if (getCategorizedActivities.length > 0) {
            //build array of categorized activities
            let buildArrCategorizedActivities = Object.values(
              getCategorizedActivities
            ).map((items) => items?.CategorizedActivities);
            //filter out any empty objects
            buildArrCategorizedActivities =
              buildArrCategorizedActivities.filter(
                (obj) => Object.keys(obj).length !== 0
              );
            //Check if there are any categorized activities (not empty obj)
            isCategorizedActivities = buildArrCategorizedActivities.every(
              (obj) => Object.keys(obj).length > 0
            );
          }

          //Find all mountain areas that have Activities arr
          let getActivities = mountainAreaData.filter(
            (area) => area.Activities
          );

          //Filter out any mountain area that have an empty Activities arr
          getActivities = getActivities.filter(
            (area) => area.Activities.length > 0
          );

          //Check if CategorizedActivities exists in feed
          const hasCategorizedActivities = getCategorizedActivities.length > 0;

          //Default tabs to show all mountain areas with activities
          let filteredData = getActivities;

          //If CategorizedActivities exists and resort has feature switched on, show those tabs instead
          const feedHasCategorizedActivities =
            hasCategorizedActivities &&
            isCategorizedActivities &&
            categorizedActivitiesSwitch;
          if (feedHasCategorizedActivities) {
            //Check if mountain area has CategorizedActivities obj and if its !empty
            getCategorizedActivities = mountainAreaData?.filter((area) => {
              if (area.CategorizedActivities) {
                return Object.keys(area.CategorizedActivities).length > 0;
              }
            });
            filteredData = getCategorizedActivities;
          }

          filteredData?.map((mountainArea) => {
            defaultActivitiesTabs.push({
              label: mountainArea.Name,
              value: mountainArea.Name,
            });
          });
          return defaultActivitiesTabs;
        }

        const buildActivitiesTabs = createActivitiesTabs(data?.MountainAreas);

        return (
          <TabsMountainAreaWidget
            id={block._id}
            key={`block-${i}`}
            tabs={buildActivitiesTabs}
            title={block?.title}
            type='activity'
          />
        );
      case "promo":
        return (
          <Promo data={data} content={block} key={`block-${i}`} id={`promo`} />
        );
      case "dailyMessage":
        return (
          <DailyMessageWidget
            content={block}
            dailyMessageTitle={block?.dailyMessage?.title}
            data={data}
            usefulLinksTitle={block?.usefulLinks?.title}
            key={`block-${i}`}
            id={`daily_message`}
          />
        );
      case "directions":
        if (!data?.Roads) return null;

        let directionLink = {
          label: block?.link?.label,
          url: block?.link?.url,
        };
        if (isUsingDataFeed(block?.link)) {
          const moreRoadsInfo =
            data?.LayoutOptions?.MoreInfoLinks?.MoreRoadsInfo;
          if (moreRoadsInfo) {
            directionLink = {
              url: moreRoadsInfo[0]?.Url,
              label: moreRoadsInfo[0]?.Title,
            };
          }
        }

        return (
          <DirectionsWidget
            dataEndpoints={getRoadsWidgetEndpoints(data?.Roads)}
            title={block?.title || "Directions"}
            link={directionLink}
            key={`block-${i}`}
            id='directions'
          />
        );
      case "lifts":
        if (!data?.MountainAreas) return null;
        return (
          <LiftWidget
            id='lifts'
            key={`block-${i}`}
            title={block?.title}
            data={data}
            liftsStatusMappings={block?.liftsStatusMappings}
            options={{
              showClosedToggleDefaultValue:
                block?.options?.showClosedToggleDefaultValue ?? null,
              showTimestamp: block?.options?.showTimestamp,
            }}
          />
        );
      case "header":
        const possibleAnchors = block.anchors.reduce((array, item) => {
          return [...array, item._type];
        }, []);
        return (
          <StickyHeader
            key={`block-${i}`}
            inverseButtonTextOnHover={block?.inverseButtonTextOnHover}
            isSticky={block?.isSticky}
            possibleAnchors={content
              .filter(
                ({ _type, _id }) => _id && possibleAnchors.includes(_type)
              )
              .map(({ title, _id }) => ({ title, link: `#${_id}` }))}
          />
        );
      case "newsletter":
        return (
          <NewsletterSignupWidget
            title={block?.title}
            form={block?.form}
            id={`newsletter`}
            key={`block-${i}`}
          />
        );
      case "mainTitle":
        return (
          <TitleWidget
            key={`block-${i}`}
            id={block?._id}
            title={block?.title}
          />
        );
      case "maps":
        let maplink = {
          label: block?.link?.label,
          url: block?.link?.url,
        };
        if (isUsingDataFeed(block?.link)) {
          const moreMapsInfo = data?.LayoutOptions?.MoreInfoLinks?.MoreMapsInfo;
          if (moreMapsInfo) {
            maplink = {
              url: moreMapsInfo[0]?.Url,
              label: moreMapsInfo[0]?.Title,
            };
          }
        }

        let mapList = block?.maps;
        if (isUsingDataFeed(block?.maps)) {
          mapList = data?.LayoutOptions?.Maps;
        }

        return (
          <MapsWidget
            key={`block-${i}`}
            id='maps'
            type='map'
            extraMarginTop={
              block?.extraMarginTop ? block?.extraMarginTop : false
            }
            headerTitle={block?.title}
            mapList={mapList || []}
            link={maplink}
          />
        );
      case "trailsWidget":
        if (!data?.MountainAreas) return null;
        return (
          <TrailWidget
            id={`trail-${i}`}
            key={`block-${i}`}
            title={block?.title}
            data={data}
            options={{
              showSort: block?.options?.showSort ?? true,
              showLegendOnLoad: block?.options?.showLegendOnLoad ?? true,
              showLegendToggleDefaultValue:
                block?.options?.showLegendToggleDefaultValue ?? false,
              showClosedToggleDefaultValue:
                block?.options?.showClosedToggleDefaultValue ?? true,
              showUphillToggleDefaultValue:
                block?.options?.showUphillToggleDefaultValue ?? true,
              showUphillToggle: block?.options?.showUphillToggle ?? false,
              groomingLabel: block?.options?.groomingLabel,
            }}
            configFilters={{
              filterTrailFeature: block?.options?.filterTrailFeature ?? null,
              filterTrailIcon: block?.options?.filterTrailIcon ?? null,
              removeTrailIcon: block?.options?.removeTrailIcon ?? null,
            }}
            trailFeatureMappings={block?.trailFeatureMappings}
            sortOptionsLabelMappings={block?.sortOptionsLabelMappings}
          />
        );
      case "liftTrailsTabs":
        if (!data?.MountainAreas) return null;
        const allLiftsTrailsTabsLabel = block?.allTabsLabel
          ? block?.allTabsLabel
          : "All Lifts/Trails";

        function createLiftsTrailsTabs(data) {
          const tabsData = new Set();
          data.forEach((area) => {
            if (
              Object.keys(area.CategorizedTrails).length > 0 ||
              Object.keys(area.CategorizedLifts).length > 0 ||
              Object.keys(area.Trails).length > 0 ||
              Object.keys(area.Lifts).length > 0
            ) {
              tabsData.add(area.Name);
            }
          });

          const calculatedTabs = [...tabsData].map((name) => ({
            label: name,
            value: name,
          }));

          return [
            { label: allLiftsTrailsTabsLabel, value: null },
            ...calculatedTabs,
          ];
        }

        const buildLiftsTrailsTabs = createLiftsTrailsTabs(data?.MountainAreas);
        return (
          <TabsMountainAreaWidget
            id={block._id}
            key={`block-${i}`}
            tabs={buildLiftsTrailsTabs}
            title={block?.title}
            type='liftsTrails'
          />
        );
      case "weather":
        if (!data?.CurrentConditions || !data?.SnowReport) return null;

        return (
          <WeatherWidget
            key={`block-${i}`}
            id={block._id}
            title={block?.title}
            content={block}
            data={data}
          />
        );
      default:
        return <p>Content block {block?._type} not defined in code</p>;
    }
  });

  return blocks;
};
