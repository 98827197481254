import { useEffect, useState } from "react";
import { merge } from "lodash";

const GetJSONConfigData = (basePath, resortPath) => {
  // Store default and current resort JSON config data
  const [fetchingDefaultJSONData, setFetchingDefaultJSONData] = useState(false);
  const [fetchingResortJSONData, setFetchingResortJSONData] = useState(false);
  const [defaultJSONData, setDefaultJSONData] = useState(null);
  const [resortJSONData, setResortJSONData] = useState(null);

  //Fetch default JSON config for all resorts
  useEffect(() => {
    const fetchDefaultData = async () => {
      if (!fetchingDefaultJSONData && !defaultJSONData) {
        setFetchingDefaultJSONData(true);
        const defaultJSONPath = basePath
          ? new URL(`./resorts/default.json`, basePath).href
          : `./resorts/default.json`;
        await fetch(defaultJSONPath)
          .then((response) => response.json())
          .then((json) => {
            setDefaultJSONData(json);
          });
      }
    };
    fetchDefaultData().catch(console.error);
  });

  //Fetch current resorts JSON config
  useEffect(() => {
    const fetchResortData = async () => {
      if (!fetchingResortJSONData && !resortJSONData) {
        setFetchingResortJSONData(true);
        const resortJSONPath = basePath
          ? new URL(`./resorts/${resortPath}.json`, basePath).href
          : `./resorts/${resortPath}.json`;
        await fetch(resortJSONPath)
          .then((response) => response.json())
          .then((json) => {
            setResortJSONData(json);
          });
      }
    };
    fetchResortData().catch(console.error);
  });

  if (!defaultJSONData || !resortJSONData) {
    return null;
  }

  const configData = merge(defaultJSONData, resortJSONData);
  return configData;
};

export default GetJSONConfigData;
