import { useContext } from "react";
import LanguageContext from "../../../lib/context/create-context/languageContext";
import { getLiftDurations } from "../../../lib/components/liftWidget/liftsWidget";
import classnames from "classnames";
import Icon from "../../shared/Icon/Icon";
import styles from "../../../styles/components/storybook/molecules/Lift.module.scss";
// import { ICON_SVG_AND_CLASSNAME_MAP } from "../../../lib/components/liftWidget/get-status";

const Lift = (props) => {
  const {
    hours,
    rideTime,
    iconPath,
    iconType = "lift-types",
    name,
    status,
    statusIcon,
    updateTimestamp,
    showTimestamp,
  } = props;

  const openStatus = status ? status.toLowerCase() === "open" : false;
  const statusClassName = classnames(
    styles.status,
    openStatus ? styles.open : styles.closed
  );

  const {isFrench} = useContext(LanguageContext);

  if (!name) return null;

  const hasHours = !!(hours && hours.Open && hours.Close);

  return (
    <li className={styles.lift}>
      <div className={styles.pseudoBorderElement}></div>
      <div className={styles.inner}>
        <div className={styles.iconWrapper}>
          <Icon name={iconPath} type={iconType} className={styles.iconLift} />
          <Icon
            name={statusIcon}
            type="lift-status"
            className={styles.iconStatus}
          />
        </div>
        <div className={styles.detailsWrapper}>
          <p className={styles.name}>{name}</p>
          {hasHours || !!rideTime ? (
            <p className={styles.timing}>
              {hasHours && (
                <span>
                  {hours.Open}
                  &mdash;
                  {hours.Close}
                </span>
              )}
              {hasHours && !!rideTime && <>&nbsp;&#x2022;&nbsp;</>}
              {!!rideTime && (
                <>
                  <strong>{rideTime} min ride</strong>
                </>
              )}
            </p>
          ) : null}
        </div>
        <div>
          <p className={statusClassName}>{status}</p>
          {showTimestamp && (
            <p className={styles.updated}>{isFrench ? "Mis à jour" : "Updated"} {updateTimestamp}</p>
          )}
        </div>
      </div>
    </li>
  );
};

export default Lift;
