import { useContext, useState } from "react";
import classnames from "classnames";
import { getLiftStatusOverrides } from "../../../lib/components/liftWidget/get-status";
import {
  getLiftsDataEndpoints,
  getLiftWidgetHeaderDataEndpoints,
} from "../../../lib/components/liftWidget/liftsWidget";
import Widget from "../organisms/Widget";

import styles from "../../../styles/components/storybook/widgets/LiftWidget.module.scss";
import CurrentMountainAreaContext from "../../../lib/context/create-context/currentMountainAreaContext";

import LanguageContext from "../../../lib/context/create-context/languageContext";
import LiftAccordion from "../organisms/lifts/LiftsAccordion";

const LiftWidget = (props) => {
  const {
    id,
    title,
    data,
    options: { showClosedToggleDefaultValue, showTimestamp },
    liftsStatusMappings,
  } = props;

  const filteredMountainArea = useContext(
    CurrentMountainAreaContext
  )?.currentMountainArea;

  const headerStats = getLiftWidgetHeaderDataEndpoints(data);

  const [filteredText, setFilteredText] = useState("");
  const [showClosed, setShowClosed] = useState(true);

  const classNames = classnames(styles.component, styles.lift);

  const mountainAreas = getLiftsDataEndpoints(data?.MountainAreas);

  const statusOverrides = getLiftStatusOverrides(liftsStatusMappings);

  const { isFrench } = useContext(LanguageContext);

  return (
    <Widget
      id={id}
      headerTitle={title}
      padding={false}
      open={headerStats.open}
      total={headerStats.total}
      placeholder={isFrench ? "Rechercher une remontée" : "Search Lifts"}
      filteredText={filteredText}
      setFilteredText={setFilteredText}
      showClosed={showClosed}
      showClosedLabel={isFrench ? "Voir les Remontées fermées" : "Show Closed"}
      setShowClosed={setShowClosed}
      showClosedToggleDefaultValue={showClosedToggleDefaultValue}
    >
      <div className={classNames}>
        <LiftAccordion
          mountainAreas={mountainAreas}
          filteredMountainArea={filteredMountainArea}
          filteredText={filteredText}
          showClosed={showClosed}
          statusOverrides={statusOverrides}
          showTimestamp={showTimestamp}
        />
      </div>
    </Widget>
  );
};

export default LiftWidget;
