import classNames from "classnames";
import { ReactSVG } from "react-svg";
import { baseUrl } from "../../../lib/env";

const StaticIcon = (props) => {
  const { name, type, className, source } = props;

  const SvgIcon = () => {
    const svgPath = `${baseUrl}assets/icons/${type}/${name}.svg`;
    return (
      <ReactSVG
        src={svgPath}
        wrapper='svg'
        className={className}
        loading={() => <span>Loading...</span>}
        afterInjection={(error) => {
          if (error) {
            return;
          }
        }}
        fallback={() => <span>--</span>} //Use if injection error occurs
      />
    );
  };

  const FontAwesomeIcon = () => {
    return <i className={classNames("fa-light", `fa-${name}`, className)}></i>;
  };

  switch (source) {
    case "fontAwesome":
      return <FontAwesomeIcon />;
    case "svg":
    default:
      return <SvgIcon />;
  }
};

export default StaticIcon;
