import classnames from "classnames";
import Icon from "../../shared/Icon/Icon";
import Indicator from "../../storybook/atoms/Indicator";
import styles from "../../../styles/components/storybook/molecules/Activity.module.scss";
import classNames from "classnames";

const Activity = (props) => {
  const { hours, name, status, statusIcon, icon } = props;
  const statusClassNameKey = status
    ? status.toLowerCase().replaceAll(" ", "-")
    : null;
  const statusClassName = classnames(styles.status, styles[statusClassNameKey]);

  if (!name) return null;

  return (
    <li className={styles.activity}>
      <div className={styles.pseudoBorderElement}></div>
      <div className={styles.inner}>
        <div className={styles.iconWrapper}>
          <Icon
            name={icon}
            type="activity-types"
            className={classNames(styles.iconActivity, {
              [styles.fa]: icon !== "default",
            })}
            source={icon === "default" ? "svg" : "fontAwesome"}
          />
          {statusIcon && (
            <Indicator status={statusIcon} className={styles.iconStatus} />
          )}
        </div>
        <p className={styles.name}>{name}</p>
        <p className={statusClassName}>{status}</p>
        <p className={styles.timing}>
          {hours && hours.Open && hours.Close && (
            <span>
              {hours.Open}
              &mdash;
              {hours.Close}
            </span>
          )}
        </p>
      </div>
    </li>
  );
};

export default Activity;
