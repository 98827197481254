import { format as formatDate } from "date-fns";
import { getLiftStatus, OPEN } from "./get-status";

export const getLiftDataEndpoint = (lift) => {
  return {
    key: lift.Name,
    name: lift.Name,
    iconPath: lift.LiftIcon && lift.LiftIcon !== "--" ? lift.LiftIcon.replace(/ /g, "_") : "na",
    statusIcon: lift.StatusIcon,
    status: lift.Status,
    hours: getHoursToday(lift.Hours),
    rideTime: getRideTime(lift.RideTime),
    updateTimestamp: getLiftUpdated(lift.UpdateDate).replace(/^0(?:0:0?)?/, ""),
  };
};

export const getLiftsDataEndpoints = (mountainAreas) => {
  if (!mountainAreas) return null;

  const getCategorizedLiftsEndpoints = (categorizedLifts) => {
    return Object.entries(categorizedLifts)
      .map(([category, lifts]) => ({
        category,
        lifts: lifts.map((lift) => getLiftDataEndpoint(lift)),
      }))
      .sort((a, b) => {
        if (a.category === "Uncategorized") {
          return -1;
        } else {
          return 1;
        }
      });
  };

  const getDefaultLiftEndpoints = (lifts) => {
    return lifts.map((lift) => getLiftDataEndpoint(lift));
  };

  let endpoints = Object.entries(mountainAreas);
  endpoints = endpoints.map(([key, value]) => {
    return [
      key,
      {
        title: value.Name,
        total: value.Lifts.length,
        open: value.Lifts.filter((lift) => getLiftStatus(lift) === OPEN).length,
        lifts: getDefaultLiftEndpoints(value.Lifts),
        categorizedLifts: getCategorizedLiftsEndpoints(value.CategorizedLifts),
      },
    ];
  });

  endpoints = Object.fromEntries(endpoints);
  endpoints = Object.values(endpoints);

  return endpoints;
};

export const getLiftWidgetHeaderDataEndpoints = (data) => {
  if (!data) return null;
  let openLifts = data?.AggregatedStats.TotalOpenLifts.toString();
  openLifts = openLifts === "--" ? null : openLifts;

  let allLifts = data?.AggregatedStats.TotalLifts.toString();
  allLifts = allLifts === "--" ? null : allLifts;

  const endpoints = {
    open: openLifts,
    total: allLifts,
  };
  return endpoints;
};

// --------------------------- DATE/TIME --------------------------- //
export function getLiftDurations(durationsArr, Name) {
  let duration;
  if (durationsArr) {
    const configLifts = durationsArr.filter((item) => item.lift === Name);
    const configDuration = configLifts.map((item) => item.duration);
    duration = parseInt(configDuration);
  }

  return duration;
}

export function getLiftUpdated(UpdateDate) {
  return new Date(UpdateDate).toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
}

export function getHoursToday(Hours) {
  // Example Hours object:
  // {
  //   "Sunday": {
  //       "Open": "9:00am",
  //       "Close": "4:30pm"
  //   },
  //   "Monday": {
  //       "Open": "9:00am",
  //       "Close": "4:30pm"
  //   },
  //   "Tuesday": {
  //       "Open": "9:00am",
  //       "Close": "4:30pm"
  //   },
  //   "Wednesday": {
  //       "Open": "9:00am",
  //       "Close": "4:30pm"
  //   },
  //   "Thursday": {
  //       "Open": "9:00am",
  //       "Close": "4:30pm"
  //   },
  //   "Friday": {
  //       "Open": "9:00am",
  //       "Close": "4:30pm"
  //   },
  //   "Saturday": {
  //       "Open": "9:00am",
  //       "Close": "4:30pm"
  //   }
  // }

  if (typeof Hours !== "object" || !Hours) {
    return false;
  }

  const todayDayOfWeek = formatDate(new Date(), "EEEE");

  if (!Object.prototype.hasOwnProperty.call(Hours, todayDayOfWeek)) {
    return false;
  }

  if (!Hours[todayDayOfWeek].Open || !Hours[todayDayOfWeek].Close) {
    return false;
  }

  return Hours[todayDayOfWeek];
}

export const getRideTime = (rideTime) => {
  const regexpSize = /(\d{0,2})(:|\s*min\s*)?(\d{0,2})?/;
  const match = (rideTime || "").match(regexpSize);
  if (match) {
    return match[3] && match[3] !== "00" ? `${match[1]}:${match[3]}` : match[1];
  }
  return null;
};
