import { useContext, useEffect, useState } from "react";
import { filterAndSortActivitiesBuilder } from "../../../../lib/components/activityWidget/filterAndSortActivities";
import useWindowDimensions from "../../../../lib/hooks/windowDimensions";

import Accordion from "../../atoms/Accordion";

import CategorizedActivities from "./CategorizedActivities";
import UncategorizedActivities from "./UncategorizedActivities";

import styles from "../../../../styles/components/storybook/organisms/ActivitiesAccordion.module.scss";
import LanguageContext from "../../../../lib/context/create-context/languageContext";

const ActivitiesAccordion = ({
  mountainAreas,
  filteredMountainArea,
  filteredText,
  showClosed,
  showSpecificActivityOnly,
  showSpecificActivityToggleID,
}) => {
  const { isFrench } = useContext(LanguageContext);

  const [activityGroups, setActivityGroups] = useState([]);

  useEffect(() => {
    const parsedFilterText = filteredText.replace(/[\W_]+/g, " ").toLowerCase();

    const filterAndSortActivities = filterAndSortActivitiesBuilder(
      parsedFilterText,
      showClosed
    );

    const filteredActivityGroups = mountainAreas
      .filter(({ title }) =>
        filteredMountainArea ? title === filteredMountainArea : true
      )
      .map((activityGroup) => {
        const categorizedActivities = (
          activityGroup.categorizedActivities || []
        )
          .map((categoryGroup) => {
            return {
              ...categoryGroup,
              activities: filterAndSortActivities(categoryGroup.activities),
            };
          })
          // Filter out categories that has empty lifts
          .filter(({ activities }) => activities.length > 0);

        return {
          ...activityGroup,
          activities: filterAndSortActivities(activityGroup.activities),
          categorizedActivities,
        };
      })
      .filter(
        (activityGroup) =>
          activityGroup.activities.length > 0 ||
          activityGroup.categorizedActivities.length > 0
      );

    setActivityGroups(filteredActivityGroups);
  }, [
    mountainAreas,
    setActivityGroups,
    filteredMountainArea,
    filteredText,
    showClosed,
  ]);

  //Get window width, needed for vertical sorting
  const { width } = useWindowDimensions();
  let columns = 1; //Mobile
  if (width >= 768) {
    //Small Breakpoint
    columns = 2;
  }
  if (width >= 1200) {
    //XL Breakpoint
    columns = 3;
  }

  return activityGroups.length > 0 ? (
    activityGroups.map((activityGroup, index) => {
      if (
        showSpecificActivityOnly &&
        activityGroup.title !== showSpecificActivityToggleID
      ) {
        return null;
      }
      return (
        <Accordion
          title={activityGroup.title}
          key={`${activityGroup.title}-${index}`}
        >
          {activityGroup.categorizedActivities.length > 0 ? (
            <CategorizedActivities
              categorizedActivities={activityGroup.categorizedActivities}
              columns={columns}
            />
          ) : (
            <UncategorizedActivities
              activities={activityGroup.activities}
              columns={columns}
            />
          )}
        </Accordion>
      );
    })
  ) : (
    <div className={styles.noMatchingActivities}>
      {isFrench
        ? "Aucune activités ne correspond aux critères de recherche"
        : "No Activities Match Search Criteria"}
    </div>
  );
};

export default ActivitiesAccordion;
