import { getPercentage } from "../helpful";

export const getActivityOnSpecificOption = (data, option) => {
  const activities = (data?.MountainAreas || [])
    .filter(({ Name }) => Name === option)
    .flatMap(({ Activities }) => Activities);

  return {
    value: activities.filter(
      ({ Status }) => Status === "open" || Status === "Open"
    ).length,
    total: activities.length,
  };
};

export const getScenicGondolaStatus = (data) => {
  const mountains = data?.MountainAreas;
  const lodge = mountains?.find(
    (mountain) => mountain.Name === "Mammoth Mountain"
  );
  const lodgeActivities = lodge?.Activities;
  const scenicGondola = lodgeActivities?.find(
    (activity) => activity.Name === "Scenic Gondola"
  );
  const scenicGondolaStatus = scenicGondola?.Status
    ? scenicGondola.Status.toLowerCase()
    : null;

  if (!scenicGondolaStatus) return "--";

  return scenicGondolaStatus;
};

const countOpenTrailByCategory = (mountainAreas, category) => {
  const trails = (mountainAreas || [])
    .flatMap(({ CategorizedTrails }) => CategorizedTrails)
    .reduce((acc, categorizedTrails) => {
      const selectedTrails = categorizedTrails[category];
      if (selectedTrails) {
        return [...acc, ...selectedTrails];
      }
      return acc;
    }, []);

  return {
    value: trails.filter(({ Status }) => Status === "Open" || Status === "open")
      .length,
    total: trails.length,
  };
};

export const getStatsDataEndpoint = (data, areaLocation, options) => {
  const snowReport = data.SnowReport;
  const snowReportArea = snowReport ? snowReport[areaLocation] : {};
  return {
    trailsOpenPerTotal: {
      value: parseInt(snowReportArea.TotalOpenTrails),
      total: parseInt(snowReportArea.TotalTrails),
    },
    trailsGroomedPerTotal: {
      value: parseInt(snowReportArea.GroomedTrails),
      total: parseInt(snowReportArea.TotalTrails),
    },
    terrainOpenPercent: {
      value: getPercentage(
        parseInt(snowReportArea.OpenTerrainAcres),
        parseInt(snowReportArea.TotalTerrainAcres)
      ),
    },
    liftsOpenPerTotal: {
      value: parseInt(snowReportArea.TotalOpenLifts),
      total: parseInt(snowReportArea.TotalLifts),
    },
    terrainOpenPerTotal: {
      value: parseInt(snowReportArea.OpenTerrainAcres),
      total: parseInt(snowReportArea.TotalTerrainAcres),
    },
    snowmakingTrailsPerTotal: {
      value: parseInt(snowReportArea.TotalTrailsMakingSnow),
      total: parseInt(snowReportArea.TotalTrails),
    },
    parksOpenPerTotal: {
      value: parseInt(snowReportArea.TotalOpenParks),
      total: parseInt(snowReportArea.TotalParks),
    },
    activitiesOpenPerTotal: {
      value: snowReportArea.TotalOpenActivities,
      total: snowReportArea.TotalActivities,
    },
    nightTrailsOpenPerTotal: {
      value: parseInt(snowReportArea.OpenNightTrails),
      total: parseInt(snowReportArea.TotalNightTrails),
    },
    halfpipesOpenPerTotal: {
      value: parseInt(snowReportArea.OpenHalfpipes),
      total: parseInt(snowReportArea.TotalHalfpipes),
    },
    mountainActivitiesOpenPerTotal: getActivityOnSpecificOption(
      data,
      options.mountainActivities
    ),
    golfCourseOpenPerTotal: getActivityOnSpecificOption(data, "Golf Course"),
    snowmakingStatus: {
      snowmakingActive: snowReportArea.SnowMakingActive,
    },
    groomingStatus: {
      groomingActive: snowReportArea?.GroomingActive,
    },
    hikingTrailsOpenPerTotal: countOpenTrailByCategory(
      data?.MountainAreas,
      "Hiking Trails"
    ),
    multiUseTrailsOpenPerTotal: countOpenTrailByCategory(
      data?.MountainAreas,
      "Multi-Use (Bike/Hike) Trails"
    ),
    downcountryBikeTrailsOpen: countOpenTrailByCategory(
      data?.MountainAreas,
      "Downcountry Bike Trails"
    ),
  };
};
