import classnames from "classnames";
import styles from "../../../styles/components/storybook/widgets/TrailWidget.module.scss";
import Icon from "../../shared/Icon/Icon";

const Trail = ({
  name,
  status,
  openStatusClass,
  difficultyIconPath,
  difficultyIconType,
  featureIcons,
  featureIconType,
}) => {
  const difficultyIconClassNames = classnames(
    styles.iconWrapper,
    styles.difficulty
  );

  const featureIconClassNames = classnames(styles.iconWrapper, styles.feature);

  const statusClassNames = classnames(
    styles.status,
    openStatusClass ? styles.open : styles.closed
  );

  return (
    <li className={styles.trail}>
      <div className={styles.pseudoBorderElement}></div>
      <div className={styles.inner}>
        {difficultyIconPath && (
          <div className={difficultyIconClassNames}>
            <Icon
              name={difficultyIconPath}
              type={difficultyIconType}
              className={styles.iconDifficulty}
            />
          </div>
        )}
        <p className={styles.name}>{name}</p>
        {featureIcons &&
          featureIcons.length > 0 &&
          featureIcons.map((featureIcon, i) => {
            return (
              <div
                className={featureIconClassNames}
                key={`${featureIcon}-${i}`}
              >
                <Icon
                  name={featureIcon}
                  type={featureIconType}
                  className={styles.iconFeature}
                />
              </div>
            );
          })}
        <p className={statusClassNames}>{status}</p>
      </div>
    </li>
  );
};

export default Trail;
