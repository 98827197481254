import {
  UNIT_LENGTH_CM,
  UNIT_LENGTH_IN,
  UNIT_TEMPERATURE_C,
  UNIT_TEMPERATURE_F,
} from "../../../lib/components/weatherWidget";
import AlertBar from "../molecules/AlertBar";
import Hours from "../molecules/Hours";
import CurrentWeather from "../organisms/CurrentWeather";
import styles from "../../../styles/components/storybook/widgets/WeatherWidget.module.scss";
import WeatherForecast from "../organisms/Forecast";
import { useContext, useState } from "react";
import ContentWithHeader from "../molecules/ContentWithHeader";
import ExpandContent from "../atoms/ExpandContent";
import { getWeatherDataEndpoints } from "../../../lib/components/weatherWidget";
import StormAlert from "../molecules/StormAlert";
import Widget from "../organisms/Widget";
import Tabs from "../molecules/Tabs";
import SnowfallBannerWidget from "./SnowfallBannerWidget";
import StatsList from "../molecules/StatList";
import Divider from "../atoms/Divider";
import useWindowDimensions from "../../../lib/hooks/windowDimensions";
import CurrentWeatherAreaContext from "../../../lib/context/create-context/currentWeatherAreaContext";
import LanguageContext from "../../../lib/context/create-context/languageContext";

const WeatherWidget = ({ id, data, content }) => {
  const {
    title,
    tabs,
    weatherCard,
    forecastCard,
    snowfallCard,
    snowFallSource,
    snowFallBannerSource,
    defaultUnitLength,
    defaultUnitTemp,
    details: detailsSwitchOn,
    hideForecastTitle,
    forecastTitleSource,
    stormAlertBanner,
    renderSnowfallBannerCondensedVariant,
    renderSnowfallBanner,
    snowfallBannerLabels,
    renderStats,
    displayStats,
  } = content;

  const UNIT_TEMPERATURE_DEFAULT =
    defaultUnitTemp === "celsius" ? UNIT_TEMPERATURE_C : UNIT_TEMPERATURE_F;
  const UNIT_LENGTH_DEFAULT =
    defaultUnitLength === "centimeters" ? UNIT_LENGTH_CM : UNIT_LENGTH_IN;
  //SET DREGREE UNIT AND MEASUREMENT UNIT STATE

  const windowDimensions = useWindowDimensions();

  const [areaLocation, setAreaLocation] = useContext(CurrentWeatherAreaContext);
  const [degreeUnit, setDegreeUnit] = useState(UNIT_TEMPERATURE_DEFAULT);
  const [measurementUnit, setMeasurementUnit] = useState(UNIT_LENGTH_DEFAULT);

  const weatherData = getWeatherDataEndpoints(data);

  const areaLocationName = weatherData.names[areaLocation];

  const { isFrench } = useContext(LanguageContext);

  const getForecastTitle = () => {
    let title = "";
    if (forecastTitleSource) {
      if (forecastTitleSource === "dynamic") {
        title = areaLocationName;
      } else {
        // If forecastTitleSource is not a valid key for names then assume that it is an override.
        title = weatherData.names[forecastTitleSource] || forecastTitleSource;
      }
    }
    return isFrench ? `Prévisions - ${title}` : `${title} Forecast`;
  };

  const getSnowfallSource = () =>
    snowFallSource ? snowFallSource : areaLocation;

  return (
    <Widget
      id={id}
      headerTitle={title ? title : null}
      padding={false}
      headerBackground={windowDimensions.width <= 768}
      largeTitle={true}
    >
      <AlertBar alert={weatherData.alert[areaLocation]} />
      <div className={styles.weatherTabsContainer}>
        <Tabs
          options={tabs.map(({ key }) => ({
            label: weatherData.names[key],
            value: key,
          }))}
          setCurrentTab={setAreaLocation}
          currentTab={areaLocation}
          tabsGroupClassName={styles.tabsGroup}
        />
      </div>
      <div className={styles.weatherComponent}>
        {stormAlertBanner && (
          <StormAlert
            measurementUnit={measurementUnit}
            stormTotal={weatherData.stormAlert[areaLocation]}
          />
        )}
        <Hours lodge={weatherData.conditions[areaLocation].lodge} />
        <div className={styles.weatherWrapper}>
          <CurrentWeather
            degreeUnit={degreeUnit}
            measurementUnit={measurementUnit}
            setDegreeUnit={setDegreeUnit}
            setMeasurementUnit={setMeasurementUnit}
            weatherCard={weatherCard}
            snowfallCard={
              snowfallCard
                ? {
                    ...snowfallCard,
                    useRangeReading: snowfallCard.useRangeReading
                      ? snowfallCard.useRangeReading[getSnowfallSource()]
                      : false,
                  }
                : null
            }
            currentConditions={weatherData.conditions[areaLocation]}
            snowReport={weatherData.snowReport[getSnowfallSource()]}
          />
          {detailsSwitchOn && (
            <ContentWithHeader
              styles={styles}
              className="details"
              marginTop={true}
            >
              <ExpandContent
                content={weatherData.weatherDetails[areaLocation]}
                maxCharLength={355}
              />
            </ContentWithHeader>
          )}
          <Divider hideDesktop={true} marginTop={true} />
          <WeatherForecast
            items={forecastCard?.items}
            degreeUnit={degreeUnit}
            measurementUnit={measurementUnit}
            hideForecastTitle={hideForecastTitle}
            currentForecast={{
              ...weatherData.forecasts[areaLocation],
              title: getForecastTitle(),
            }}
            isFrench={isFrench}
          />
        </div>
      </div>
      {renderSnowfallBannerCondensedVariant && (
        <SnowfallBannerWidget
          condensedVariant={true}
          lenghtUnit={measurementUnit}
          content={{
            area: snowFallBannerSource?.length
              ? weatherData.names[snowFallBannerSource]
              : areaLocationName,
          }}
          snowfallBannerDataEndpoints={
            snowFallBannerSource?.length
              ? weatherData.snowFallBanner[snowFallBannerSource]
              : weatherData.snowFallBanner[areaLocation]
          }
          snowfallBannerLabels={snowfallBannerLabels}
        />
      )}
      {renderSnowfallBanner && (
        <SnowfallBannerWidget
          lenghtUnit={measurementUnit}
          content={{
            area: snowFallBannerSource?.length
              ? weatherData.names[snowFallBannerSource]
              : areaLocationName,
          }}
          snowfallBannerDataEndpoints={
            snowFallBannerSource?.length
              ? weatherData.snowFallBanner[snowFallBannerSource]
              : weatherData.snowFallBanner[areaLocation]
          }
          snowfallBannerLabels={snowfallBannerLabels}
        />
      )}
      <Divider line={true} hideDesktop={true} marginTop={true} />
      {renderStats && (
        <StatsList
          areaLocation={areaLocation}
          displayStats={
            displayStats[areaLocation]
              ? displayStats[areaLocation]
              : displayStats.zero
          }
          hideSnowMakingOffStatus={displayStats.hideSnowMakingOffStatus}
        />
      )}
    </Widget>
  );
};

export default WeatherWidget;
