//weather.js
import { format as formatDate } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { valideObjectEndpoint } from "./helpful";
import { getSnowFallBannerWidgetEndpoints } from "./snowfallBannerWidget";

export const FORECAST_DAYS = ["TwoDay", "ThreeDay", "FourDay"];

export const daysOfWeekENtoFR = {
  'Sun': 'Dim',
  'Mon': 'Lun',
  'Tue': 'Mar',
  'Wed': 'Mer',
  'Thu': 'Jeu',
  'Fri': 'Ven',
  'Sat': 'Sam' 
};

export const monthsENtoFR = {
  'Jan': 'Janv',
  'Feb': 'Févr',
  'Mar': 'Mars',
  'Apr': 'Avr', 
  'May': 'Mai', 
  'Jun': 'Juin',
  'Jul': 'Juil',
  'Aug': 'Août',
  'Sep': 'Sept',
  'Oct': 'Oct', 
  'Nov': 'Nov', 
  'Dec': 'Déc'  
};

const INCHES = `"`;
const CENTIMETERS = `cm`;
const DEGREE_FAHRENHEIT = "\u00B0F";
const DEGREE_CELSIUS = `\u00B0C`;

export const getAlertData = (data) => {
  return data?.SnowReport
    ? Object.fromEntries(
        Object.entries(data?.SnowReport || {}).map(([key, snowReport]) => [
          key,
          snowReport?.Alert,
        ])
      )
    : {};
};

export const getWeatherDetailsData = (
  forecast,
  timeZone = "America/Vancouver"
) => {
  const dayComments = forecast?.DayComments;
  const nightComments = forecast?.NightComments;

  const getIsDayTime = isDayTime(timeZone);
  if (getIsDayTime) {
    return dayComments;
  }
  if (!getIsDayTime) {
    return nightComments;
  }
};

export const getStormAlertData = (snowReport) => {
  return {
    inches: {
      stormTotal: snowReport ? snowReport.StormTotalIn : null,
    },
    centimeters: {
      stormTotal: snowReport ? snowReport.StormTotalCM : null,
    },
  };
};

export const getConditionsData = (location, conditions, snowReport) => {
  const snowReportLocation = snowReport ? snowReport[location] : null;

  return {
    baseConditions: snowReportLocation
      ? snowReportLocation.BaseConditions
      : null,
    iconName: getWeatherIconName(conditions),
    skies: conditions?.Skies,
    windDirection: conditions.WindDirection,
    fahrenheit: {
      windSpeed: conditions ? `${conditions.WindStrengthMph}mph` : null,
      windChill: conditions
        ? `${conditions.WindChillF}${DEGREE_FAHRENHEIT}`
        : null,
      temperature: conditions ? conditions.TemperatureF : null,
      temperatureLow: conditions
        ? `${conditions.TemperatureLowF}${DEGREE_FAHRENHEIT}`
        : null,
      temperatureHigh: conditions
        ? `${conditions.TemperatureHighF}${DEGREE_FAHRENHEIT}`
        : null,
    },
    celsius: {
      windSpeed: conditions ? `${conditions.WindStrengthKph}kph` : null,
      windChill: conditions
        ? `${conditions.WindChillC}${DEGREE_CELSIUS}`
        : null,
      temperature: conditions ? conditions.TemperatureC : null,
      temperatureLow: conditions
        ? `${conditions.TemperatureLowC}${DEGREE_CELSIUS}`
        : null,
      temperatureHigh: conditions
        ? `${conditions.TemperatureHighC}${DEGREE_CELSIUS}`
        : null,
    },
    lodge:
      conditions && conditions?.Lodge
        ? {
            label: conditions.Lodge.Name,
            isOpen: conditions.Lodge.Open,
            hours: valideObjectEndpoint(conditions.Lodge.Hours)
              ? Object.fromEntries(
                  Object.entries(conditions.Lodge.Hours).map(
                    ([key, { Open, Close }]) => [
                      key,
                      {
                        Open: Open && Open !== "Closed" ? Open : "",
                        Close: Close && Close !== "Closed" ? Close : "",
                      },
                    ]
                  )
                )
              : {},
            moreHoursUrl: conditions.Lodge.MoreHoursUrl,
          }
        : null,
  };
};

const getForecastData = (forecast, defaultForecastTitle) => ({
  title: (forecast.title || defaultForecastTitle || "").replace(" Summer", ""),
  ...FORECAST_DAYS.map((day) => {
    const dayForecast = forecast[day];
    const cleanDate = dayForecast.date.split("-") || null;
    const date = new Date(cleanDate[0], cleanDate[1] - 1, cleanDate[2]) || null;

    return {
      key: day,
      value: {
        forecastIconName: getWeatherIconName(dayForecast),
        forecastDayName: getDayOfWeek(date),
        forecastDayMonth: getCurrentMonth(date),
        forecastDayNumber: getCurrentDayNumber(date),
        forecastSkies: dayForecast?.skies,
        forecastWindDirection: dayForecast?.avewind?.dir,
        fahrenheit: {
          forecastWindSpeed: dayForecast ? dayForecast?.avewind?.mph : null,
          forecastTemperatureHigh: dayForecast
            ? `${dayForecast?.temp_high_f}${DEGREE_FAHRENHEIT}`
            : null,
          forecastTemperatureLow: dayForecast
            ? `${dayForecast?.temp_low_f}${DEGREE_FAHRENHEIT}`
            : null,
        },
        celsius: {
          forecastWindSpeed: dayForecast ? dayForecast?.avewind?.kph : null,
          forecastTemperatureHigh: dayForecast
            ? `${dayForecast?.temp_high_c}${DEGREE_CELSIUS}`
            : null,
          forecastTemperatureLow: dayForecast
            ? `${dayForecast?.temp_low_c}${DEGREE_CELSIUS}`
            : null,
        },
        inches: {
          forecastSnowDay: `${parseInt(
            dayForecast?.forecasted_snow_day_in
          )}${INCHES}`,
          forecastSnowNight: `${parseInt(
            dayForecast?.forecasted_snow_night_in
          )}${INCHES}`,
        },
        centimeters: {
          forecastSnowDay: `${parseInt(
            dayForecast?.forecasted_snow_day_cm
          )}${CENTIMETERS}`,
          forecastSnowNight: `${parseInt(
            dayForecast?.forecasted_snow_night_cm
          )}${CENTIMETERS}`,
        },
      },
    };
  }).reduce((obj, item) => Object.assign(obj, { [item.key]: item.value }), {}),
});

const getSnowReportData = (snowReportLocation) => {
  const snowReportArea = snowReportLocation ? snowReportLocation.Area : null;

  return {
    inches: {
      seasonTotal: snowReportLocation
        ? `${snowReportLocation.SeasonTotalIn}${INCHES}`
        : null,
      stormTotal: snowReportLocation ? snowReportLocation.StormTotalIn : null,
      locationBase: snowReportArea ? snowReportArea?.BaseIn : null,
      snowBaseRange: snowReportLocation
        ? snowReportLocation?.SnowBaseRangeIn
        : null,
      last24Hour: snowReportArea
        ? `${snowReportArea?.Last24HoursIn}${INCHES}`
        : null,
      last48Hour: snowReportArea
        ? `${snowReportArea?.Last48HoursIn}${INCHES}`
        : null,
      last72Hour: snowReportArea
        ? `${snowReportArea?.Last72HoursIn}${INCHES}`
        : null,
      last7Days: snowReportArea
        ? `${snowReportArea?.Last7DaysIn}${INCHES}`
        : null,
    },
    centimeters: {
      seasonTotal: snowReportLocation
        ? `${snowReportLocation.SeasonTotalCm}${CENTIMETERS}`
        : null,
      stormTotal: snowReportLocation ? snowReportLocation.StormTotalCM : null,
      locationBase: snowReportArea ? snowReportArea?.BaseCm : null,
      snowBaseRange: snowReportLocation
        ? snowReportLocation?.SnowBaseRangeCM
        : null,
      last24Hour: snowReportArea
        ? `${snowReportArea?.Last24HoursCm}${CENTIMETERS}`
        : null,
      last48Hour: snowReportArea
        ? `${snowReportArea?.Last48HoursCm}${CENTIMETERS}`
        : null,
      last72Hour: snowReportArea
        ? `${snowReportArea?.Last72HoursCm}${CENTIMETERS}`
        : null,
      last7Days: snowReportArea
        ? `${snowReportArea?.Last7DaysCm}${CENTIMETERS}`
        : null,
    },
  };
};

export const getWeatherDataEndpoints = (data) => {
  if (!data) return null;
  return {
    names: Object.fromEntries(
      Object.entries(data.Names).map(([key, name]) => [
        key,
        (name || "").replace(" Summer", ""),
      ])
    ),
    alert: getAlertData(data),
    stormAlert: Object.fromEntries(
      Object.entries(data.SnowReport).map(([key, snowReport]) => [
        key,
        getStormAlertData(snowReport),
      ])
    ),
    conditions: Object.fromEntries(
      Object.entries(data.CurrentConditions).map(([key, currentConditions]) => [
        key,
        getConditionsData(key, currentConditions, data?.SnowReport, {
          snowReportSource: "MidMountainArea",
        }),
      ])
    ),
    snowReport: Object.fromEntries(
      Object.entries(data.SnowReport).map(([key, snowReport]) => [
        key,
        getSnowReportData(snowReport),
      ])
    ),
    forecasts: Object.fromEntries(
      Object.entries(data.Forecasts).map(([key, forecast]) => [
        key,

        getForecastData(forecast),
      ])
    ),
    weatherDetails: Object.fromEntries(
      Object.entries(data.Forecasts).map(([key, forecast]) => [
        key,
        getWeatherDetailsData(forecast),
      ])
    ),
    snowFallBanner: Object.fromEntries(
      Object.entries(data.SnowReport).map(([key, snowReport]) => [
        key,
        getSnowFallBannerWidgetEndpoints(snowReport),
      ])
    ),
  };
};

// -------------------- DATE FUNCTIONS -------------------- //

// https://date-fns.org/v2.14.0/docs/format
export const getDayOfWeek = (date) => formatDate(date, "eee");
export const getCurrentMonth = (date) => formatDate(date, "MMM");
export const getCurrentDayNumber = (date) => formatDate(date, "d");

export function isDayTime(timeZone) {
  const date = new Date();
  const amHour = 6;
  const pmHour = 18;
  const currentHour = formatInTimeZone(date, timeZone, "k"); //timezones current hour [1-24]

  return currentHour >= amHour && currentHour < pmHour;
}

// -------------------- MAP WEATHER ICONS -------------------- //
// Weather Icons
const ICON_BKN = Symbol("bkn");
const ICON_FEW = Symbol("few");
const ICON_FOG = Symbol("fog");
const ICON_HAZE = Symbol("haze");
const ICON_NA = Symbol("na");
const ICON_RAIN = Symbol("rain");
const ICON_RAIN_FZRA = Symbol("rain_fzra");
const ICON_RAIN_SNOW = Symbol("rain_snow");
const ICON_SKC = Symbol("skc");
const ICON_SMOKE = Symbol("smoke");
const ICON_SNOW = Symbol("snow");
const ICON_TSRA = Symbol("tsra");
const ICON_TSRA_HI = Symbol("tsra_hi");
const ICON_TSRA_SCT = Symbol("tsra_sct");

// Translate weather icon to svg filenames.
export const ICON_SVG_MAP = {
  [ICON_BKN]: "bkn",
  [ICON_FEW]: "few",
  [ICON_FOG]: "fog",
  [ICON_HAZE]: "haze",
  [ICON_NA]: "na",
  [ICON_RAIN]: "rain",
  [ICON_RAIN_FZRA]: "rain_fzra",
  [ICON_RAIN_SNOW]: "rain_snow",
  [ICON_SKC]: "skc",
  [ICON_SMOKE]: "smoke",
  [ICON_SNOW]: "snow",
  [ICON_TSRA]: "tsra",
  [ICON_TSRA_HI]: "tsra_hi",
  [ICON_TSRA_SCT]: "tsra_sct",
};

export const getWeatherIconName = (props) => {
  // First search by icon name.
  // It has 2 different names in the API, so get one of them.
  if (!props) return "bkn.svg";
  if (!props.icon && !props.Icon) return "bkn.svg";
  const icon = props.icon ? props.icon : props.Icon;

  if (!icon || icon === undefined) {
    return "bkn.svg";
  }

  if (icon === "bkn.svg") return ICON_SVG_MAP[ICON_BKN];
  if (icon === "few.svg") return ICON_SVG_MAP[ICON_FEW];
  if (icon === "fog.svg") return ICON_SVG_MAP[ICON_FOG];
  if (icon === "haze.svg") return ICON_SVG_MAP[ICON_HAZE];
  if (icon === "na.svg") return ICON_SVG_MAP[ICON_NA];
  if (icon === "rain.svg") return ICON_SVG_MAP[ICON_RAIN];
  if (icon === "rain_fzra.svg") return ICON_SVG_MAP[ICON_RAIN_FZRA];
  if (icon === "rain_snow.svg") return ICON_SVG_MAP[ICON_RAIN_SNOW];
  if (icon === "skc.svg") return ICON_SVG_MAP[ICON_SKC];
  if (icon === "smoke.svg") return ICON_SVG_MAP[ICON_SMOKE];
  if (icon === "snow.svg") return ICON_SVG_MAP[ICON_SNOW];
  if (icon === "blizzard.svg") return ICON_SVG_MAP[ICON_SNOW];
  if (icon === "tsra.svg") return ICON_SVG_MAP[ICON_TSRA];
  if (icon === "tsra_hi.svg") return ICON_SVG_MAP[ICON_TSRA_HI];
  if (icon === "tsra_sct.svg") return ICON_SVG_MAP[ICON_TSRA_SCT];
  if (icon === "--") return ICON_SVG_MAP[ICON_NA];

  return icon.split(".")[0];
};

// Get the default area name from the feed.
export const getDefaultAreaName = ({ CurrentConditions }) => {
  if (!CurrentConditions) return "Base";

  const areaNames = Object.keys(CurrentConditions);

  // Find the area which the API identifies as default.
  // Fall back to 'Base' if none are default.
  return (
    areaNames.find((area) => CurrentConditions[area].Default === "true") ||
    "Base"
  );
};

// -------------------- UNITS OF MEASUREMENTS/TEMPERATURE -------------------- //
// Units of temperature
export const UNIT_TEMPERATURE_F = Symbol("fahrenheit");
export const UNIT_TEMPERATURE_C = Symbol("celsius");
export const UNIT_TEMPERATURES = {
  [UNIT_TEMPERATURE_C]: {
    decorateSpeed: (value) => `${value}kph`,
    decorateTemperature: (value) => `${value}\u00B0C`,
    getTemperature: (conditions) => conditions.TemperatureC,
    getTemperatureHigh: (conditions) => conditions?.TemperatureHighC,
    getTemperatureLow: (conditions) => conditions.TemperatureLowC,
    getWindChill: (conditions) => conditions.WindChillC,
    getWindSpeed: (conditions) => conditions.WindStrengthKph,
  },
  [UNIT_TEMPERATURE_F]: {
    decorateSpeed: (value) => `${value}mph`,
    decorateTemperature: (value) => `${value}\u00B0F`,
    getTemperature: (conditions) => conditions.TemperatureF,
    getTemperatureHigh: (conditions) => conditions.TemperatureHighF,
    getTemperatureLow: (conditions) => conditions.TemperatureLowF,
    getWindChill: (conditions) => conditions.WindChillF,
    getWindSpeed: (conditions) => conditions.WindStrengthMph,
  },
};

// Units of length
export const UNIT_LENGTH_IN = Symbol("inches");
export const UNIT_LENGTH_CM = Symbol("centimeters");
export const UNIT_LENGTHS = {
  [UNIT_LENGTH_CM]: {
    label: "Centimeters",
    name: "cm",
  },
  [UNIT_LENGTH_IN]: {
    label: "Inches",
    name: "in",
  },
};

export const getLengthUnit = (unit) => {
  if (!Object.getOwnPropertySymbols(UNIT_LENGTHS).includes(unit)) {
    return null;
  }

  return UNIT_LENGTHS[unit];
};

// Get the name given a unit symbol
export const getUnitLengthText = (unit) => {
  const unitInfo = getLengthUnit(unit);

  if (!unitInfo) {
    return;
  }

  return unitInfo.name;
};
