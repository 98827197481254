import styles from "../../../../styles/components/storybook/organisms/ActivitiesAccordion.module.scss";

import Activity from "../../molecules/Activity";

const UnCategorizedActivities = ({ activities, columns }) => {
  return (
    <>
      <div className={styles.accordionPseudoElement}></div>
      <ul
        className={styles.activityList}
        style={{
          gridTemplateRows: `repeat(${Math.ceil(
            activities.length / columns
          )}, 1fr)`,
        }}
      >
        {activities.map((activity, index) => {
          return (
            <Activity
              key={`${activity.key}-${index}`}
              name={activity.name}
              status={activity.status}
              statusIcon={activity.statusIcon}
              hours={activity.hours}
              icon={activity.icon}
            />
          );
        })}
      </ul>
    </>
  );
};

export default UnCategorizedActivities;
