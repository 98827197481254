import classnames from "classnames";
import styles from "../../../styles/components/storybook/molecules/Tabs.module.scss";

const Tab = ({ className, isActive = false, onClick, label, value }) => {
  const classNames = classnames(className, styles.tab, {
    [styles.active]: isActive,
  });

  return (
    <li className={classNames}>
      <button role="tab" onClick={onClick} value={value}>
        {label}
      </button>
    </li>
  );
};

export default Tab;
