import styles from "../../../styles/components/storybook/widgets/PromoWidget.module.scss";
import Widget from "../organisms/Widget";

const Promo = (props) => {
  const { id, data, content } = props;

  let { image, mobileImage, link, size = "large" } = content;

  if (image === "dataFeedPromo") {
    const dataImage = data?.LayoutOptions?.PromoImages?.DesktopUrl;
    const dataMobileImage = data?.LayoutOptions?.PromoImages?.MobileUrl;

    image = dataImage && dataImage !== "--" ? dataImage : null;
    mobileImage =
      dataMobileImage && dataMobileImage !== "--" ? dataMobileImage : null;
    link = data?.LayoutOptions?.PromoImages?.LinkUrl;
  }

  if (!image || image === "--") {
    return null;
  }

  return (
    <Widget
      id={id}
      background={false}
      padding={true}
      marginTop={true}
      center={true}
    >
      <a
        className={styles.component}
        href={link}
        target="_blank"
        rel="noreferrer nofollow noopener"
      >
        <img
          // hide on mobile if mobile image exists
          className={mobileImage ? styles.showOnDesktop : ""}
          src={image}
          width={size === "large" ? "970" : "400"}
          height="90"
          alt="Promo"
        />

        {/* If a mobile image exists from the API, use the different data */}
        {mobileImage && (
          <img className={styles.showOnMobile} src={mobileImage} alt="Promo" />
        )}
      </a>
    </Widget>
  );
};

export default Promo;
