import {
  UNIT_LENGTH_IN,
  UNIT_LENGTH_CM,
  UNIT_TEMPERATURE_F,
  UNIT_TEMPERATURE_C,
} from "../../../lib/components/weatherWidget";
import styles from "../../../styles/components/storybook/widgets/WeatherWidget.module.scss";

import WeatherCard from "../molecules/WeatherCard";
import { FORECAST_DAYS, daysOfWeekENtoFR, monthsENtoFR } from "../../../lib/components/weatherWidget";

const WeatherForecast = (props) => {
  const {
    items,
    degreeUnit,
    measurementUnit,
    hideForecastTitle,
    currentForecast,
    isFrench,
  } = props;

  return (
    <>
      <ul className={styles.forecastComponent}>
        {!hideForecastTitle && (
          <div className={styles.forecastTitle}>{currentForecast.title}</div>
        )}
        {FORECAST_DAYS.map((day, i) => {
          if (day !== undefined) {
            const {
              forecastIconName,
              forecastWindDirection,
              forecastSkies,
              forecastDayName,
              forecastDayNumber,
              forecastDayMonth,
              inches = {},
              centimeters = {},
              fahrenheit = {},
              celsius = {},
            } = currentForecast[day];

            let forecastTemperatureHigh,
              forecastTemperatureLow,
              forecastWindSpeed;

            if (degreeUnit === UNIT_TEMPERATURE_C) {
              forecastTemperatureHigh = celsius.forecastTemperatureHigh;
              forecastTemperatureLow = celsius.forecastTemperatureLow;
              forecastWindSpeed = celsius.forecastWindSpeed;
            }

            if (degreeUnit === UNIT_TEMPERATURE_F) {
              forecastTemperatureHigh = fahrenheit.forecastTemperatureHigh;
              forecastTemperatureLow = fahrenheit.forecastTemperatureLow;
              forecastWindSpeed = fahrenheit.forecastWindSpeed;
            }

            let forecastSnowDay, forecastSnowNight;

            if (measurementUnit === UNIT_LENGTH_IN) {
              forecastSnowDay = inches.forecastSnowDay;
              forecastSnowNight = inches.forecastSnowNight;
            }

            if (measurementUnit === UNIT_LENGTH_CM) {
              forecastSnowDay = centimeters.forecastSnowDay;
              forecastSnowNight = centimeters.forecastSnowNight;
            }

            const translatedDayName = isFrench ? daysOfWeekENtoFR[forecastDayName] : forecastDayName;
            const translatedMonthName = isFrench ? monthsENtoFR[forecastDayMonth] : forecastDayMonth;

            return (
              <li key={i}>
                <WeatherCard
                  centerItems={true}
                  iconName={forecastIconName}
                  iconType="weather"
                  labeledListItems={items}
                  measurementUnit={measurementUnit}
                  forecastDayName={translatedDayName}
                  forecastDayNumber={forecastDayNumber}
                  forecastDayMonth={translatedMonthName}
                  forecastSnowDay={forecastSnowDay}
                  forecastSnowNight={forecastSnowNight}
                  forecastTemperatureHigh={forecastTemperatureHigh}
                  forecastTemperatureLow={forecastTemperatureLow}
                  forecastWindSpeed={forecastWindSpeed}
                  forecastWindDirection={forecastWindDirection}
                  forecastSkies={forecastSkies}
                  isFrench={isFrench}
                />
              </li>
            );
          }
        })}
      </ul>
    </>
  );
};

export default WeatherForecast;
