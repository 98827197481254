import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Page from "./components/layout/Page";
import "./App.scss";

const App = () => {
  console.log(
    `%cENV: ${process.env.NODE_ENV}`,
    "color: orange; font-family: monospace; font-size: 16px"
  );
  return (
    <Router>
      <Routes>
        <Route path="*" element={<Page />} />
      </Routes>
    </Router>
  );
};

export default App;
