import classnames from "classnames";
import styles from "../../../styles/components/storybook/atoms/Divider.module.scss";
import image from "../../../assets/dropShadow.png";

const Divider = (props) => {
  const { hideDesktop, marginTop, line } = props;

  const classNames = classnames(
    styles.component,
    hideDesktop && styles.hideDesktop,
    marginTop && styles.marginTop
  );

  return (
    <>
      <div className={classNames}>
        <img className={styles.divider} src={image} alt="drop shadow" />
      </div>
      {line && hideDesktop && <div className={styles.line}></div>}
    </>
  );
};

export default Divider;
