import { useContext } from "react";
import LanguageContext from "../../../lib/context/create-context/languageContext";
import classnames from "classnames";
import styles from "../../../styles/components/storybook/atoms/Widget.module.scss";
import SearchInput from "../atoms/SearchInput";
import SelectInput from "../atoms/SelectInput";
import ToggleSwitch from "../atoms/ToggleSwitch";
import LegendDrawer from "../organisms/LegendDrawer";
import { is } from "date-fns/locale";

const WidgetHeader = (props) => {
  const { isFrench } = useContext(LanguageContext);

  const {
    title,
    largeTitle,
    headerBackground,
    headerPaddingTop,
    open,
    total,
    setFilteredText,
    filteredText,
    placeholder,
    showClosed,
    showClosedLabel = "Show Closed",
    setShowClosed,
    showLegend,
    setShowLegend,
    showUphillOnly,
    setShowUphillOnly,
    showUphillToggle,
    sortTrails,
    setSortTrails,
    legendGroups,
    showClosedToggleDefaultValue,
    showLegendToggleDefaultValue,
    showUphillToggleDefaultValue,
    showLegendOnLoad,
    showSort,
    sortOptions,

    showSpecificActivityToggle,
    showSpecificActivityOnly,
    setShowSpecificActivityOnly,
    specificActivityToggleLabel,
  } = props;

  if (!title) return null;

  const classNames = classnames(
    styles.header,
    headerPaddingTop ? styles.headerPaddingTop : undefined,
    headerBackground ? styles.background : undefined,
    showLegend ? styles.withLegend : undefined
  );

  const titleClassName = classnames(
    styles.title,
    largeTitle ? styles.large : undefined,
    "widget-title"
  );

  const showClosedToggle =
    (showClosedToggleDefaultValue === true ||
      showClosedToggleDefaultValue === false) &&
    setShowClosed;

  const showLegendToggle =
    (showLegendToggleDefaultValue === true ||
      showLegendToggleDefaultValue === false) &&
    setShowLegend;

  const showUphillOnlyToggle =
    (showUphillToggleDefaultValue === true ||
      showUphillToggleDefaultValue === false) &&
    setShowUphillOnly;

  return (
    <>
      <div className={classNames}>
        {title && (
          <div className={styles.titleStatContainer}>
            {title && <h3 className={titleClassName}>{title}</h3>}
            {open && !!total && (
              <div className={styles.stat}>
                <span>
                  <strong>
                    {open}/{total}
                  </strong>
                </span>
                <span> {isFrench ? "OUVERT(S)" : "Open"}</span>
              </div>
            )}
          </div>
        )}
        {(showClosedToggle ||
          showLegendToggle ||
          showUphillOnlyToggle ||
          showSpecificActivityToggle) && (
          <div className={styles.toggleContainer}>
            {showUphillToggle && (
              <div className={styles.uphillToggle}>
                <ToggleSwitch
                  title={
                    isFrench
                      ? "Randonnée Alpine Seulement"
                      : "Alpine Touring Only"
                  }
                  isToggled={showUphillOnly}
                  setIsToggled={setShowUphillOnly}
                  id={"uphill"}
                />
              </div>
            )}
            {showSpecificActivityToggle && (
              <div className={styles.uphillToggle}>
                <ToggleSwitch
                  title={specificActivityToggleLabel}
                  isToggled={showSpecificActivityOnly}
                  setIsToggled={setShowSpecificActivityOnly}
                  id={"mutliActivity"}
                />
              </div>
            )}

            {showClosedToggle && (
              <div className={styles.closedToggle}>
                <ToggleSwitch
                  title={showClosedLabel}
                  isToggled={showClosed}
                  setIsToggled={setShowClosed}
                />
              </div>
            )}
            {showLegendToggle && (
              <div className={styles.legendToggle}>
                <ToggleSwitch
                  title={isFrench ? "Légende" : "Legend"}
                  isToggled={showLegend}
                  setIsToggled={setShowLegend}
                />
              </div>
            )}
          </div>
        )}
        {(setFilteredText || showSort) && (
          <div className={styles.inputContainer}>
            {setFilteredText && (
              <div className={styles.searchInput}>
                <SearchInput
                  placeholder={placeholder}
                  value={filteredText}
                  onChange={(e) => setFilteredText(e.target.value)}
                />
              </div>
            )}
            {showSort && setSortTrails && sortTrails && sortOptions && (
              <div className={styles.sortInput}>
                <div>
                  <label className={styles.sortLabel}>
                    {isFrench ? "Tri" : "Sort"}
                  </label>
                  <SelectInput
                    options={sortOptions}
                    onChange={(e) => setSortTrails(e.value)}
                    section='Trails'
                    value={sortTrails}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {showLegend && showLegendOnLoad && legendGroups?.length && (
        <LegendDrawer legendGroups={legendGroups} showLegend={showLegend} />
      )}
    </>
  );
};

export default WidgetHeader;
