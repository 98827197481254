import { getTextFromSymbol } from "../helpful";

// FEATURES
const GROOMERS_CHOICE = Symbol("Groomers Choice");
const GROOMING = Symbol("Grooming");
const GROOMING_FIRST_SHIFT = Symbol("Grooming 1st Shift");
const GROOMING_SECOND_SHIFT = Symbol("Grooming 2nd Shift");
const GROOMING_TONIGHT = Symbol("Grooming Tonight");
const GROOMING_TOP = Symbol("Grooming Top");
const GROOMING_BOTTOM = Symbol("Grooming Bottom");
const GROOMING_NIGHTLY = Symbol("Grooming Nightly");
const SNOW_MAKING = Symbol("SnowMaking");
const SNOW_MAKING_TONIGHT = Symbol("Snowmaking Tonight");
const SNOW_MAKING_OVERNIGHT = Symbol("Overnight");
const SNOW_MAKING_NIGHTTIME = Symbol("Nighttime");

const TRAILS_GROOMING_LEGENDS = [
  GROOMERS_CHOICE,
  GROOMING,
  GROOMING_FIRST_SHIFT,
  GROOMING_SECOND_SHIFT,
  GROOMING_TONIGHT,
  GROOMING_TOP,
  GROOMING_BOTTOM,
  GROOMING_NIGHTLY,
  SNOW_MAKING,
  SNOW_MAKING_TONIGHT,
  SNOW_MAKING_OVERNIGHT,
  SNOW_MAKING_NIGHTTIME,
];

// Grooming map
//
// mapping from each icon symbol to filename/classname
export const GROOMING_ICON_MAP = {
  [GROOMERS_CHOICE]: "groomers-choice",
  [GROOMING]: "grooming",
  [GROOMING_FIRST_SHIFT]: "grooming-first-shift",
  [GROOMING_SECOND_SHIFT]: "grooming-second-shift",
  [GROOMING_TONIGHT]: "grooming-tonight",
  [GROOMING_TOP]: "grooming-top",
  [GROOMING_BOTTOM]: "grooming-bottom",
  [GROOMING_NIGHTLY]: "grooming-nightly",
  [SNOW_MAKING]: "snow-making",
  [SNOW_MAKING_TONIGHT]: "snow-making-tonight",
  [SNOW_MAKING_OVERNIGHT]: "snow-making-tonight",
  [SNOW_MAKING_NIGHTTIME]: "snow-making-tonight",
};

export const getGrooming = (trail) => {
  return TRAILS_GROOMING_LEGENDS.filter((symbol) => {
    switch (symbol) {
      case GROOMERS_CHOICE:
        return trail?.Grooming === "Choice";
      case GROOMING:
        return ["Yes", "Always"].includes(trail.Grooming);
      case GROOMING_FIRST_SHIFT:
        return trail?.Grooming === "First Shift";
      case GROOMING_SECOND_SHIFT:
        return trail?.Grooming === "Second Shift";
      case GROOMING_TONIGHT:
        return trail?.Grooming === "Grooming Tonight";
      case GROOMING_TOP:
        return trail?.Grooming === "Top" || trail?.Grooming === "Haut";
      case GROOMING_BOTTOM:
        return trail?.Grooming === "Bottom" || trail?.Grooming === "Bas";
      case GROOMING_NIGHTLY:
        return trail?.Grooming === "Nightly";
      case SNOW_MAKING:
        return trail?.SnowMaking === "Yes";
      case SNOW_MAKING_TONIGHT:
        return trail?.SnowMaking === "Snowmaking Tonight";
      case SNOW_MAKING_OVERNIGHT:
        return trail?.SnowMaking === "Overnight";
      case SNOW_MAKING_NIGHTTIME:
        return trail?.SnowMaking === "Nighttime";
      default:
        return false;
    }
  });
};

export const getResortGrooming = (trailFeatureMappings, trails) => {
  return (
    TRAILS_GROOMING_LEGENDS
      // Add all FEATURES used by any trail
      .filter((key) =>
        trails?.some((trail) => getGrooming(trail).includes(key))
      )
      // Format as a nicely mapped object
      .reduce((acc, symbol) => {
        const key = getTextFromSymbol(symbol);
        const icon = GROOMING_ICON_MAP[symbol];
        const label = trailFeatureMappings[key];
        return { ...acc, [key]: { icon, label } };
      }, {})
  );
};
