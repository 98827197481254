export const getSnowFallBannerWidgetEndpoints = (snowReport) => {
  const snowReportArea = snowReport.Area;

  return {
    inches: {
      newSnowLow: `${snowReportArea?.newSnowLowIn}"`,
      newSnowHigh: `${snowReportArea?.newSnowHighIn}"`,
      today: `${snowReportArea?.Last24HoursIn}"`,
      tomorrow: `${snowReportArea?.Last48HoursIn}"`,
      seventyTwoHours: `${snowReportArea?.Last72HoursIn}"`,
      seasonTotal: `${snowReport?.SeasonTotalIn}"`,
      last7Days: snowReportArea?.Last7DaysIn,
    },
    centimeters: {
      newSnowLow: `${snowReportArea?.newSnowLowCm}cm`,
      newSnowHigh: `${snowReportArea?.newSnowHighCm}cm`,
      today: `${snowReportArea?.Last24HoursCm}cm`,
      tomorrow: `${snowReportArea?.Last48HoursCm}cm`,
      seventyTwoHours: `${snowReportArea?.Last72HoursCm}cm`,
      seasonTotal: `${snowReport?.SeasonTotalCm}cm`,
      last7Days: snowReportArea?.Last7DaysCm,
    },
  };
};
