import styles from "../../../styles/components/storybook/widgets/StatsWidget.module.scss";

import StatItem from "./StatItem";
import {
  getScenicGondolaStatus,
  getStatsDataEndpoint,
} from "../../../lib/components/statsWidget/statsWidget";
import ResortAPIFeedContext from "../../../lib/context/create-context/resortAPIFeedContext";
import { useContext } from "react";

const StatsList = (props) => {
  const {
    displayStats = {},
    areaLocation,
    hideSnowMakingOffStatus = true,
  } = props;

  const data = useContext(ResortAPIFeedContext);
  const statsData = getStatsDataEndpoint(data, areaLocation, {
    mountainActivities: "Lincoln Peak",
  });

  const rows = displayStats?.rows;
  const statRows = rows && rows.length > 0;

  const StatListItems = ({ type, label }) => {
    switch (type) {
      case "scenicGondolaStatus": {
        const gondolaStatus = getScenicGondolaStatus(data);
        const isOpen = gondolaStatus === "open";
        const status = isOpen ? "Open" : "Closed";
        const iconProps = {
          name: `gondola`,
          type: "lift-types",
        };
        return (
          <StatItem
            statType="icon"
            className={iconProps.name}
            label={label}
            status={status}
            iconProps={iconProps}
          />
        );
      }
      case "groomingStatus": {
        const { groomingActive } = statsData?.groomingStatus;
        const status = groomingActive === "true" ? "active" : "inactive";
        const iconProps = {
          name: `groomer`,
          type: "stats-rollup",
        };
        return (
          <StatItem
            statType="icon"
            label={label}
            status={status}
            iconProps={iconProps}
          />
        );
      }
      //Hidden when snowmaking is off
      case "snowmakingStatus": {
        const { snowmakingActive } = statsData.snowmakingStatus;
        const status = snowmakingActive === "true" ? "on" : "off";
        const iconProps = {
          name: `snowblower-${status}`,
          hiddenClass:
            hideSnowMakingOffStatus && status === "off"
              ? `snowblower-${status}-hide`
              : "",
          type: "stats-rollup",
        };
        return (
          <StatItem
            statType="icon"
            className={iconProps.name}
            hiddenClass={iconProps.hiddenClass}
            label={label}
            status={status}
            iconProps={iconProps}
          />
        );
      }

      case "terrainOpenPercent": {
        return (
          <StatItem statType="percent" label={label} {...statsData[type]} />
        );
      }
      default:
        return <StatItem statType="total" {...statsData[type]} label={label} />;
    }
  };

  return (
    <div className={styles.component}>
      {statRows &&
        rows.map((row, index) => (
          <ul className={styles.statsList} key={index}>
            {Object.entries(row).map(([type, label], i) => (
              <StatListItems key={i} type={type} label={label} />
            ))}
          </ul>
        ))}
    </div>
  );
};

export default StatsList;
