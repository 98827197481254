import { format as formatDate } from "date-fns";

// --------------------------- FILTERS --------------------------- //

export const getFilteredLifts = (filterText, lifts) => {
  const parsedFilterText = filterText.replace(/[\W_]+/g, " ").toLowerCase();

  lifts = lifts.filter((lift) => {
    const parsedName = lift.title.replace(/[\W_]+/g, " ").toLowerCase();

    return parsedName.includes(parsedFilterText);
  });

  return lifts;
};

// --------------------------- DATE/TIME --------------------------- //
export function getLiftDurations(durationsArr, Name) {
  let duration;
  if (durationsArr) {
    const configLifts = durationsArr.filter((item) => item.lift === Name);
    const configDuration = configLifts.map((item) => item.duration);
    duration = parseInt(configDuration);
  }

  return duration;
}

export function getLiftUpdated(UpdateDate) {
  return new Date(UpdateDate).toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
}

export function getHoursToday(Hours) {
  // Example Hours object:
  // {
  //   "Sunday": {
  //       "Open": "9:00am",
  //       "Close": "4:30pm"
  //   },
  //   "Monday": {
  //       "Open": "9:00am",
  //       "Close": "4:30pm"
  //   },
  //   "Tuesday": {
  //       "Open": "9:00am",
  //       "Close": "4:30pm"
  //   },
  //   "Wednesday": {
  //       "Open": "9:00am",
  //       "Close": "4:30pm"
  //   },
  //   "Thursday": {
  //       "Open": "9:00am",
  //       "Close": "4:30pm"
  //   },
  //   "Friday": {
  //       "Open": "9:00am",
  //       "Close": "4:30pm"
  //   },
  //   "Saturday": {
  //       "Open": "9:00am",
  //       "Close": "4:30pm"
  //   }
  // }

  if (typeof Hours !== "object" || !Hours) {
    return false;
  }

  const todayDayOfWeek = formatDate(new Date(), "EEEE");

  if (!Object.prototype.hasOwnProperty.call(Hours, todayDayOfWeek)) {
    return false;
  }

  if (!Hours[todayDayOfWeek].Open || !Hours[todayDayOfWeek].Close) {
    return false;
  }

  return Hours[todayDayOfWeek];
}
