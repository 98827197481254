import { useContext, useEffect, useState } from "react";

import Accordion from "../../atoms/Accordion";

import { filterAndSortTrailsBuilder } from "../../../../lib/components/trailWidget/filterAndSortTrails";
import useWindowDimensions from "../../../../lib/hooks/windowDimensions";

import CategorizedTrails from "./CategorizedTrails";
import UncategorizedTrails from "./UncategorizedTrails";

import styles from "../../../../styles/components/storybook/organisms/TrailsAccordion.module.scss";
import LanguageContext from "../../../../lib/context/create-context/languageContext";

const TrailsAccordion = ({
  mountainAreas,
  filteredMountainArea,
  trailSort,
  filteredText,
  showClosed,
  showUphillOnly,
  showLegend,
}) => {
  const { isFrench } = useContext(LanguageContext);

  const [trailGroups, setTrailGroups] = useState([]);

  useEffect(() => {
    const parsedFilterText = filteredText.replace(/[\W_]+/g, " ").toLowerCase();
    const filterAndSortTrails = filterAndSortTrailsBuilder(
      parsedFilterText,
      trailSort,
      showClosed,
      showUphillOnly
    );

    const filteredTrailGroups = (mountainAreas || [])
      .filter(({ title }) =>
        filteredMountainArea ? title === filteredMountainArea : true
      )
      .map((trailGroup) => {
        let categorizedTrails = (trailGroup.categorizedTrails || [])
          .map((categoryGroup) => ({
            ...categoryGroup,
            trails: filterAndSortTrails(categoryGroup.trails),
          }))
          // Filter out categories that has empty trails
          .filter(({ trails }) => trails.length > 0);

        return {
          ...trailGroup,
          trails: filterAndSortTrails(trailGroup.trails),
          categorizedTrails,
        };
      }) // Filter empty trails
      .filter(
        (trailGroup) =>
          trailGroup.trails.length > 0 ||
          trailGroup.categorizedTrails.length > 0
      );

    setTrailGroups(filteredTrailGroups);
  }, [
    mountainAreas,

    trailSort,
    filteredMountainArea,
    filteredText,
    showClosed,

    setTrailGroups,
  ]);

  //Get window width, needed for vertical sorting
  const { width } = useWindowDimensions();
  let columns = 1; //Mobile
  if (width >= 768) {
    //Small Breakpoint
    columns = 2;
  }
  if (width >= 1200) {
    //XL Breakpoint
    columns = 3;
  }

  return trailGroups.length > 0 ? (
    trailGroups.map((trailGroup, i) => {
      return (
        <Accordion title={trailGroup.title} key={`${trailGroup.title}-${i}`}>
          {trailGroup.categorizedTrails.length > 0 ? (
            <CategorizedTrails
              categorizedTrails={trailGroup.categorizedTrails}
              columns={columns}
            />
          ) : (
            <UncategorizedTrails trails={trailGroup.trails} columns={columns} />
          )}
        </Accordion>
      );
    })
  ) : (
    <div
      className={
        showLegend ? styles.noMatchingTrails : styles.noMatchFullPadding
      }
    >
      {isFrench
        ? "Aucune piste ne correspond aux critères de recherche"
        : "No Trails Match Search Criteria"}
    </div>
  );
};

export default TrailsAccordion;
