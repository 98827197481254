import classnames from "classnames";
import Icon from "../../shared/Icon/Icon";
import styles from "../../../styles/components/storybook/widgets/TrailWidget.module.scss";

export const StatusIcon = ({ icon }) => {
  const className = classnames(styles.trailIcon, styles[icon]);

  return <Icon name={icon} type="lift-status" className={className} />;
};

export const FeatureIcon = ({ icon }) => {
  const className = classnames(styles.trailIcon, styles[icon]);

  return <Icon name={icon} type="trail-features" className={className} />;
};

export const TrailIcon = ({ icon }) => {
  const className = classnames(styles.trailIcon, styles[icon]);

  return <Icon name={icon} type="trail-icons" className={className} />;
};
