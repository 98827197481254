import { useContext } from "react";
import CurrentMountainAreaContext from "../../../lib/context/create-context/currentMountainAreaContext";
import CurrentActivityMountainAreaContext from "../../../lib/context/create-context/currentActivityMountainAreaContext";
import Widget from "../organisms/Widget";
import Tabs from "../molecules/Tabs";
import classnames from "classnames";
import styles from "../../../styles/components/storybook/widgets/TabsMountainAreaWidget.module.scss";
import classNames from "classnames";

const TabsMountainAreaWidget = ({ id, tabs, title, marginTop, type }) => {
  const mountainContext = useContext(CurrentMountainAreaContext);
  const currentMountainArea = mountainContext.currentMountainArea;
  const setCurrentMountainArea = mountainContext.setCurrentMountainArea;

  const activityMountainContext = useContext(
    CurrentActivityMountainAreaContext
  );
  const currentActivityMountainArea =
    activityMountainContext.currentActivityMountainArea;
  const setCurrentActivityMountainArea =
    activityMountainContext.setCurrentActivityMountainArea;

  const renderLiftsTrailsTabs = type === "liftsTrails";
  const renderActivityTabs = type === "activity";

  const titleClassNames = classnames(
    styles.title,
    marginTop && styles.marginTop,
    "widget-title"
  );

  //Hide Tabs if only one mountain area plus default show all
  const allMountainAreaPlusOneMA = 2;
  const atLeastOneMountainArea = tabs.length > allMountainAreaPlusOneMA;

  if (!tabs) return null;

  return (
    <Widget id={id} background={false} padding={false}>
      <div className={styles.component}>
        {title && <h4 className={titleClassNames}>{title}</h4>}
        {renderLiftsTrailsTabs && atLeastOneMountainArea && (
          <Tabs
            options={tabs}
            currentTab={currentMountainArea}
            setCurrentTab={setCurrentMountainArea}
          />
        )}
        {renderActivityTabs && atLeastOneMountainArea && (
          <Tabs
            options={tabs}
            currentTab={currentActivityMountainArea}
            setCurrentTab={setCurrentActivityMountainArea}
          />
        )}
      </div>
    </Widget>
  );
};

export default TabsMountainAreaWidget;
