import { DIFFICULTY_ICON_MAPPING, getDifficulty } from "./get-difficulty";
import { FEATURE_ICON_MAP, getFeatures } from "./get-features";
import { GROOMING_ICON_MAP, getGrooming } from "./get-grooming";

export const getTrailDataEndpoints = (trail) => {
  const difficulty = getDifficulty(trail);
  return {
    key: trail.Name,
    name: trail.Name,
    status: trail.Status,
    openStatusClass:
      trail.Status.toLowerCase() === "open" ||
      trail.Status.toLowerCase() === "ouvert",

    difficulty: difficulty,
    label: trail.Difficulty,
    iconPath: DIFFICULTY_ICON_MAPPING[difficulty],
    featureIcons: [
      ...getFeatures(trail).map((feature) => FEATURE_ICON_MAP[feature]),
      ...getGrooming(trail).map((grooming) => GROOMING_ICON_MAP[grooming]),
    ],

    TrailIcon: trail.TrailIcon,

    SnowMaking: trail.SnowMaking,
    Grooming: trail.Grooming,
    NightSkiing: trail.NightSkiing,
    Moguls: trail.Moguls,
    Glades: trail.Glades,
    Touring: trail.Touring,
    Nordic: trail.Nordic,
    TerrainParkOnRun: trail.TerrainParkOnRun,
    RunOfTheDay: trail.RunOfTheDay,
  };
};

const configFiltersFnBuilder =
  ({ filterTrailFeature, filterTrailIcon, removeTrailIcon }) =>
  (trails) => {
    return (
      trails
        .filter((trail) =>
          filterTrailIcon ? trail.TrailIcon === filterTrailIcon : true
        )
        // Filter from config
        .filter((trail) =>
          removeTrailIcon ? trail.TrailIcon !== removeTrailIcon : true
        )
        // Filter from config
        .filter((trail) =>
          filterTrailFeature ? trail[filterTrailFeature] === "Yes" : true
        )
    );
  };

// export const getCategorizedTrailsDataEndpoints = (
//   mountainAreas,
//   configFilters
// ) => {
//   if (!mountainAreas) return null;

//   const configFiltersFn = configFiltersFnBuilder(configFilters);

//   const categorizedTrailsGroup = mountainAreas.map((value) => {
//     const categorizedTrailsData = value.CategorizedTrails || {};

//     return {
//       title: value.Name,

//       total: value.TotalTrailsCount,
//       open: value.OpenTrailsCount,
//       categorizedTrails: Object.entries(categorizedTrailsData)
//         .map(([category, trails]) => ({
//           category,
//           trails: configFiltersFn(
//             trails.map((trail) => getTrailDataEndpoints(trail))
//           ),
//         }))
//         .sort((a, b) => {
//           if (a.category === "Uncategorized") {
//             return -1;
//           } else {
//             return 1;
//           }
//         }),
//     };
//   });

//   return categorizedTrailsGroup;
// };

export const getTrailsDataEndpoints = (mountainAreas, configFilters) => {
  if (!mountainAreas) return null;

  const configFiltersFn = configFiltersFnBuilder(configFilters);

  const getCategorizedTrailsEndpoints = (categorizedTrails) => {
    return Object.entries(categorizedTrails)
      .map(([category, trails]) => ({
        category,
        trails: configFiltersFn(
          trails.map((trail) => getTrailDataEndpoints(trail))
        ),
      }))
      .sort((a, b) => {
        if (a.category === "Uncategorized") {
          return -1;
        } else {
          return 1;
        }
      });
  };

  const getDefaultTrailsEndpoints = (trails) => {
    return configFiltersFn(trails.map((trail) => getTrailDataEndpoints(trail)));
  };

  let endpoints = Object.entries(mountainAreas);
  endpoints = endpoints.map(([key, value]) => {
    return [
      key,
      {
        title: value.Name,
        total: value.TotalTrailsCount,
        open: value.OpenTrailsCount,
        trails: getDefaultTrailsEndpoints(value.Trails),
        categorizedTrails: getCategorizedTrailsEndpoints(
          value.CategorizedTrails
        ),
      },
    ];
  });

  endpoints = Object.fromEntries(endpoints);
  endpoints = Object.values(endpoints);

  return endpoints;
};

export const getTrailWidgetHeaderDataEndpoints = (data) => {
  if (!data?.SnowReport) return null;

  let openTrails = data?.AggregatedStats.TotalOpenTrails.toString();
  openTrails = openTrails === "--" ? null : openTrails;

  let allTrails = data?.AggregatedStats.TotalTrails.toString();
  allTrails = allTrails === "--" ? null : allTrails;

  const endpoints = {
    open: openTrails,
    total: allTrails,
  };
  return endpoints;
};
