import classnames from "classnames";
import styles from "../../../styles/components/storybook/atoms/Widget.module.scss";
import WidgetFooter from "../molecules/WidgetFooter";
import WidgetHeader from "../molecules/WidgetHeader";

const Widget = (props) => {
  const {
    id,
    children,
    headerTitle,
    largeTitle,
    headerDivider = false,
    headerBackground = false,
    footerBackground = false,
    headerLink,
    headerLinkLabel,
    padding = true,
    headerPaddingTop,
    marginTop = true,
    extraMarginTop,
    background = true,
    center = false,
    open,
    total,
    filteredText,
    setFilteredText,
    placeholder,
    showClosed,
    showClosedLabel,
    setShowClosed,
    showLegend,
    setShowLegend,
    showUphillOnly,
    setShowUphillOnly,
    showUphillToggle,
    sortOptions,
    sortTrails,
    setSortTrails,
    // trails,
    resortDifficulties,
    resortFeatures,
    resortGrooming,
    legendGroups,
    showClosedToggleDefaultValue,
    showLegendToggleDefaultValue,
    showUphillToggleDefaultValue,

    showSpecificActivityToggle,
    showSpecificActivityOnly,
    setShowSpecificActivityOnly,
    specificActivityToggleLabel,

    showLegendOnLoad,
    showSort,
  } = props;

  const sectionClassName = classnames(
    styles.widget,
    headerDivider ? styles.headerDivider : undefined,
    marginTop ? styles.marginTop : undefined,
    extraMarginTop ? styles.extraMarginTop : undefined
  );

  const containerClassName = classnames(
    styles.container,
    background ? styles.background : undefined,
    background && background === "secondary"
      ? styles.secondaryBackground
      : undefined,
    center ? styles.center : undefined,
    padding ? styles.padding : undefined
  );

  if (!children) return null;

  return (
    <section id={id} className={sectionClassName}>
      {headerDivider && (
        <div className={styles.divider}>
          <div className={styles.dividerLine}></div>
        </div>
      )}
      {headerTitle && (
        <WidgetHeader
          title={headerTitle}
          largeTitle={largeTitle}
          headerBackground={headerBackground}
          headerPaddingTop={headerPaddingTop}
          link={headerLink}
          linkLabel={headerLinkLabel}
          open={open}
          total={total}
          filteredText={filteredText}
          setFilteredText={setFilteredText}
          placeholder={placeholder}
          showClosed={showClosed}
          showClosedLabel={showClosedLabel}
          setShowClosed={setShowClosed}
          showLegend={showLegend}
          setShowLegend={setShowLegend}
          showUphillOnly={showUphillOnly}
          setShowUphillOnly={setShowUphillOnly}
          showUphillToggle={showUphillToggle}
          sortOptions={sortOptions}
          sortTrails={sortTrails}
          setSortTrails={setSortTrails}
          // trails={trails}
          resortDifficulties={resortDifficulties}
          resortFeatures={resortFeatures}
          resortGrooming={resortGrooming}
          legendGroups={legendGroups}
          showSort={showSort}
          showClosedToggleDefaultValue={showClosedToggleDefaultValue}
          showLegendToggleDefaultValue={showLegendToggleDefaultValue}
          showUphillToggleDefaultValue={showUphillToggleDefaultValue}
          showLegendOnLoad={showLegendOnLoad}
          showSpecificActivityToggle={showSpecificActivityToggle}
          showSpecificActivityOnly={showSpecificActivityOnly}
          setShowSpecificActivityOnly={setShowSpecificActivityOnly}
          specificActivityToggleLabel={specificActivityToggleLabel}
        />
      )}
      <div className={containerClassName}>{children}</div>
      {headerLink && headerLinkLabel && (
        <WidgetFooter
          link={headerLink}
          label={headerLinkLabel}
          background={footerBackground}
        />
      )}
    </section>
  );
};

export default Widget;
