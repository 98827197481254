import Icon from "../../shared/Icon/Icon";
import styles from "../../../styles/components/storybook/widgets/WeatherWidget.module.scss";
import { UNIT_LENGTH_IN } from "../../../lib/components/weatherWidget";

//TODO: DETERMINE IF WE WILL STILL USE THIS COMPONENT. IF NOT, DELETE IT.
const StormAlert = ({ measurementUnit, stormTotal }) => {
  const { inches, centimeters } = stormTotal;

  const snowAmount =
    measurementUnit === UNIT_LENGTH_IN
      ? inches.stormTotal
      : centimeters.stormTotal;

  if (snowAmount <= 0) {
    return null;
  }

  return (
    <div className={styles.stormAlert}>
      <div className={styles.stormAlertBanner}>
        <Icon
          name="snowflake"
          type="weather"
          className={styles.stormAlertIcon}
        />
        <p>Storm Total:</p>
        <p className={styles.stormAlertAmount}>
          {snowAmount} {measurementUnit === UNIT_LENGTH_IN ? "in" : "cm"}
        </p>
      </div>
    </div>
  );
};

export default StormAlert;
