import Trail from "../../molecules/Trail";

import styles from "../../../../styles/components/storybook/organisms/TrailsAccordion.module.scss";

const UncategorizedTrails = ({ trails, columns }) => {
  return (
    <>
      <div className={styles.accordionPseudoElement}></div>
      <ul
        className={styles.trailList}
        style={{
          gridTemplateRows: `repeat(${Math.ceil(
            trails.length / columns
          )}, 1fr)`,
        }}
      >
        {trails.map((trail, index) => (
          <Trail
            key={`${trail.name}-${trail.difficultyIconPath}-${index}`}
            name={trail.name}
            status={trail.status}
            openStatusClass={trail.openStatusClass}
            difficultyIconPath={trail.iconPath}
            difficultyIconType="trail-icons"
            featureIcons={trail.featureIcons}
            featureIconType="trail-features"
          />
        ))}
      </ul>
    </>
  );
};

export default UncategorizedTrails;
