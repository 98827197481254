import classnames from "classnames";
import styles from "../../../styles/components/storybook/atoms/SelectInput.module.scss";
import widgetStyles from "../../../styles/components/storybook/atoms/Widget.module.scss";
import "react-dropdown/style.css";
import Dropdown from "react-dropdown";

export default function SelectInput(props) {
  const { options, value, onChange, section } = props;

  let className = classnames(styles.dropdownContainer, widgetStyles.dropdown);

  if (section === "Weather") {
    className = classnames(styles.dropdownContainer, styles.dropdownWeather);
  }

  return (
    <Dropdown
      arrowClassName={styles.dropdownArrow}
      className={className}
      controlClassName={styles.dropdownControl}
      menuClassName={styles.dropdownMenu}
      optionClassName={styles.dropdownOption}
      options={options}
      onChange={onChange}
      value={value}
    />
  );
}
