import Lift from "../../molecules/Lift";

import styles from "../../../../styles/components/storybook/organisms/LiftsAccordion.module.scss";

const UncategorizedLifts = ({
  lifts,
  columns,
  statusOverrides,
  showTimestamp,
}) => {
  return (
    <>
      <div className={styles.accordionPseudoElement}></div>
      <ul
        className={styles.liftList}
        style={{
          gridTemplateRows: `repeat(${Math.ceil(lifts.length / columns)}, 1fr)`,
        }}
      >
        {lifts.map((lift, index) => (
          <Lift
            key={`${lift.key}-${index}`}
            name={lift.name}
            iconPath={lift.iconPath}
            iconType="lift-types"
            statusIcon={lift.statusIcon}
            status={statusOverrides[lift.status] || lift.status}
            rideTime={lift.rideTime}
            hours={lift.hours}
            updateTimestamp={lift.updateTimestamp}
            showTimestamp={showTimestamp}
          />
        ))}
      </ul>
    </>
  );
};

export default UncategorizedLifts;
